import RestApiService from '../../service/RestApiService'

/*Employee onboard API start*/

export const getEmployees = async (page: number, records: number) => {
    const path = `employee/employees?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const createEmployee = async (data: any) => {
    const path = 'employee/employees'
    return await RestApiService.post(path, data)
}

export const getEmployee = async (id: number) => {
    const path = `employee/employees/${id}`
    return await RestApiService.get(path)
}

export const changeEmployeeStatus = async (id: number) => {
    const path = `employee/employees/${id}`
    return await RestApiService.patch(path)
}

export const deleteEmployee = async (id: number) => {
    const path = `employee/employees/${id}`
    return await RestApiService.destroy(path)
}

export const patchEmployee = async (id: number, data: any) => {
    const path = `employee/employees/${id}`
    return await RestApiService.put(path, data)
}

export const accessEmployee = async (id: number, data?: any) => {
    const path = `employee/employees/${id}/login-access`
    return await RestApiService.put(path, data)
}

export const patchEmployeeProfile = async (id: number, data: any) => {
    const path = `employee/employees/${id}/profile`
    return await RestApiService.put(path, data)
}

export const uploadImage = async (data: any) => {
    const path = 'common/upload/image'
    return await RestApiService.upload(path, data)
}

export const patchEmployeeFinance = async (id: number, data: any) => {
    const path = `employee/employees/${id}/finance`
    return await RestApiService.put(path, data)
}

export const patchEmployeeQualification = async (id: number, data: any) => {
    const path = `employee/employees/${id}/qualification`
    return await RestApiService.put(path, data)
}

export const patchEmployeeExperience = async (id: number, data: any) => {
    const path = `employee/employees/${id}/experience`
    return await RestApiService.put(path, data)
}

export const getDocumentTypeDropdown = async (sort?: string, order?: string) => {
    const path = `misc/document-types/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const createDocument = async (id: number, data: any) => {
    const path = `employee/employees/${id}/documents`
    return await RestApiService.put(path, data)
}

export const deleteEmployeeDocument = async (id: number, employeeId: number) => {
    const path = `employee/employees/${employeeId}/documents/${id}`
    return await RestApiService.destroy(path)
}
/*Employee onboard API end*/
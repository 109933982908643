import { CHeader, CHeaderNav, CHeaderNavItem, CHeaderNavLink, CToggler } from '@coreui/react'
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectDashboards, selectGlobalSidebar, setGlobalSidebar } from '../../store'
import { Span } from '../../styled'
import { GlobalSidebarShowType } from '../../type'
import UserMenu from './usermenu'
import logo from '../../../../asset/static/logo-long.svg'
import { databoardsList } from '../../../../app/data'
import { useLocation } from 'react-router-dom'
import UserContext from '../../../../app/context'

const Img = styled.img``
const GlobalHeader = ({switcher}: {switcher?: (value: string)=> any}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const profile: any = useContext(UserContext)
    const sidebar: GlobalSidebarShowType = useSelector(selectGlobalSidebar)
    const dashboards: string[] = useSelector(selectDashboards)

    const toggleSidebarMobile = () => {
        const val = [false, 'responsive'].includes(sidebar.sidebarShow) ? true : 'responsive'
        dispatch(setGlobalSidebar({type: 'set', sidebarShow: val}))
    }

    const toggleSidebar = () => {
        const val = [true, 'responsive'].includes(sidebar.sidebarShow) ? false : 'responsive'
        dispatch(setGlobalSidebar({type: 'set', sidebarShow: val}))
    }

    const handleBoardSwitch = (item: string) => {
        profile.setDashboard(item)
        window.localStorage.setItem('dashboard', item)
    }

    useEffect(()=> {
        if(switcher)
            switcher(profile?.dashboard)
    },[profile, switcher])
    return (
        <CHeader withSubheader>
            <CToggler inHeader className={'ml-md-3 d-lg-none'} onClick={toggleSidebarMobile}/>
            <CToggler inHeader className={'ml-3 d-md-down-none'} onClick={toggleSidebar}/>

            <CHeaderNav className={'d-md-down-none mr-auto'}>
                {(dashboards.length > 1 && location.pathname === '/dashboard') && dashboards.map((dashboard: string) => <CHeaderNavItem key={`key-${dashboard}`} className={'px-3'} onClick={()=>handleBoardSwitch(dashboard)}>
                    <CHeaderNavLink>{databoardsList.find(item => item.id === dashboard)?.title}</CHeaderNavLink>
                </CHeaderNavItem>)}
                {(dashboards.length <= 1) && <CHeaderNavItem className={'px-3'} >
                    <CHeaderNavLink></CHeaderNavLink>
                </CHeaderNavItem>}
            </CHeaderNav>

            <CHeaderNav className={'px-3 m-auto d-md-none'}>
                <Span className={'c-sidebar-brand-full'}><Img src={logo} height={35}/></Span>
            </CHeaderNav>
            <CHeaderNav className={'px-3'}>
                <UserMenu/>
            </CHeaderNav>

        </CHeader>
    )
}

export default GlobalHeader
import dateFormat from 'dateformat'
import { confirmAlert } from 'react-confirm-alert'
import { ExaminationTypeList } from '../data'
import { MetaType, DropdownItemType, SessionResponseType, StandardResponseType, SubjectResponseType, ExaminationResponseType, ExaminationsCustomResponseType, SectionResponseType, ExaminationMarkStudentAcademicsResponseType, EntranceExaminationCentreResponseType, EntranceExamResponseType, EntranceExaminationCustomResponseType, AreaResponseType, RegistrationResponseType, StudentRegistrationCustomResponseType } from '../type'

/* start get total number of pages */
export const getTotalPages = (meta: MetaType | undefined) => {
    return (meta !== undefined) ? Math.ceil(meta.total / meta.per_page) || 1 : 1
}
/* end get total number of pages */

export const getFullName = (firstname?: string, middlename?: string | null, lastname?: string | null) => {
    return `${firstname} ${middlename || ''} ${lastname || ''}`
}

export const getActiveSession = (sessions: SessionResponseType[]) => {
    if (sessions && sessions.length > 0) {
        return sessions.find((session: SessionResponseType) => session.is_current === true)
    }
    return {} as SessionResponseType
}

export const booleanToString = (key: boolean | string | number) => {
    if (typeof key === 'string') {
        return key === 'false' ? false : true
    }
    if (typeof key === 'number') {
        return key === 0 ? false : true
    }
    return key
}

/* start dropdown label by id */
export const getDropdownLabelById = (items: DropdownItemType[], id: any, placeholder?: string) => {
    if (items !== undefined) {
        const item = items.filter((item: DropdownItemType) => item.id === id)
        if (item.length > 0) {
            return item[0].title
        }
    }
    return placeholder || 'Select here'
}
/* end dropdown label by id */

/* start dropdown list */
export const getStandardsForDropdown = (standards: StandardResponseType[]) => {
    return standards?.map((standard: StandardResponseType) => {
        return {
            id: standard.id,
            title: standard.name
        } as DropdownItemType
    })
}

export const getSubjectsForDropdown = (subjects: SubjectResponseType[]) => {
    return subjects?.map((subject: SubjectResponseType) => {
        return {
            id: subject.id,
            title: subject.name
        } as DropdownItemType
    })
}

export const getSectionForDropdown = (sections: SectionResponseType[]) => {
    return sections?.map((section: SectionResponseType) => {
        return {
            id: section.id,
            title: section.name
        } as DropdownItemType
    })
}

export const getStandardSubjectForDropdown = (subjects: SubjectResponseType[]) => {
    return subjects?.map((subject: SubjectResponseType) => {
        return {
            id: subject.id,
            title: subject.name
        } as DropdownItemType
    })
}

export const getExaminationCenterForDropdown = (centers: EntranceExaminationCentreResponseType[]) => {
    return centers?.map((center: EntranceExaminationCentreResponseType) => {
        return {
            id: center.id,
            title: center.name
        } as DropdownItemType
    })
}

export const getAreasForDropdown = (areas: AreaResponseType[]) => {
    return areas?.map((area: AreaResponseType) => {
        return {
            id: area.id,
            title: `${area.name}, ${area.district.name}, ${area.state.name}, ${area.country.name}`
        } as DropdownItemType
    })
}
/* end dropdown list */

export const getInt = (num: string | number) => {
    return (typeof num === 'string') ? parseInt(num) : num
}

/* start get examination list */
export const getExaminationForList = (examinations: ExaminationResponseType[]) => {
    return examinations?.map((examination) => {
        return {
            id: examination.id,
            name: examination.title,
            standard: `${examination.standard?.name} ${examination.section?.name}`,
            subject: examination.subject?.name,
            exam_type: getDropdownLabelById(ExaminationTypeList, examination.exam_type),
            mark_type: examination.mark_type === 'number' ? `Number (QM: ${examination.qualify_marks}, MM:${examination.maximum_marks})` : 'Grade',
            exam_date: dateFormat(examination.exam_date, 'dd-mm-yyyy'),
            exam_status: examination.exam_status.toUpperCase()
        } as ExaminationsCustomResponseType
    })
}
/* end get examination list */

/* Marks Submit Confirmation Alert Start */
export const marksCreateConfirmationAlert = (acceptAction: () => any, denyAction: () => any) => {
    confirmAlert({
        title: 'Confirmation Submission',
        message: 'Are you sure you want to submit students marks',
        closeOnClickOutside: false,
        buttons: [
            {
                label: 'Accept',
                onClick: acceptAction
            },
            {
                label: 'Deny',
                onClick: denyAction
            }
        ]
    })
}
/* Marks Submit Confirmation Alert End */

export const getRollNumber = (academics: ExaminationMarkStudentAcademicsResponseType[], sessionId: number) => {
    const academicData = academics.find((academic: ExaminationMarkStudentAcademicsResponseType) => academic.session_id === sessionId)
    if (academicData) {
        return academicData.standard_roll_number
    }
    return ''
}

export const isMarkEntryValid = (mark: string, type: string, maximum: number | null) => {
    if (type === 'number' && maximum) {
        return (getInt(mark) <= maximum)
    }
    const regex = (type === 'number') ? /^\d+\.\d+$|^\d+$|^\d+.$/ : /^[A-F+-]+$/i
    return regex.test(mark)
}

/* Entrance examination list start */

export const getEntranceExaminationForList = (examinations: EntranceExamResponseType[]) => {
    return examinations?.map((examination) => {
        return {
            Id: examination.id,
            Registration_Number: examination.student_registration_id,
            Standard: examination.registration.standard.name,
            Name: getFullName(examination?.registration?.firstname, examination?.registration?.middlename, examination?.registration?.lastname),
            Exam_Status: examination.exam_status.toUpperCase(),
            Exam_Date: examination.exam_date ? dateFormat(new Date(examination.exam_date), 'ddd, mmm dS, yyyy, h:MM TT') : '-'
        } as EntranceExaminationCustomResponseType
    })
}

/* Entrance examination list end */

/* Student Registration List Start */

export const getRegistrationForList = (registrations: RegistrationResponseType[]) => {
    return registrations?.map((registration) => {
        return {
            Registration_Number: registration.id,
            Student_Name: getFullName(registration.firstname, registration.middlename, registration.lastname),
            Standard: registration.standard?.name
        } as StudentRegistrationCustomResponseType
    })
}

export const getRescheduleEntranceExamList = (examinations: EntranceExamResponseType[]) => {
    return examinations?.map((examination) => {
        return {
            Id: examination.id,
            Registration_Number: examination.student_registration_id,
            Student_Name: getFullName(examination?.registration?.firstname, examination?.registration?.middlename, examination?.registration?.lastname),
            Standard: examination.registration?.standard?.name
        } as StudentRegistrationCustomResponseType
    })
}

/* Student Registration List End */

/* Entrance Exam Schedule Confirmation Alert Start */
export const entranceExamScheduleConfirmationAlert = (acceptAction: () => any, denyAction: () => any, students: number) => {
    confirmAlert({
        title: 'Confirmation Schedule',
        message: `Are you sure you want to schedule entrance exam for ${students} students`,
        closeOnClickOutside: false,
        buttons: [
            {
                label: 'Accept',
                onClick: acceptAction
            },
            {
                label: 'Deny',
                onClick: denyAction
            }
        ]
    })
}
/* Entrance Exam Schedule Confirmation Alert End */

/* Entrance Exam Schedule Confirmation Alert Start */
export const entranceExamReScheduleConfirmationAlert = (acceptAction: () => any, denyAction: () => any, students: number) => {
    confirmAlert({
        title: 'Confirmation Schedule',
        message: `Are you sure you want to reschedule entrance exam for ${students} students`,
        closeOnClickOutside: false,
        buttons: [
            {
                label: 'Accept',
                onClick: acceptAction
            },
            {
                label: 'Deny',
                onClick: denyAction
            }
        ]
    })
}
/* Entrance Exam Schedule Confirmation Alert End */
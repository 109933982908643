import React from 'react'

//Teacher Start
const TeacherList = React.lazy(() => import('./teacher/index'))
const TeacherTeachingInfo = React.lazy(()=>import('./teacher/modal/teachingInfo'))
//Teacher End

export {
    //Teacher Start
    TeacherList,
    TeacherTeachingInfo
    //Teacher End
}
import dateFormat from 'dateformat'

export const handleDateInput = (value: string) => {
    if (value && value.length > 0) {
        return dateFormat(new Date(value), 'dd-mm-yyyy')
    }
    return ''
}

export const handleTimeInput = (value:string) => {
    if (value && value.length > 0) {
        return dateFormat(new Date(value), 'HH:MM')
    }
    return ''
}

export const validateDate = (dateString: string|null) => {
    const dateFormat = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
    if (dateString && dateString.length === 10 && dateString.match(dateFormat)) {
        return true
    }
    return false
}

export const validateTime = (timeString: string) => {
    const timeFormat = /(0[0-9]|1[0-9]|2[0-3])(:)([0-5][0-9])$/
    if (timeString && timeString.length === 5 && timeString.match(timeFormat)){
        return true
    }
    return false
}

export const handleYear = (yearString: string) => {
    if (yearString && yearString.length === 2){
        return parseInt(yearString) > 50 ? `19${yearString}` : `20${yearString}`
    }
    return yearString
}

export const constructDate = (rawString: string) => {
    if (rawString && (rawString.length === 6 || rawString.length === 8)){
        return `${rawString.slice(0,2)}-${rawString.slice(2,4)}-${handleYear(rawString.slice(4,rawString.length))}`
    }
    return ''
}

export const constructTime = (rawString: string) => {
    if (rawString && (rawString.length === 4)){
        return `${rawString.slice(0,2)}:${rawString.slice(2,4)}`
    }
    return ''
}

export const formatDate = (rawString: string) => {
    const dateArray = rawString.split('-')
    return `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`
}
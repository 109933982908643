import { SessionResponseType, StandardResponseType, CasteResponseType, ReligionResponseType, QualificationResponseType, OccupationResponseType, IncomeSlabResponseType, AreaResponseType, DropdownItemType, RegistrationResponseType, RegistrationCustomType, StudentAdmissionCustomType, MetaType, SubjectResponseType, AdmissionResponseType } from '../type'
import dateFormat from 'dateformat'
import { confirmAlert } from 'react-confirm-alert'

export const getLastSession = (session: SessionResponseType[]) => {
    if(session.length>0){
        return session[0]
    }
    return {} as SessionResponseType
}

export const getActiveSession = (sessions: SessionResponseType[]) => {
    if(sessions.length > 0){
        return sessions.find((session: SessionResponseType)=> session.is_current === true)
    }
    return {} as SessionResponseType
}

export const getFullName = (firstname?: string, middlename?: string, lastname?: string) => {
    return `${firstname}${middlename?` ${middlename}`: ''}${lastname? ` ${lastname}` :''}`
}

/* start get total number of pages */
export const getTotalPages = (meta: MetaType|undefined) => {
    return (meta !== undefined) ? Math.ceil(meta.total/meta.per_page)||1:1
}
/* end get total number of pages */

/* start dropdown label by id */
export const getDropdownLabelById = (items: DropdownItemType[], id: any, placeholder?: string) => {
    if(items !== undefined){
        const item = items.filter((item:DropdownItemType)=> item.id === id)
        if(item.length > 0){
            return item[0].title
        }
    }
    return placeholder || 'Select here'
}
/* end dropdown label by id */

/* start dropdown list */
export const getStandardsForDropdown = (standards: StandardResponseType[]) => {
    return standards?.map((standard: StandardResponseType) => {
        return {
            id:standard.id,
            title:standard.name
        } as DropdownItemType
    })
}

export const getReligionsForDropdown = (religions: ReligionResponseType[]) => {
    return religions?.map((religion: ReligionResponseType) => {
        return {
            id:religion.id,
            title:religion.name
        } as DropdownItemType
    })
}

export const getCastesForDropdown = (castes: CasteResponseType[]) => {
    return castes?.map((caste: CasteResponseType) => {
        return {
            id:caste.id,
            title:caste.name
        } as DropdownItemType
    })
}

export const getQualifcationsForDropdown = (qualifications: QualificationResponseType[]) => {
    return qualifications?.map((qualification: QualificationResponseType) => {
        return {
            id:qualification.id,
            title:qualification.name
        } as DropdownItemType
    })
}

export const getOccupationForDropdown = (occupations: OccupationResponseType[]) => {
    return occupations?.map((occupation: OccupationResponseType) => {
        return {
            id:occupation.id,
            title:occupation.name
        } as DropdownItemType
    })
}

export const getIncomeSlabForDropdown = (incomes: IncomeSlabResponseType[]) => {
    return incomes?.map((income: IncomeSlabResponseType) => {
        return {
            id:income.id,
            title:`${income.minimum}-${income.maximum}`
        } as DropdownItemType
    })
}

export const getAreaForDropdown = (areas: AreaResponseType[]) => {
    return areas?.map((area: AreaResponseType) => {
        return {
            id:area.id,
            title:`${area.name}, ${area.district.name}, ${area.state.name}, ${area.country.name}`
        } as DropdownItemType
    })
}

export const getSubjectsForDropdown = (subjects: SubjectResponseType[]) => {
    return subjects?.map((subject: SubjectResponseType) => {
        return {
            id: subject.id,
            title: subject.name
        } as DropdownItemType
    })
}
/* end dropdown list */

/* start registration */
export const getRegistrationsForList = (registrations: RegistrationResponseType[]) => {
    return registrations?.map((registration: RegistrationResponseType) => {
        return {
            Number: registration.id,
            Session: registration?.session?.name,
            Name: getFullName(registration.firstname, registration?.middlename, registration?.lastname),
            Father: registration.father,
            Gender: registration.gender.toUpperCase(),
            Class: registration?.standard?.name,
            Date: dateFormat(new Date(registration.created_at),'dS mmm, yyyy'),
            Profile: registration.profile_status.toUpperCase(),
            Mode: registration.admission_type.toUpperCase(),
            Status: registration.admission_status.toUpperCase(),
            Completed: getRegistrationCompletePercentage(registration)
        } as RegistrationCustomType
    })
}
/* end registration */

/* confirm admission alert */
export const admitWithConfirmation = (acceptAction: () => any, denyAction: () => any) => {
    confirmAlert({
        title: 'Confirm Admission!',
        message: 'Are you sure you want to confirm admission?',
        closeOnClickOutside: false,
        buttons: [
            {
                label: 'Accept',
                onClick: acceptAction
            },
            {
                label: 'Deny',
                onClick: denyAction
            }
        ]
    })
}
/* end confirm admission alert */

/* Admission List Start */
export const getAdmissionForList = (admissions: AdmissionResponseType[]) => {
    return admissions?.map((admission: AdmissionResponseType)=> {
        return {
            Type: admission.last_standard_id?'Old':'New',
            Admission: admission.student.admission_number,
            Standard: admission.standard.name,
            Student: getFullName(admission.student.firstname, admission.student.middlename||'', admission.student.lastname||''),
            Father: admission.student.parents.find(parent=>parent.relation === 'father')?.name,
            Admission_Date: dateFormat(admission.updated_at,'dS mmm, yyyy'),
            Admission_By: getFullName(admission.user.firstname, admission.user.middlename||'', admission.user.lastname||''),
            Status: admission?.is_confirm?.toUpperCase()
        } as StudentAdmissionCustomType
    })
}
/* Admission List End */

/* Complete percentage start */
export const getRegistrationCompletePercentage = (registration: RegistrationResponseType) => {
    const count = Object.keys(registration).length
    const filled = Object.values(registration).filter(item => item !== null).length
    const value = (filled/count) * 100
    return {
        value,
        color: (value > 0 && value < 40) ? 'danger' : ((value > 40 && value < 80) ? 'warning' : 'success')
    }
}
/* Complete percentage end */

/* Student Tc Confirmation Alert Start */
export const settlementConfirmationAlert = (acceptAction: () => any, denyAction: () => any) => {
    confirmAlert({
        title: 'Confirmation Final Settlement',
        message: 'Are you sure you want to move student to final settlement, this is irreversible process option',
        closeOnClickOutside: false,
        buttons: [
            {
                label: 'Accept',
                onClick: acceptAction
            },
            {
                label: 'Deny',
                onClick: denyAction
            }
        ]
    })
}
/* Student Tc Confirmation Alert End */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FilterType, MetaType, DropdownItemType, SessionResponseType, ExaminationResponseType, ExaminationListResponseType, StudentAcademicResponseType, StudentAcademicDropdownListResponseType, EntranceExamResponseType, EntranceExamListResponseType, RegistrationResponseType, RegistrationListResponseType } from './type'
import { getActiveSession, getSectionForDropdown, getStandardsForDropdown, getSubjectsForDropdown, getStandardSubjectForDropdown, getExaminationCenterForDropdown, getAreasForDropdown } from './helper'
import { getStandardDropdown, getSubjectDropdownItems, getSessionDropdown, getSectionDropdownItems, getExaminations, getSelectedExamination, getStandardSubjectDropdownItems, getStudentDropdownItems, getEntranceExaminations, getSelectedEntranceExamination, getEntranceExaminationCentresDropdownItems, getAreaDropdown, getStudentEntranceList, getEntranceExaminationsDropdownList } from './api'


export const ExaminationSlice = createSlice({
    name: 'examination',
    initialState: {
        /* Start Common */
        loading: false as boolean,
        meta: {} as MetaType,
        standardsDropdown: [] as DropdownItemType[],
        subjectsDropdown: [] as DropdownItemType[],
        sectionsDropdown: [] as DropdownItemType[],
        standardSubject: [] as DropdownItemType[],
        activeSession: {} as SessionResponseType,
        filter: {} as FilterType,
        examinationCenterDropdown: [] as DropdownItemType[],
        areaDropdown: [] as DropdownItemType[],
        /* End Common */

        /* Examination Start */
        selectedExaminationPage: 1 as number,
        examinations: [] as ExaminationResponseType[],
        selectedExamination: {} as ExaminationResponseType,
        /* Examination End */

        /* Examination Marks Start */
        studentDropdownItems: [] as StudentAcademicResponseType[],
        /* Examination Marks End */

        /* Entrance Examination Start */
        selectedEntranceExamPage: 1 as number,
        entranceExaminations: [] as EntranceExamResponseType[],
        entranceExamination: {} as EntranceExamResponseType,
        registrationList: [] as RegistrationResponseType[],
        entranceExamDropdown: [] as EntranceExamResponseType[]
        /* Entrance Examination End */
    },
    reducers: {
        /* Start Common */
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setMeta: (state, action: PayloadAction<MetaType>) => {
            state.meta = action.payload
        },
        setStandardsDropdown: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.standardsDropdown = action.payload
        },
        setSubjectsDropdown: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.subjectsDropdown = action.payload
        },
        setSectionsDropdown: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.sectionsDropdown = action.payload
        },
        setStandardSubjectDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.standardSubject = action.payload
        },
        setActiveSession: (state, action: PayloadAction<SessionResponseType[]>) => {
            state.activeSession = getActiveSession(action.payload) as SessionResponseType
        },
        setExaminationCenterDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.examinationCenterDropdown = action.payload
        },
        setFilter: (state, action: PayloadAction<FilterType>) => {
            state.filter = action.payload
        },
        setAreaDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.areaDropdown = action.payload
        },
        /* End Common */

        /* Examination Start */
        setSelectedExaminationPage: (state, action: PayloadAction<number>) => {
            state.selectedExaminationPage = action.payload
        },
        setExaminations: (state, action: PayloadAction<ExaminationResponseType[]>) => {
            state.examinations = action.payload
        },
        setSelectedExamination: (state, action: PayloadAction<ExaminationResponseType>) => {
            state.selectedExamination = action.payload
        },
        /* Examination End */

        /* Examination Marks Start */
        setStudentDropdownItems: (state, action: PayloadAction<StudentAcademicResponseType[]>) => {
            state.studentDropdownItems = action.payload
        },
        /* Examination Marks End */

        /* Entrance Examination Start */
        setSelectedEntranceExaminationPage: (state, action: PayloadAction<number>) => {
            state.selectedEntranceExamPage = action.payload
        },
        setEntranceExaminations: (state, action: PayloadAction<EntranceExamResponseType[]>) => {
            state.entranceExaminations = action.payload
        },
        setSelectedEntranceExamination: (state, action: PayloadAction<EntranceExamResponseType>) => {
            state.entranceExamination = action.payload
        },
        setRegistrationList: (state, action: PayloadAction<RegistrationResponseType[]>) => {
            state.registrationList = action.payload
        },
        setEntranceExamintionDropdown: (state, action: PayloadAction<EntranceExamResponseType[]>) => {
            state.entranceExamDropdown = action.payload
        }
        /* Entrance Examination End */
    }
})

export const {
    /* Start Common */
    setLoading,
    setMeta,
    setStandardsDropdown,
    setSubjectsDropdown,
    setSectionsDropdown,
    setStandardSubjectDropdownItems,
    setActiveSession,
    setFilter,
    setExaminationCenterDropdownItems,
    setAreaDropdownItems,
    /* End Common */

    /* Examination Start */
    setSelectedExaminationPage,
    setExaminations,
    setSelectedExamination,
    /* Examination End */

    /* Examination Marks Start */
    setStudentDropdownItems,
    /* Examination Marks End */

    /* Entrance Examination Start */
    setSelectedEntranceExaminationPage,
    setEntranceExaminations,
    setSelectedEntranceExamination,
    setRegistrationList,
    setEntranceExamintionDropdown
    /* Entrance Examination End */

} = ExaminationSlice.actions

/*Start Common */
export const loadStandardsDropdownItems = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const standardsResponse = await getStandardDropdown()
    dispatch(setStandardsDropdown(getStandardsForDropdown(standardsResponse.items)))
    dispatch(setLoading(false))
}

export const loadSectionsDropdownItems = (sort?: string, order?: string, sessionId?: number, standardId?: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response = await getSectionDropdownItems(sort, order, sessionId, standardId)
    dispatch(setSectionsDropdown(getSectionForDropdown(response.items)))
    dispatch(setLoading(false))
}


export const loadSubjectsDropdownItems = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response = await getSubjectDropdownItems()
    dispatch(setSubjectsDropdown(getSubjectsForDropdown(response.items)))
    dispatch(setLoading(false))
}
export const loadActiveSession = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const sessionResponse = await getSessionDropdown('start', 'DESC')
    dispatch(setActiveSession(sessionResponse.items))
    dispatch(setLoading(false))
}

export const loadStandardSubjectDropdownItems = (sessionId: number, standardId: number, sort?: string, order?: string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response = await getStandardSubjectDropdownItems(sessionId, standardId, sort, order)
    dispatch(setStandardSubjectDropdownItems(getStandardSubjectForDropdown(response.items)))
    dispatch(setLoading(false))
}

export const loadExaminationCenterDropdownItems = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response = await getEntranceExaminationCentresDropdownItems()
    dispatch(setExaminationCenterDropdownItems(getExaminationCenterForDropdown(response.items)))
    dispatch(setLoading(false))
}

export const loadAreaDropdownItems = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response = await getAreaDropdown()
    dispatch(setAreaDropdownItems(getAreasForDropdown(response.items)))
    dispatch(setLoading(false))
}
/*Start Common */

/* Examination Start */
export const loadExaminations = (page?: number, records?: number, sort?: string, order?: string, sessionId?: number, filter?: object) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: ExaminationListResponseType = await getExaminations(page, records, sort, order, sessionId, filter)
    dispatch(setExaminations(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSelectedExamination = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response = await getSelectedExamination(id)
    dispatch(setSelectedExamination(response))
    dispatch(setLoading(false))
}
/* Examination End */

/* Examination Marks Start */
export const loadStudentDropdownItem = (sessionId: number, standardId: number, sectionId: number, subjectId: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StudentAcademicDropdownListResponseType = await getStudentDropdownItems(sessionId, standardId, sectionId, subjectId)
    dispatch(setStudentDropdownItems(response.items))
    dispatch(setLoading(false))
}
/* Examination Marks End */

/* Entrance Examination Start */
export const loadEntranceExaminations = (sessionId: number, page: number, records: number, sort?: string, order?: string, filter?: object) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: EntranceExamListResponseType = await getEntranceExaminations(sessionId, page, records, sort, order, filter)
    dispatch(setEntranceExaminations(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSelectedEntranceExamination = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response = await getSelectedEntranceExamination(id)
    dispatch(setSelectedEntranceExamination(response))
    dispatch(setLoading(false))
}

export const loadStudentRegistrationList = (page: number, records: number, sessionId: number, admissionType: string, admissionStatus: string, standardId?: number | string, localAreaId?: number | string, gender?: string | number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: RegistrationListResponseType = await getStudentEntranceList(page, records, sessionId, admissionType, admissionStatus, standardId, localAreaId, gender)
    dispatch(setRegistrationList(response.list))
    dispatch(setLoading(false))
}

export const loadEntranceExamDropdown = (sessionId: number, status?: string, standardId?: number|string, examinationCenterId?: number|string, examDate?: string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response = await getEntranceExaminationsDropdownList(sessionId, status, standardId, examinationCenterId, examDate)
    dispatch(setEntranceExamintionDropdown(response.items))
    dispatch(setLoading(false))
}
/* Entrance Examination End */

/* Start Common */
export const selectLoading = (state: any) => state.examination.loading
export const selectMeta = (state: any) => state.examination.meta
export const selectStandardsDropdown = (state: any) => state.examination.standardsDropdown
export const selectSubjectsDropdown = (state: any) => state.examination.subjectsDropdown
export const selectSectionsDropdown = (state: any) => state.examination.sectionsDropdown
export const selectStandardSubjectDropdown = (state: any) => state.examination.standardSubject
export const selectExaminationCenterDropdown = (state: any) => state.examination.examinationCenterDropdown
export const selectActiveSession = (state: any) => state.examination.activeSession
export const selectFilter = (state: any) => state.examination.filter
export const selectAreaDropdown = (state: any) => state.examination.areaDropdown
/* End Common */

/* Examination Start */
export const selectSelectedExaminationPage = (state: any) => state.examination.selectedExaminationPage
export const selectExaminations = (state: any) => state.examination.examinations
export const selectSelectedExamination = (state: any) => state.examination.selectedExamination
/* Examination End */

/* Examination Marks Start */
export const selectStudentDropdwonItems = (state: any) => state.examination.studentDropdownItems
/* Examination Marks End */

/* Entrance Examination Start */
export const selectSelectedEntranceExaminationPage = (state: any) => state.examination.selectedEntranceExamPage
export const selectEntranceExaminations = (state: any) => state.examination.entranceExaminations
export const selectSelectedEntranceExamination = (state: any) => state.examination.entranceExamination
export const selectRegistrationList = (state: any) => state.examination.registrationList
export const selectEntranceExaminationsDropdownItems = (state: any) => state.examination.entranceExamDropdown
/* Entrance Examination End */

export default ExaminationSlice.reducer
import moment from 'moment'
import { confirmAlert } from 'react-confirm-alert'
import { DocumentTypeDropdownItemType, DocumentTypeResponseType, DropdownItemType, EmployeeResponseType, EmployeesResponseCustomType, MetaType } from '../type'

/* start get total number of pages */
export const getTotalPages = (meta: MetaType | undefined) => {
    return (meta !== undefined) ? Math.ceil(meta.total / meta.per_page) || 1 : 1
}
/* end get total number of pages */

export const getFullName = (firstname?: string, middlename?: string | null, lastname?: string | null) => {
    return `${firstname} ${middlename || ''} ${lastname || ''}`
}

/* start dropdown label by id */
export const getDropdownLabelById = (items: DropdownItemType[], id: any, placeholder?: string) => {
    if (items !== undefined) {
        const item = items.filter((item: DropdownItemType) => item.id === id)
        if (item.length > 0) {
            return item[0].title
        }
    }
    return placeholder || 'Select here'
}
/* end dropdown label by id */

/* Delete with confirmation start */
export const deleteWithConfirmation = (deleteAction: () => any, cancelAction: () => any) => {
    confirmAlert({
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this?',
        closeOnClickOutside: false,
        buttons: [
            {
                label: 'Yes',
                onClick: deleteAction
            },
            {
                label: 'No',
                onClick: cancelAction
            }
        ]
    })
}
/* Delete with confirmation end */

/* Start get employees list */
export const getEmployeesForList = (employees: EmployeeResponseType[]) => {
    return employees?.map((employee: EmployeeResponseType) => {
        return {
            Employee_ID: employee.id,
            Name: getFullName(employee.firstname, employee.middlename, employee.lastname),
            Email: employee.email,
            Phone: employee.mobile,
            Department: employee.employee_profile?.department?.name || 'NA',
            Status: employee.status ? 'Active' : 'Not Active',
            Login_Access: employee.user ? (employee.user.status ? 'Available' : 'Suspended') : 'Not Available'
        } as EmployeesResponseCustomType
    })
}
/* End get employees list */

/* start get employee experience */
export const getEmployeeExperience = (startDate: string, endDate?: string) => {
    let joiningDate = moment(startDate, 'YYYYMMDD')
    let leavingDate = moment(endDate, 'YYYYMMDD')
    const experience = joiningDate.from(leavingDate, true)
    return experience
}
/* end get employee experience */

/* start dropdown list item */
export const getDocumentTypeForDropdown = (documentTypes: DocumentTypeResponseType[]) => {
    return documentTypes?.map((documentType: DocumentTypeResponseType) => {
        return {
            id: documentType.id,
            title: documentType.name,
            attributes: documentType.attributes
        } as DocumentTypeDropdownItemType
    })
}
/* end dropdown list item */
import { useParams } from 'react-router-dom'
import React, { Suspense } from 'react'
import { GlobalLayout, LoaderFour } from '../features/layout'
import { StudentList, StudentEdit, StudentView, SettlementList, StudentTransferCertificateList, StudentAttendanceList, StudentAcademicsList } from '../features/student'

const StudentPage = () => {
    const { type } = useParams() as any
    return (
        <Suspense fallback={<LoaderFour />}>
            <GlobalLayout>
                {type === 'students' && <StudentList />}
                {type === 'edit' && <StudentEdit />}
                {type === 'view' && <StudentView />}
                {type === 'settlements' && <SettlementList />}
                {type === 'transfer-certificates' && <StudentTransferCertificateList />}
                {type === 'attendances' && <StudentAttendanceList />}
                {type === 'academics' && <StudentAcademicsList />}
            </GlobalLayout>
        </Suspense>
    )
}

export default StudentPage
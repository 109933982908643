import React, { useEffect, useState } from 'react'
import { CInput, CInputGroup, CInputGroupAppend, CInputGroupPrepend, CInputGroupText, CInvalidFeedback, CLabel } from '@coreui/react'
import { DateTimeRangeInputPropType } from './type'
import { getFullDate, handleDateInput, handleTwoDigitYears, validateDate } from './helper'
import CIcon from '@coreui/icons-react'

export const DateTimeRange = ({
    value,
    onValueChange,
    label,
    required,
    placeholder,
    leftIcon,
    leftText,
    rightIcon,
    rightText,
    autocomplete,
    error,
    size,
    className
}: DateTimeRangeInputPropType) => {

    const [dateInputValue, setDateInputValue] = useState(handleDateInput(value))
    const [errorMessage, setErrorMessage] = useState('')

    const onDateValueChange = (formattedDate: string | null) => {
        if (!!formattedDate || onValueChange) {
            return onValueChange(formattedDate)
        }
    }

    // onChange Event Handlers Start

    const handleDateOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const value = event.target.value

        if (value === null || value === undefined || value === '') {
            setDateInputValue('')
        }
        else {
            setDateInputValue(value.replace(/\D||\S/, ''))
        }
    }

    // onChange Event Handlers end

    // onFocus Event Handlers Start

    const handleDateOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setErrorMessage('')

        const value = event.target.value
        let startDate = value.slice(0, 10)
        let endDate = value.slice(11, 21)

        if (value === null || value === undefined || value === '') {
            return setDateInputValue('')
        }
        else if (value.length === 10) {
            const dateArray = value.split('-')
            return setDateInputValue(dateArray[0] + dateArray[1] + dateArray[2])
        }
        else if (value.length === 21) {
            const startDateArray = startDate.split('-')
            const endDateArray = endDate.split('-')
            return setDateInputValue(startDateArray[0] + startDateArray[1] + startDateArray[2] + '-' + endDateArray[0] + endDateArray[1] + endDateArray[2])
        }
        else {
            setDateInputValue(value)
        }
    }

    // onFocus Event Handlers End

    // onBlur Event Handlers Start

    const handleDateOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const value = event.target.value
        let startDate = (value.length === 6 || value.length === 8) ? value : value.slice(0, 6)
        let endDate = (value.length === 13) ? value.slice(7, 13) : ''

        if (!!value) {
            if (value.length === 6) {
                const convertedYearEntry = handleTwoDigitYears(value.slice(4, 6))
                startDate = (value.slice(0, 2) + '-' + value.slice(2, 4) + '-' + convertedYearEntry)
            }
            else if (value.length === 8) {
                startDate = (value.slice(0, 2) + '-' + value.slice(2, 4) + '-' + value.slice(4, 8))
            }
            else if (value.length === 13) {
                const convertedFirstDateYearEntry = handleTwoDigitYears(value.slice(4, 6))
                const convertedEndDateYearEntry = handleTwoDigitYears(value.slice(11, 13))
                startDate = (value.slice(0, 2) + '-' + value.slice(2, 4) + '-' + convertedFirstDateYearEntry)
                endDate = (value.slice(7, 9) + '-' + value.slice(9, 11) + '-' + convertedEndDateYearEntry)
            }
            else if (value.length === 15) {
                const convertedEndDateYearEntry = handleTwoDigitYears(value.slice(13, 15))
                startDate = (value.slice(0, 2) + '-' + value.slice(2, 4) + '-' + value.slice(4, 8))
                endDate = (value.slice(9, 11) + '-' + value.slice(11, 13) + '-' + convertedEndDateYearEntry)
            }
            else if (value.length === 17) {
                startDate = (value.slice(0, 2) + '-' + value.slice(2, 4) + '-' + value.slice(4, 8))
                endDate = (value.slice(9, 11) + '-' + value.slice(11, 13) + '-' + value.slice(13, 17))
            }
            else {
                startDate = event.target.value
            }
        }
        else {
            startDate = ''
        }
        setDateInputValue(getFullDate(startDate, endDate))
        return handleDate(startDate, endDate)
    }

    // onBlur Event Handlers End

    // Date Conversion Logic For API Start

    const handleDate = (startDateInputValue: string | null, endDateInputValue: string | null) => {
        if (!!startDateInputValue && !!endDateInputValue) {
            if ((startDateInputValue <= endDateInputValue) && (validateDate(startDateInputValue) && validateDate(endDateInputValue))) {
                const startDateArray = startDateInputValue.split('-')
                const endDateArray = endDateInputValue.split('-')
                return onDateValueChange(startDateArray[2] + '-' + startDateArray[1] + '-' + startDateArray[0] + ' ' + endDateArray[2] + '-' + endDateArray[1] + '-' + endDateArray[0])
            }
            else if (validateDate(startDateInputValue) && !validateDate(endDateInputValue)) {
                const startDateArray = startDateInputValue.split('-')
                setErrorMessage('Invalid end date entered')
                return onDateValueChange(startDateArray[2] + '-' + startDateArray[1] + '-' + startDateArray[0])
            }
            else if (!validateDate(startDateInputValue) && validateDate(endDateInputValue)) {
                setErrorMessage('Invalid start date entered')
                return onDateValueChange(null)
            }
            else {
                setErrorMessage('Please enter valid end date')
                return onDateValueChange(null)
            }
        }
        else if (!!startDateInputValue && !endDateInputValue) {
            if (validateDate(startDateInputValue)) {
                const startDateArray = startDateInputValue.split('-')
                return onDateValueChange(startDateArray[2] + '-' + startDateArray[1] + '-' + startDateArray[0])
            }
            else {
                setErrorMessage('Invalid start date entered')
                return onDateValueChange(null)
            }
        }
        else if (!startDateInputValue && !!endDateInputValue) {
            setErrorMessage('Please provide a date')
            return onDateValueChange(null)
        }
        else {
            setErrorMessage('Please provide a date')
            return onDateValueChange(null)
        }
    }

    // Date Conversion Logic For API End

    // Update Input Logic Start

    useEffect(() => {
        setDateInputValue(value)
    }, [value])

    // Update Input Logic End

    return (
        <>
            {label && <CLabel className={required ? 'required-class font-weight-bolder' : 'font-weight-bolder'}>{label}</CLabel>}
            <CInputGroup>
                {(leftIcon || leftText) && <CInputGroupPrepend>
                    <CInputGroupText className={(required && !label) ? 'required-class' : ''}>
                        {leftIcon && <CIcon name={leftIcon || 'cil-calendar'} />}
                        {leftText && <>{leftText}</>}
                    </CInputGroupText>
                </CInputGroupPrepend>}
                <CInput
                    value={dateInputValue || undefined || ''}
                    type={'text'}
                    onChange={handleDateOnChange}
                    onFocus={handleDateOnFocus}
                    onBlur={handleDateOnBlur}
                    placeholder={placeholder || 'Enter Here..'}
                    autoComplete={autocomplete || 'none'}
                    invalid={(error || errorMessage) ? true : false}
                    size={size}
                    className={className}
                />
                {error && <CInvalidFeedback>{error}</CInvalidFeedback>}
                {(errorMessage && error === undefined) && <CInvalidFeedback>{errorMessage}</CInvalidFeedback>}
                {(rightIcon || rightText) && <CInputGroupAppend>
                    <CInputGroupText>
                        {rightIcon && <CIcon name={rightIcon || 'cil-clock'} />}
                        {rightText && <CInputGroupText>{rightText}</CInputGroupText>}
                    </CInputGroupText>
                </CInputGroupAppend>}
            </CInputGroup>
        </>
    )
}
import { useParams } from 'react-router-dom'
import React, { Suspense } from 'react'
import { GlobalLayout, LoaderFour } from '../features/layout'
import { AreaList, CountryList, DistrictList, StateList } from '../features/location'

const LocationPage = () => {
    const { type } = useParams() as any
    return (
        <Suspense fallback={<LoaderFour/>}>
            <GlobalLayout>
                {type === 'areas' && <AreaList/>}
                {type === 'countries' && <CountryList/>}
                {type === 'states' && <StateList/>}
                {type === 'districts' && <DistrictList/>}
            </GlobalLayout>
        </Suspense>
    )
}

export default LocationPage
import React from 'react'

const DeveloperBoard = React.lazy(()=>import('./developer/dashboard'))
const AdminBoard = React.lazy(()=>import('./admin/dashboard'))
const DefaultBoard = React.lazy(()=>import('./admin/dashboard'))

export {
    DeveloperBoard,
    AdminBoard,
    DefaultBoard
}
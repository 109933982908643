import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getCastes, getCasteDropdownItems, getReligions, getSelectedCaste, getSelectedReligion, getReligionDropdownItems, getDocumentTypes, getSelectedDocumentType, getIncomeSlabs, getSelectedIncomeSlab, getOccupations, getSelectedOccupation, getSelectedQualification, getQualifications, getEntranceExaminationCentres, getSelectedEntranceExaminationCentre, getDepartments, getSelectedDepartment, getDepartmentDropdownItems } from './api'
import { getCastesForDropdown, getDepartmentsForDropdown, getReligionsForDropdown } from './helper'
import { CasteListResponseType, CasteResponseType, DepartmentListResponseType, DepartmentResponseType, DocumentTypeListResponseType, DocumentTypeResponseType, DropdownItemType, EntranceExaminationCentreListResponseType, EntranceExaminationCentreResponseType, IncomeSlabListResponseType, IncomeSlabResponseType, MetaType, OccupationListResponseType, OccupationResponseType, QualificationListResponseType, QualificationResponseType, ReligionListResponseType, ReligionResponseType } from './type'

export const MiscSlice = createSlice({
    name: 'misc',
    initialState: {
        /* start comman */
        loading:false as boolean,
        meta: {} as MetaType,
        /* start comman */

        /* start religion */
        selectedReligionPage: 1 as number,
        religions: [] as ReligionResponseType[],
        selectedReligion: {} as ReligionResponseType,
        religionDropdownItems: [] as DropdownItemType[],
        /* end religion */

        /* start caste */
        selectedCastePage: 1 as number,
        castes: [] as CasteResponseType[],
        selectedCaste: {} as CasteResponseType,
        casteDropdownItems: [] as DropdownItemType[],
        /* end caste */

        /* start document type */
        selectedDocumentTypePage: 1 as number,
        documentTypes: [] as DocumentTypeResponseType[],
        selectedDocumentType: {} as DocumentTypeResponseType,
        /* end document type */

        /* start income slab */
        selectedIncomeSlabPage: 1 as number,
        incomeSlabs: [] as IncomeSlabResponseType[],
        selectedIncomeSlab: {} as IncomeSlabResponseType,
        /* end income slab */

        /* start occupation */
        selectedOccupationPage: 1 as number,
        occupations: [] as OccupationResponseType[],
        selectedOccupation: {} as OccupationResponseType,
        /* end occupation */

        /* start qualification */
        selectedQualificationPage: 1 as number,
        qualifications: [] as QualificationResponseType[],
        selectedQualification: {} as QualificationResponseType,
        /* end qualification */

        /* start entrance examination centre */
        selectedEntranceExaminationCentrePage: 1 as number,
        entranceExaminationCentres: [] as EntranceExaminationCentreResponseType[],
        selectedEntranceExaminationCentre: {} as EntranceExaminationCentreResponseType,
        /* end entrance examination centre */

        /* start department */
        selectedDepartmentPage: 1 as number,
        departments: [] as DepartmentResponseType[],
        selectedDepartment: {} as DepartmentResponseType,
        departmentDropdownItems: [] as DropdownItemType[]
        /* start department */

    },
    reducers:{
        /* start comman */
        setLoading: (state, action:PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setMeta: (state, action:PayloadAction<MetaType>) => {
            state.meta = action.payload
        },
        /* start comman */

        /* start religion */
        setSelectedReligionPage: (state, action:PayloadAction<number>) => {
            state.selectedReligionPage = action.payload
        },
        setReligions: (state, action:PayloadAction<ReligionResponseType[]>) => {
            state.religions = action.payload
        },
        setSelectedReligion: (state, action:PayloadAction<ReligionResponseType>) => {
            state.selectedReligion = action.payload
        },
        setReligionDropdownItems: (state, action:PayloadAction<DropdownItemType[]>) => {
            state.religionDropdownItems = action.payload
        },
        /* end religion */

        /* start caste */
        setSelectedCastePage: (state, action:PayloadAction<number>) => {
            state.selectedCastePage = action.payload
        },
        setCastes: (state, action:PayloadAction<CasteResponseType[]>) => {
            state.castes = action.payload
        },
        setSelectedCaste: (state, action:PayloadAction<CasteResponseType>) => {
            state.selectedCaste = action.payload
        },
        setCasteDropdownItems: (state, action:PayloadAction<DropdownItemType[]>) => {
            state.casteDropdownItems = action.payload
        },
        /* end caste */

        /* start document type */
        setSelectedDocumentTypePage: (state, action:PayloadAction<number>) => {
            state.selectedDocumentTypePage = action.payload
        },
        setDocumentTypes: (state, action:PayloadAction<DocumentTypeResponseType[]>) => {
            state.documentTypes = action.payload
        },
        setSelectedDocumentType: (state, action:PayloadAction<DocumentTypeResponseType>) => {
            state.selectedDocumentType = action.payload
        },
        /* end document type */

        /* start income slab */
        setSelectedIncomeSlabPage: (state, action:PayloadAction<number>) => {
            state.selectedIncomeSlabPage = action.payload
        },
        setIncomeSlabs: (state, action:PayloadAction<IncomeSlabResponseType[]>) => {
            state.incomeSlabs = action.payload
        },
        setSelectedIncomeSlab: (state, action:PayloadAction<IncomeSlabResponseType>) => {
            state.selectedIncomeSlab = action.payload
        },
        /* end income slab */

        /* start occupation */
        setSelectedOccupationPage: (state, action:PayloadAction<number>) => {
            state.selectedOccupationPage = action.payload
        },
        setOccupations: (state, action:PayloadAction<OccupationResponseType[]>) => {
            state.occupations = action.payload
        },
        setSelectedOccupation: (state, action:PayloadAction<OccupationResponseType>) => {
            state.selectedOccupation = action.payload
        },
        /* end occupation */

        /* start qualification */
        setSelectedQualificationPage: (state, action:PayloadAction<number>) => {
            state.selectedQualificationPage = action.payload
        },
        setQualifications: (state, action:PayloadAction<QualificationResponseType[]>) => {
            state.qualifications = action.payload
        },
        setSelectedQualification: (state, action:PayloadAction<QualificationResponseType>) => {
            state.selectedQualification = action.payload
        },
        /* end qualification */

        /* start entrance examination centre */
        setSelectedEntranceExaminationCentrePage: (state, action:PayloadAction<number>) => {
            state.selectedEntranceExaminationCentrePage = action.payload
        },
        setEntranceExaminationCentres: (state, action:PayloadAction<EntranceExaminationCentreResponseType[]>) => {
            state.entranceExaminationCentres = action.payload
        },
        setSelectedEntranceExaminationCentre: (state, action:PayloadAction<EntranceExaminationCentreResponseType>) => {
            state.selectedEntranceExaminationCentre = action.payload
        },
        /* end entrance examination centre */

        /* start department */
        setSelectedDepartmentPage: (state, action:PayloadAction<number>) => {
            state.selectedDepartmentPage = action.payload
        },
        setDepartments: (state, action:PayloadAction<DepartmentResponseType[]>) => {
            state.departments = action.payload
        },
        setSelectedDepartment: (state, action:PayloadAction<DepartmentResponseType>) => {
            state.selectedDepartment = action.payload
        },
        setDepartmentDropdownItems: (state, action:PayloadAction<DropdownItemType[]>) => {
            state.departmentDropdownItems = action.payload
        }
        /* end department */

   }
})

export const {
    /* start comman */
    setLoading,
    setMeta,
    /* end comman */

    /* start religion */
    setSelectedReligionPage,
    setReligions,
    setSelectedReligion,
    setReligionDropdownItems,
    /* end religion */

    /* start caste */
    setSelectedCastePage,
    setCastes,
    setSelectedCaste,
    setCasteDropdownItems,
    /* end caste */

    /* start document type */
    setSelectedDocumentTypePage,
    setDocumentTypes,
    setSelectedDocumentType,
    /* end document type */

    /* start income slab */
    setSelectedIncomeSlabPage,
    setIncomeSlabs,
    setSelectedIncomeSlab,
    /* end income slab */

    /* start occupation */
    setSelectedOccupationPage,
    setOccupations,
    setSelectedOccupation,
    /* end occupation */

    /* start qualification */
    setSelectedQualificationPage,
    setQualifications,
    setSelectedQualification,
    /* end qualification */

    /* start qualification */
    setSelectedEntranceExaminationCentrePage,
    setEntranceExaminationCentres,
    setSelectedEntranceExaminationCentre,
    /* end qualification */

    /* start department */
    setSelectedDepartmentPage,
    setDepartments,
    setSelectedDepartment,
    setDepartmentDropdownItems
    /* end department */

} = MiscSlice.actions

/* start religion */
export const loadReligions = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: ReligionListResponseType = await getReligions(page, records)
    dispatch(setReligions(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSelectedReligion = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: ReligionResponseType = await getSelectedReligion(id)
    dispatch(setSelectedReligion(response))
    dispatch(setLoading(false))
}

export const loadReligionDropdownItems = (sort?: string, order?:string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: {items: ReligionResponseType[]} = await getReligionDropdownItems(sort, order)
    const dropdownItems= getReligionsForDropdown(response.items)
    dispatch(setReligionDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}
/* end religion */

/* start caste */
export const loadCastes = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: CasteListResponseType = await getCastes(page, records)
    dispatch(setCastes(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSelectedCaste = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: CasteResponseType = await getSelectedCaste(id)
    dispatch(setSelectedCaste(response))
    dispatch(setLoading(false))
}

export const loadCasteDropdownItems = (sort?: string, order?:string, religion_id?: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: {items: CasteResponseType[]} = await getCasteDropdownItems(sort, order, religion_id)
    const dropdownItems = getCastesForDropdown(response.items)
    dispatch(setCasteDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}
/* end caste */

/* start document type */
export const loadDocumentTypes = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: DocumentTypeListResponseType = await getDocumentTypes(page, records)
    dispatch(setDocumentTypes(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSelectedDocumentType = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: DocumentTypeResponseType = await getSelectedDocumentType(id)
    dispatch(setSelectedDocumentType(response))
    dispatch(setLoading(false))
}
/* end document type */

/* start income slab */
export const loadIncomeSlabs = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: IncomeSlabListResponseType = await getIncomeSlabs(page, records)
    dispatch(setIncomeSlabs(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSelectedIncomeSlab = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: IncomeSlabResponseType = await getSelectedIncomeSlab(id)
    dispatch(setSelectedIncomeSlab(response))
    dispatch(setLoading(false))
}
/* end income slab */

/* start occupation */
export const loadOccupations = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: OccupationListResponseType = await getOccupations(page, records)
    dispatch(setOccupations(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSelectedOccupation = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: OccupationResponseType = await getSelectedOccupation(id)
    dispatch(setSelectedOccupation(response))
    dispatch(setLoading(false))
}
/* end occupation */

/* start qualification */
export const loadQualifications = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: QualificationListResponseType = await getQualifications(page, records)
    dispatch(setQualifications(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSelectedQualification = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: QualificationResponseType = await getSelectedQualification(id)
    dispatch(setSelectedQualification(response))
    dispatch(setLoading(false))
}
/* end qualification */

/* start entrance examination centre */
export const loadEntranceExaminationCentres = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: EntranceExaminationCentreListResponseType = await getEntranceExaminationCentres(page, records)
    dispatch(setEntranceExaminationCentres(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSelectedEntranceExaminationCentre = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: EntranceExaminationCentreResponseType = await getSelectedEntranceExaminationCentre(id)
    dispatch(setSelectedEntranceExaminationCentre(response))
    dispatch(setLoading(false))
}
/* end entrance examination centre */

/* start department */
export const loadDepartments = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: DepartmentListResponseType = await getDepartments(page, records)
    dispatch(setDepartments(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadDepartmentDropdownItems = (sort?: string, order?:string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: {items: DepartmentResponseType[]} = await getDepartmentDropdownItems(sort, order)
    const dropdownItems= getDepartmentsForDropdown(response.items)
    dispatch(setDepartmentDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}

export const loadSelectedDepartment = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response = await getSelectedDepartment(id)
    dispatch(setSelectedDepartment(response))
    dispatch(setLoading(false))
}
/* end department */

/* start comman */
export const selectLoading = (state:any) => state.misc.loading
export const selectMeta = (state:any) => state.misc.meta
/* end comman */

/* start religion */
export const selectSelectedReligionPage = (state:any) => state.misc.selectedReligionPage
export const selectReligions = (state:any) => state.misc.religions
export const selectSelectedReligion = (state:any) => state.misc.selectedReligion
export const selectReligionDropdownItems = (state:any) => state.misc.religionDropdownItems
/* end religion */

/* start caste */
export const selectSelectedCastePage = (state:any) => state.misc.selectedCastePage
export const selectCastes = (state:any) => state.misc.castes
export const selectSelectedCaste = (state:any) => state.misc.selectedCaste
export const selectCasteDropdownItems = (state:any) => state.misc.casteDropdownItems
/* end caste */

/* start document type */
export const selectSelectedDocumentTypePage = (state:any) => state.misc.selectedDocumentTypePage
export const selectDocumentTypes = (state:any) => state.misc.documentTypes
export const selectSelectedDocumentType = (state:any) => state.misc.selectedDocumentType
/* end document type */

/* start income slab */
export const selectSelectedIncomeSlabPage = (state:any) => state.misc.selectedIncomeSlabPage
export const selectIncomeSlabs = (state:any) => state.misc.incomeSlabs
export const selectSelectedIncomeSlab = (state:any) => state.misc.selectedIncomeSlab
/* end income slab */

/* start occupation */
export const selectSelectedOccupationPage = (state:any) => state.misc.selectedOccupationPage
export const selectOccupations = (state:any) => state.misc.occupations
export const selectSelectedOccupation = (state:any) => state.misc.selectedOccupation
/* end occupation */

/* start qualification */
export const selectSelectedQualificationPage = (state:any) => state.misc.selectedQualificationPage
export const selectQualifications = (state:any) => state.misc.qualifications
export const selectSelectedQualification = (state:any) => state.misc.selectedQualification
/* end qualification */

/* start entranceExaminationCentre */
export const selectSelectedEntranceExaminationCentrePage = (state:any) => state.misc.selectedEntranceExaminationCentrePage
export const selectEntranceExaminationCentres = (state:any) => state.misc.entranceExaminationCentres
export const selectSelectedEntranceExaminationCentre = (state:any) => state.misc.selectedEntranceExaminationCentre
/* end entranceExaminationCentre */

/* start department */
export const selectSelectedDepartmentPage = (state: any) => state.misc.selectedDepartmentPage
export const selectDepartments = (state: any) => state.misc.departments
export const selectSelectedDepartment = (state: any) => state.misc.selectedDepartment
export const selectedDepartmentDropdownItems = (state: any) => state.misc.departmentDropdownItems
/* end department */

export default MiscSlice.reducer
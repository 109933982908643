import { useParams } from 'react-router-dom'
import React, { Suspense } from 'react'
import { GlobalLayout, LoaderFour } from '../features/layout'
import { EmployeeCreate, EmployeeEdit, EmployeeList } from '../features/employee'

const EmployeePage = () => {
    const { type } = useParams() as any
    return (
        <Suspense fallback={<LoaderFour />}>
            <GlobalLayout>
                {type === 'list' && <EmployeeList />}
                {type === 'create' && <EmployeeCreate />}
                {type === 'edit' && <EmployeeEdit />}
            </GlobalLayout>
        </Suspense>
    )
}

export default EmployeePage
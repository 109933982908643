import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getSelectedArea, getAreas, getCountries, getSelectedCountry, getStates, getSelectedState, getDistricts, getSelectedDistrict, getAreaDropdownItems, getCountryDropdownItems, getStateDropdownItems, getDistrictDropdownItems } from './api'
import { getAreasForDropdown, getCountriesForDropdown, getDistrictsForDropdown, getStatesForDropdown } from './helper'
import { MetaType, AreaListResponseType, AreaResponseType, CountryResponseType, CountryListResponseType, StateResponseType, StateListResponseType, DistrictResponseType, DistrictListResponseType, DropdownItemType } from './type'

export const LocationSlice = createSlice({
    name: 'location',
    initialState: {
        /* start comman */
        loading:false as boolean,
        meta: {} as MetaType,
        /* end comman */

        /* start area */
        selectedCountryPage: 1 as number,
        countries: [] as CountryResponseType[],
        selectedCountry: {} as CountryResponseType,
        countryDropdownItems: [] as DropdownItemType[],
        /* end area */

        /* start state */
        selectedStatePage: 1 as number,
        states: [] as StateResponseType[],
        selectedState: {} as StateResponseType,
        stateDropdownItems: [] as DropdownItemType[],
        /* end state */

        /* start district */
        selectedDistrictPage: 1 as number,
        districts: [] as DistrictResponseType[],
        selectedDistrict: {} as DistrictResponseType,
        districtDropdownItems: [] as DropdownItemType[],
        /* end district */

        /* start area */
        selectedAreaPage: 1 as number,
        areas: [] as AreaResponseType[],
        selectedArea: {} as AreaResponseType,
        areaDropdownItems: [] as DropdownItemType[],
        /* end area */

    },
    reducers:{
        /* start comman */
        setLoading: (state, action:PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setMeta: (state, action:PayloadAction<MetaType>) => {
            state.meta = action.payload
        },
        /* end comman */

        /* start country */
        setSelectedCountryPage: (state, action:PayloadAction<number>) => {
            state.selectedCountryPage = action.payload
        },
        setCountries: (state, action:PayloadAction<CountryResponseType[]>) => {
            state.countries = action.payload
        },
        setSelectedCountry: (state, action:PayloadAction<CountryResponseType>) => {
            state.selectedCountry = action.payload
        },
        setCountryDropdownItems: (state, action:PayloadAction<DropdownItemType[]>) => {
            state.countryDropdownItems = action.payload
        },
        /* end country */

        /* start state */
        setSelectedStatePage: (state, action:PayloadAction<number>) => {
            state.selectedStatePage = action.payload
        },
        setStates: (state, action:PayloadAction<StateResponseType[]>) => {
            state.states = action.payload
        },
        setSelectedState: (state, action:PayloadAction<StateResponseType>) => {
            state.selectedState = action.payload
        },
        setStateDropdownItems: (state, action:PayloadAction<DropdownItemType[]>) => {
            state.stateDropdownItems = action.payload
        },
        /* end state */

        /* start district */
        setSelectedDistrictPage: (state, action:PayloadAction<number>) => {
            state.selectedDistrictPage = action.payload
        },
        setDistricts: (state, action:PayloadAction<DistrictResponseType[]>) => {
            state.districts = action.payload
        },
        setSelectedDistrict: (state, action:PayloadAction<DistrictResponseType>) => {
            state.selectedDistrict = action.payload
        },
        setDistrictDropdownItems: (state, action:PayloadAction<DropdownItemType[]>) => {
            state.districtDropdownItems = action.payload
        },
        /* end district */

        /* start area */
        setSelectedAreaPage: (state, action:PayloadAction<number>) => {
            state.selectedAreaPage = action.payload
        },
        setAreas: (state, action:PayloadAction<AreaResponseType[]>) => {
            state.areas = action.payload
        },
        setSelectedArea: (state, action:PayloadAction<AreaResponseType>) => {
            state.selectedArea = action.payload
        },
        setAreaDropdownItems: (state, action:PayloadAction<DropdownItemType[]>) => {
            state.areaDropdownItems = action.payload
        },
        /* end area */
   }
})

export const {
    /* start comman */
    setLoading,
    setMeta,
    /* end comman */

    /* start country */
    setSelectedCountryPage,
    setCountries,
    setSelectedCountry,
    setCountryDropdownItems,
    /* end country */

    /* start state */
    setSelectedStatePage,
    setStates,
    setSelectedState,
    setStateDropdownItems,
    /* end state */

    /* start district */
    setSelectedDistrictPage,
    setDistricts,
    setSelectedDistrict,
    setDistrictDropdownItems,
    /* end district */

    /* start area */
    setSelectedAreaPage,
    setAreas,
    setSelectedArea,
    setAreaDropdownItems,
    /* start area */


} = LocationSlice.actions
/* start country */
export const loadCountries = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: CountryListResponseType = await getCountries(page, records)
    dispatch(setCountries(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSelectedCountry = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: CountryResponseType = await getSelectedCountry(id)
    dispatch(setSelectedCountry(response))
    dispatch(setLoading(false))
}

export const loadCountryDropdownItems = (sort?: string, order?:string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: {items: CountryResponseType[]} = await getCountryDropdownItems(sort, order)
    const dropdownItems = getCountriesForDropdown(response.items)
    dispatch(setCountryDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}
/* end country */

/* start state */
export const loadStates = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StateListResponseType = await getStates(page, records)
    dispatch(setStates(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSelectedState = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StateResponseType = await getSelectedState(id)
    dispatch(setSelectedState(response))
    dispatch(setLoading(false))
}

export const loadStateDropdownItems = (sort?: string, order?: string, country_id?: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: {items: StateResponseType[]} = await getStateDropdownItems(sort, order, country_id)
    const dropdownItems = getStatesForDropdown(response.items)
    dispatch(setStateDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}
/* end state */

/* start district */
export const loadDistricts = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: DistrictListResponseType = await getDistricts(page, records)
    dispatch(setDistricts(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSelectedDistrict = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: DistrictResponseType = await getSelectedDistrict(id)
    dispatch(setSelectedDistrict(response))
    dispatch(setLoading(false))
}

export const loadDistrictDropdownItems = (sort?: string, order?: string, country_id?: number, state_id?: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: {items: DistrictResponseType[]} = await getDistrictDropdownItems(sort, order, country_id, state_id)
    const dropdownItems = getDistrictsForDropdown(response.items)
    dispatch(setDistrictDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}
/* end district */

/* start area */
export const loadAreas = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: AreaListResponseType = await getAreas(page, records)
    dispatch(setAreas(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSelectedArea = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: AreaResponseType = await getSelectedArea(id)
    dispatch(setSelectedArea(response))
    dispatch(setLoading(false))
}

export const loadAreaDropdownItems = (sort?: string, order?: string, country_id?: number, state_id?: number, district_id?: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: {items: AreaResponseType[]} = await getAreaDropdownItems(sort, order, country_id, state_id, district_id)
    const dropdownItems = getAreasForDropdown(response.items)
    dispatch(setAreaDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}
/* end area */

/* start comman */
export const selectLoading = (state:any) => state.location.loading
export const selectMeta = (state:any) => state.location.meta
/* end comman */

/* start country */
export const selectSelectedCountryPage = (state:any) => state.location.selectedCountryPage
export const selectCountries = (state:any) => state.location.countries
export const selectSelectedCountry = (state:any) => state.location.selectedCountry
export const selectCountryDropdownItems = (state:any) => state.location.countryDropdownItems
/* end country */

/* start state */
export const selectSelectedStatePage = (state:any) => state.location.selectedStatePage
export const selectStates = (state:any) => state.location.states
export const selectSelectedState = (state:any) => state.location.selectedState
export const selectStateDropdownItems = (state:any) => state.location.stateDropdownItems
/* end state */

/* start district */
export const selectSelectedDistrictPage = (state:any) => state.location.selectedDistrictPage
export const selectDistricts = (state:any) => state.location.districts
export const selectSelectedDistrict = (state:any) => state.location.selectedDistrict
export const selectDistrictDropdownItems = (state:any) => state.location.districtDropdownItems
/* end district */

/* start area */
export const selectSelectedAreaPage = (state:any) => state.location.selectedAreaPage
export const selectAreas = (state:any) => state.location.areas
export const selectSelectedArea = (state:any) => state.location.selectedArea
export const selectAreaDropdownItems = (state:any) => state.location.areaDropdownItems
/* end area */

export default LocationSlice.reducer
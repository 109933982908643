import React from 'react'

const ListRegistration = React.lazy(() => import('./registration/list'))
const CreateRegistration = React.lazy(() => import('./registration/create'))
const EditRegistration = React.lazy(() => import('./registration/edit'))
const FeeConfirmation = React.lazy(() => import('./registration/modals/feeConfirmation'))
const ShowStudentRegistration = React.lazy(() => import('./registration/show'))
const EntranceExamination = React.lazy(() => import('./registration/modals/entranceExamination'))
const AdmissionFeeConfirmation = React.lazy(() => import('./registration/modals/admissionFeeConfirmation'))
const AdmisstionList = React.lazy(() => import('./admission/index'))
const RegistrationListFilters = React.lazy(() => import('./registration/modals/filter'))
const AdmissionListFilter = React.lazy(() => import('./admission/modals/filter'))
const AdmisionListTcGenerate = React.lazy(()=>import('./admission/modals/generatetc'))
const AdmissionStandardEdit = React.lazy(()=>import('./admission/modals/standardEdit'))

export {
    ListRegistration,
    CreateRegistration,
    EditRegistration,
    FeeConfirmation,
    ShowStudentRegistration,
    EntranceExamination,
    AdmissionFeeConfirmation,
    AdmisstionList,
    RegistrationListFilters,
    AdmissionListFilter,
    AdmisionListTcGenerate,
    AdmissionStandardEdit
}
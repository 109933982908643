import { MetaType, DropdownItemType, SessionResponseType, TeacherResponseType, TeachersCutomeResponseType, StandardResponseType, SubjectResponseType } from '../type'

/* start get total number of pages */
export const getTotalPages = (meta: MetaType | undefined) => {
    return (meta !== undefined) ? Math.ceil(meta.total / meta.per_page) || 1 : 1
}
/* end get total number of pages */

export const getFullName = (firstname?: string, middlename?: string | null, lastname?: string | null) => {
    return `${firstname} ${middlename || ''} ${lastname || ''}`
}

export const getActiveSession = (sessions: SessionResponseType[]) => {
    if (sessions.length > 0) {
        return sessions.find((session: SessionResponseType) => session.is_current === true)
    }
    return {} as SessionResponseType
}

export const booleanToString = (key: boolean | string | number) => {
    if (typeof key === 'string') {
        return key === 'false' ? false : true
    }
    if (typeof key === 'number') {
        return key === 0 ? false : true
    }
    return key
}

/* start dropdown label by id */
export const getDropdownLabelById = (items: DropdownItemType[], id: any, placeholder?: string) => {
    if (items !== undefined) {
        const item = items.filter((item: DropdownItemType) => item.id === id)
        if (item.length > 0) {
            return item[0].title
        }
    }
    return placeholder || 'Select here'
}
/* end dropdown label by id */

/* start dropdown list */
export const getStandardsForDropdown = (standards: StandardResponseType[]) => {
    return standards?.map((standard: StandardResponseType) => {
        return {
            id: standard.id,
            title: standard.name
        } as DropdownItemType
    })
}

export const getSubjectsForDropdown = (subjects: SubjectResponseType[]) => {
    return subjects?.map((subject: SubjectResponseType) => {
        return {
            id: subject.id,
            title: subject.name
        } as DropdownItemType
    })
}
/* end dropdown list */

/* start get teachers list */
export const getTeachersForList = (teachers: TeacherResponseType[], desigantionList: DropdownItemType[]) => {
    return teachers?.map((teacher: TeacherResponseType) => {
        return {
            Emp_Id: teacher.id,
            Name: getFullName(teacher.firstname, teacher.middlename, teacher.lastname),
            Designation: getDropdownLabelById(desigantionList, teacher?.employee_profile?.designation) || 'NA',
            Department: teacher.employee_profile?.department?.name || 'NA',
            Phone_Number: teacher.mobile
        } as TeachersCutomeResponseType
    })
}
/* end get teachers list */

/* start get standard subjects */
export const getStandardSubjects = (compulsorySubjects: string[], optionalSubjects: string[]) => {
    const allSubjects = compulsorySubjects?.concat(optionalSubjects)
    return allSubjects
}

export const getInt = (num: string | number) => {
    return (typeof num === 'string') ? parseInt(num) : num
}
/* end get standard subjects */

/* get non existing standard list */
export const getNonExistStandardList = (existing:TeacherResponseType, standardList: DropdownItemType[], standardId?: string|number, update?: number|null) => {
    if(existing.teachers){
        let standardsExist = existing.teachers.map(teacher => teacher.standard_id)
        if(update && standardId) {
            standardsExist = standardsExist.filter(standardData => standardData !== getInt(standardId))
        }
        return standardList.filter(standard => !standardsExist.includes(getInt(standard.id)))
    }
    return standardList
}
/* end get non existing standard list */
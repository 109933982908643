import React from 'react'
import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react'
import { Button } from '../..'
import { FilterBoxType } from './type'

export const FilterBox = ({
    children,
    filterTitle,
    showFilterBox,
    setShowFilterBox,
    backdrop,
    onApply,
    onReset,
    applyTitle,
    resetTitle,
    applyDisable
}: FilterBoxType) => {

    const optionClose = () => {
        setShowFilterBox(!showFilterBox)
    }

    return (
        <CModal
            show={showFilterBox}
            onClose={optionClose}
            color={'primary'}
            closeOnBackdrop={backdrop || false}
        >
            <CModalHeader closeButton>
                <CModalTitle>{filterTitle || 'Filters'}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                {children}
            </CModalBody>
            <CModalFooter>
                {onReset && <Button
                    buttonText={resetTitle || 'Reset'}
                    buttonType={'danger'}
                    onClick={onReset}
                />}
                <Button
                    buttonText={applyTitle || 'Apply'}
                    buttonType={'primary'}
                    onClick={onApply}
                    disabled={applyDisable}
                />
            </CModalFooter>
        </CModal>
    )
}
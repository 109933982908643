import dateFormat from 'dateformat'
import { DailyFeeTransactionCustomResponseType, FeeDailyTransactionResponseType, FeeSummaryResponseType, SessionResponseType } from '../type'
import { MonthlyFeeResponseType, DropdownItemType, MetaType } from '../type'

export const getFullName = (firstname?: string, middlename?: string | null, lastname?: string | null) => {
    return `${firstname} ${middlename || ''} ${lastname || ''}`
}

/* start get dropdown items */
export const getDropdownLabelById = (items: DropdownItemType[], id: any, placeholder?: string) => {
    const item = items.filter((item: DropdownItemType) => item.id === id)
    if (item.length > 0) {
        return item[0].title
    }
    return placeholder || 'Select here'
}
/* end get dropdown items */

/* start get total number of pages */
export const getTotalPages = (meta: MetaType | undefined) => {
    return (meta !== undefined) ? Math.ceil(meta.total / meta.per_page) || 1 : 1
}
/* end get total number of pages */

/* start sessions dropdown list */
export const getSessionsForDropdown = (sessions: SessionResponseType[]) => {
    return sessions?.map((session: SessionResponseType) => {
        return {
            id: session.id,
            title: session.name
        } as DropdownItemType
    })
}
/* end sessions dropdown list */

export const getActiveSession = (sessions: SessionResponseType[]) => {
    if (sessions.length > 0) {
        return sessions.find((session: SessionResponseType) => session.is_current === true)
    }
    return {} as SessionResponseType
}

export const standardFeeTotalByMonth = (summary: FeeSummaryResponseType, month: number) => {
    let total = 0
    if (summary && 'expected' in summary && summary.expected.length) {
        summary.expected.forEach((item) => {
            const feeItem = item.fee.find((feeItem: MonthlyFeeResponseType) => feeItem.month === month)
            total += (feeItem?.sub_total || 0)
        })
    }
    return total.toFixed(2)
}

export const grandTotal = (summary: FeeSummaryResponseType) => {
    let total = 0
    if (summary && 'expected' in summary && summary.expected.length) {
        summary.expected.forEach((item) => {
            total += (item.total || 0)
        })
    }
    return total.toFixed(2)
}

export const getValueById = (items: DropdownItemType[], id: number) => {
    const item = items.find((item) => item.id === id)
    return item?.title
}

export const getDailyTransactionsForList = (transactions: FeeDailyTransactionResponseType[]) => {
    return transactions?.map((transaction: FeeDailyTransactionResponseType, index: number) => {
        let fines = 0
        transaction?.student_regular_fees?.forEach((fee) => fines += parseInt(fee.fine))
        return {
            '#': index + 1,
            'Adm#': transaction.student_regular_fees[0].student_id,
            Standard: transaction.student_regular_fees[0].standard?.name,
            Name: transaction.student_regular_fees[0].name,
            Father: transaction.student_regular_fees[0].father,
            Total_Payable: transaction?.total_payable,
            Amount_Paid: transaction.amount_paid,
            Concession: transaction?.concession,
            Transaction_By: getFullName(transaction?.user?.firstname, transaction?.user?.middlename, transaction?.user?.lastname),
            Month: transaction?.student_regular_fees?.map((fee) => fee.month),
            Fine: fines?.toFixed(2),
            Transaction_Date: dateFormat(transaction?.created_at, 'dS mmm, yy, h:MM TT'),
            Payment_Mode: `${transaction?.payment_mode.toUpperCase()} ${transaction.reference_number !== null ? `/ ${transaction.reference_number}` : ''}`
        } as DailyFeeTransactionCustomResponseType
    })
}
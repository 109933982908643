import React, { StrictMode } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import DashboardPage from './page/DashboardPage'
import LoginPage from './page/LoginPage'
import NotFoundPage from './page/NotFound'
import LogoutPage from './page/LogoutPage'
import UserPage from './page/UserPage'
import MiscPage from './page/MiscPage'
import LocationPage from './page/LocationPage'
import FeePage from './page/FeePage'
import AcademicPage from './page/AcademicPage'
import AdmissionPage from './page/AdmissionPage'
import RegistrationPage from './page/RegistrationPage'
import AccountPage from './page/AccountPage'
import PasswordForgetPage from './page/PasswordForgetPage'
import PasswordResetPage from './page/PasswordResetPage'
import StudentPage from './page/StudentPage'
import ServerErrorPage from './page/ServerError'
import ProfilePage from './page/ProfilePage'
import DeveloperPage from './page/DeveloperPage'
import EmployeePage from './page/EmployeePage'
import StaffPage from './page/StaffPage'
import ExaminationPage from './page/ExaminationPage'
import ReportPage from './page/ReportPage'

const App = () => {
  return (
    <Router>
      <StrictMode>
        <Switch>
          <Route path='/' exact><LoginPage/></Route>
          <Route path='/logout' exact><LogoutPage/></Route>
          <Route path='/forget' exact><PasswordForgetPage/></Route>
          <Route path='/password-reset' exact><PasswordResetPage/></Route>
          <Route path='/dashboard' exact><DashboardPage/></Route>
          <Route path='/profile' exact><ProfilePage/></Route>
          <Route path='/profile/:type'><ProfilePage/></Route>
          <Route path='/academic/:type'><AcademicPage/></Route>
          <Route path='/user-management/:type/list'><UserPage/></Route>
          <Route path='/misc-management/:type/list'><MiscPage/></Route>
          <Route path='/location-management/:type/list'><LocationPage/></Route>
          <Route path='/fee-management/:type/list'><FeePage/></Route>
          <Route path='/academic/:type/list'><AcademicPage/></Route>
          <Route path='/registration/:id/:type'><RegistrationPage/></Route>
          <Route path='/registration/:type'><RegistrationPage/></Route>
          <Route path='/admission/:type'><AdmissionPage/></Route>
          <Route path='/account/:type/fee'><AccountPage/></Route>
          <Route path='/student/:type/list'><StudentPage/></Route>
          <Route path='/student/:id/:type'><StudentPage/></Route>
          <Route path='/employee/:id/:type'><EmployeePage/></Route>
          <Route path='/employee/:type'><EmployeePage/></Route>
          <Route path='/staff/:type/list'><StaffPage/></Route>
          <Route path='/examination/:type/list'><ExaminationPage/></Route>
          <Route path='/developers-guide/:type'><DeveloperPage/></Route>
          <Route path={'/reports/fee/:type'}><ReportPage/></Route>
          <Route path='/server-error' exact><ServerErrorPage/></Route>
          <Route path='*'><NotFoundPage /></Route>
        </Switch>
      </StrictMode>
    </Router>
  )
}

export default App

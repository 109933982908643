import { useParams } from 'react-router-dom'
import React, { Suspense } from 'react'
import { AdmisstionList } from '../features/admission'
import { GlobalLayout, LoaderFour } from '../features/layout'

const AdmissionPage = () => {
    const { type } = useParams() as any
    return (
        <Suspense fallback={<LoaderFour/>}>
            <GlobalLayout>
                {type === 'list' && <AdmisstionList/>}
            </GlobalLayout>
        </Suspense>
    )
}

export default AdmissionPage
import { useParams } from 'react-router-dom'
import React, { Suspense } from 'react'
import { CreateRegistration, EditRegistration, ListRegistration, ShowStudentRegistration } from '../features/admission'
import { GlobalLayout, LoaderFour } from '../features/layout'

const RegistrationPage = () => {
    const { type } = useParams() as any
    return (
        <Suspense fallback={<LoaderFour/>}>
            <GlobalLayout>
                {type === 'create' && <CreateRegistration/>}
                {type === 'list' && <ListRegistration/>}
                {type === 'edit' && <EditRegistration/>}
                {type === 'show' && <ShowStudentRegistration/>}
            </GlobalLayout>
        </Suspense>
    )
}

export default RegistrationPage
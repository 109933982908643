import { useParams } from 'react-router-dom'
import React, { Suspense } from 'react'
import { SelectFeeDefaulter, SelectFeeType, TransactionList } from '../features/account'
import { GlobalLayout, LoaderFour } from '../features/layout'

const FeePage = () => {
    const { type } = useParams() as any
    return (
        <Suspense fallback={<LoaderFour/>}>
            <GlobalLayout>
             {type === 'collect' && <SelectFeeType/>}
             {type === 'defaulter' && <SelectFeeDefaulter/>}
             {type === 'transaction' && <TransactionList/>}
            </GlobalLayout>
        </Suspense>
    )
}

export default FeePage
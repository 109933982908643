const itemsListArray =  [
  {
    'to': '/dashboard',
    '_tag': 'CSidebarNavItem',
    'icon': 'cil-speedometer',
    'name': 'Dashboard',
    'path': 'dashboard'
  },
  {
    '_tag': 'CSidebarNavTitle',
    '_children': [
      'Admission Cell'
    ]
  },
  {
    '_tag': 'CSidebarNavDropdown',
    'icon': 'cil-layers',
    'name': 'Registrations',
    'path': 'registration',
    '_children': [
      {
        'to': '/registration/create',
        '_tag': 'CSidebarNavItem',
        'name': 'New Registration',
        'path': 'create',
        'scope': 'student_registrations.create'
      },
      {
        'to': '/registration/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Registrations List',
        'path': 'list',
        'scope': 'student_registrations.list'
      }
    ]
  },
  {
    '_tag': 'CSidebarNavDropdown',
    'icon': 'cil-layers',
    'name': 'Admissions',
    'path': 'admission',
    '_children': [
      {
        'to': '/admission/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Class Allotment',
        'path': 'list',
        'scope': 'admissions.list'
      }
    ]
  },
  {
    '_tag': 'CSidebarNavTitle',
    '_children': [
      'Accounts Cell'
    ]
  },
  {
    '_tag': 'CSidebarNavDropdown',
    'icon': 'cil-money',
    'name': 'Accounting',
    'path': 'account',
    '_children': [
      {
        'to': '/account/collect/fee',
        '_tag': 'CSidebarNavItem',
        'name': 'Fee Collect',
        'path': 'collect',
        'scope': 'fee_collects.collect'
      },
      {
        'to': '/account/refund/fee',
        '_tag': 'CSidebarNavItem',
        'name': 'Fee Refund',
        'path': 'refund',
        'scope': 'fee_collects.refund'
      },
      {
        'to': '/account/defaulter/fee',
        '_tag': 'CSidebarNavItem',
        'name': 'Fee Defaulters',
        'path': 'defaulter',
        'scope': 'fee_defaulters.list'
      },
      {
        'to': '/account/transaction/fee',
        '_tag': 'CSidebarNavItem',
        'name': 'Fee Transactions',
        'path': 'defaulter',
        'scope': 'fee_collects.collect'
      }
    ]
  },
  {
    '_tag': 'CSidebarNavTitle',
    '_children': [
      'Student Cell'
    ]
  },
  {
    '_tag': 'CSidebarNavDropdown',
    'icon': 'cil-smile',
    'name': 'Student',
    'path': 'student',
    '_children': [
      {
        'to': '/student/students/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Students',
        'path': 'students',
        'scope': 'students.list'
      },
      {
        'to': '/student/settlements/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Settlements',
        'path': 'settlements',
        'scope': 'student_settlements.list'
      },
      {
        'to': '/student/transfer-certificates/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Transfer Certificates',
        'path': 'transfer-certificates',
        'scope': 'student_transfer_certificates.list'
      }
    ]
  },
  {
    '_tag': 'CSidebarNavTitle',
    '_children': [
      'Management'
    ]
  },
  {
    '_tag': 'CSidebarNavDropdown',
    'icon': 'cil-school',
    'name': 'Academics',
    'path': 'academic',
    '_children': [
      {
        'to': '/academic/sessions/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Session',
        'path': 'sessions',
        'scope': 'sessions.list'
      },
      {
        'to': '/academic/standards/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Standard',
        'path': 'standards',
        'scope': 'standards.list'
      },
      {
        'to': '/academic/sections/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Section',
        'path': 'sections',
        'scope': 'sections.list'
      },
      {
        'to': '/academic/seats/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Seat',
        'path': 'seats',
        'scope': 'seats.list'
      },
      {
        'to': '/academic/subjects/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Subject',
        'path': 'subjects',
        'scope': 'subjects.list'
      }
    ]
  },
  {
    '_tag': 'CSidebarNavDropdown',
    'icon': 'cil-money',
    'name': 'Fees',
    'path': 'fee-management',
    '_children': [
      {
        'to': '/fee-management/fee-types/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Fee Types',
        'path': 'fee-types',
        'scope': 'fee_types.list'
      },
      {
        'to': '/fee-management/fee-rates/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Fee Rates',
        'path': 'fee-rates',
        'scope': 'fee_rates.list'
      },
      {
        'to': '/fee-management/fee-lates/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Late Fees',
        'path': 'fee-lates',
        'scope': 'fee_lates.list'
      }
    ]
  },
  {
    '_tag': 'CSidebarNavDropdown',
    'icon': 'cil-user',
    'name': 'User',
    'path': 'user-management',
    '_children': [
      {
        'to': '/user-management/users/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Users',
        'path': 'users',
        'scope': 'users.list'
      },
      {
        'to': '/user-management/roles/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Roles',
        'path': 'roles',
        'scope': 'roles.list'
      },
      {
        'to': '/user-management/permissions/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Permissions',
        'path': 'permissions',
        'scope': 'permissions.list'
      }
    ]
  },
  {
    '_tag': 'CSidebarNavDropdown',
    'icon': 'cib-medium',
    'name': 'Miscellaneous',
    'path': 'misc-management',
    '_children': [
      {
        'to': '/misc-management/religions/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Religion',
        'path': 'religions',
        'scope': 'religions.list'
      },
      {
        'to': '/misc-management/castes/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Castes',
        'path': 'castes',
        'scope': 'castes.list'
      },
      {
        'to': '/misc-management/document-types/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Document Types',
        'path': 'document-types',
        'scope': 'document_types.list'
      },
      {
        'to': '/misc-management/qualifications/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Qualifications',
        'path': 'qualifications',
        'scope': 'qualifications.list'
      },
      {
        'to': '/misc-management/occupations/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Occupations',
        'path': 'occupations',
        'scope': 'occupations.list'
      },
      {
        'to': '/misc-management/income-slabs/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Income Slabs',
        'path': 'income-slabs',
        'scope': 'income_slabs.list'
      },
      {
        'to': '/misc-management/entrance-examination-centres/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Exam Centre',
        'path': 'entrance-examination-centres',
        'scope': 'entrance_examination_centres.list'
      }
    ]
  },
  {
    '_tag': 'CSidebarNavDropdown',
    'icon': 'cil-location-pin',
    'name': 'Location',
    'path': 'location-management',
    '_children': [
      {
        'to': '/location-management/countries/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Country',
        'path': 'countries',
        'scope': 'countries.list'
      },
      {
        'to': '/location-management/states/list',
        '_tag': 'CSidebarNavItem',
        'name': 'State',
        'path': 'states',
        'scope': 'states.list'
      },
      {
        'to': '/location-management/districts/list',
        '_tag': 'CSidebarNavItem',
        'name': 'District',
        'path': 'districts',
        'scope': 'districts.list'
      },
      {
        'to': '/location-management/areas/list',
        '_tag': 'CSidebarNavItem',
        'name': 'Areas',
        'path': 'areas',
        'scope': 'areas.list'
      }
    ]
  }
]

export default itemsListArray

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getFeeSummary, getDailyFee, getSessionDropdown } from './api'
import { getActiveSession, getSessionsForDropdown } from './helper'
import { DropdownItemType, FeeDailyReportResponseType, FeeDailyTransactionResponseType, FeeSummaryResponseType, FilterType, MetaType, SessionResponseType } from './type'

export const ReportSlice = createSlice({
    name: 'report',
    initialState: {
        /* Start Common */
        loading: false as boolean,
        meta: {} as MetaType,
        session: [] as DropdownItemType[],
        filter: {} as FilterType,
        activeSession: {} as SessionResponseType,
        dailyReportFilter: {} as FilterType,
        /* End Common */

        /* Start Fee Report */
        feeSummaryReport: {} as FeeSummaryResponseType,
        feeDailyReport: [] as FeeDailyTransactionResponseType[],
        selectedFeeDailyReportPage: 1 as number
        /* End Fee Report */
    },
    reducers: {
        /* Start Common */
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setMeta: (state, action: PayloadAction<MetaType>) => {
            state.meta = action.payload
        },
        setSessionDropdown: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.session = action.payload
        },
        setFilter: (state, action: PayloadAction<FilterType>) => {
            state.filter = action.payload
        },
        setActiveSession: (state, action: PayloadAction<SessionResponseType[]>) => {
            state.activeSession = getActiveSession(action.payload) as SessionResponseType
        },
        setDailyReportFilterType: (state, action: PayloadAction<FilterType>) => {
            state.dailyReportFilter = action.payload
        },
        /* End Common */

        /* Start Fee Report */
        setFeeSummaryReport: (state, action: PayloadAction<FeeSummaryResponseType>) => {
            state.feeSummaryReport = action.payload
        },
        setFeeDailyReport: (state, action: PayloadAction<FeeDailyTransactionResponseType[]>) => {
            state.feeDailyReport = action.payload
        },
        setDailyReportSelectedPage: (state, action: PayloadAction<number>) => {
            state.selectedFeeDailyReportPage = action.payload
        }
        /* End Fee Report */
    }
})

export const {
    setLoading,
    setMeta,
    setSessionDropdown,
    setActiveSession,
    setFilter,
    setDailyReportFilterType,
    setFeeSummaryReport,
    setFeeDailyReport,
    setDailyReportSelectedPage
} = ReportSlice.actions

/* Start Common */

export const loadSessionDropdownItems = (sort?: string, order?: string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response = await getSessionDropdown(sort, order)
    dispatch(setActiveSession(response.items))
    dispatch(setSessionDropdown(getSessionsForDropdown(response.items)))
    dispatch(setLoading(false))
}

export const loadActiveSessionDropdownItems = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response = await getSessionDropdown('start', 'DESC')
    dispatch(setActiveSession(response.items))
    dispatch(setLoading(false))
}

/* End Common */

/* Start Fee Report */

export const loadFeeSummaryReport = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response = await getFeeSummary(id)
    dispatch(setFeeSummaryReport(response.report))
    dispatch(setLoading(false))
}

export const loadFeeDailyReport = (page: number, records: number, filters: object) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: FeeDailyReportResponseType = await getDailyFee(page, records, filters)
    dispatch(setFeeDailyReport(response.report))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

/* End Fee Report */

/* Start Common */
export const selectLoading = (state: any) => state.report.loading
export const selectStandardDropdown = (state: any) => state.report.standardDropdown
export const selectMeta = (state: any) => state.report.meta
export const selectSessionDropdown = (state: any) => state.report.session
export const selectFilter = (state: any) => state.report.filter
export const selectActiveSession = (state: any) => state.report.activeSession
export const selectDailyReportFilter =  (state: any) => state.report.dailyReportFilter
/* End Common */

/* Start Fee Report */
export const selectFeeSummary = (state: any) => state.report.feeSummaryReport
export const selectFeeDaily = (state: any) => state.report.feeDailyReport
export const selectSelectedDailyReportPage = (state: any) => state.report.selectedFeeDailyReportPage
/* End Fee Report */

export default ReportSlice.reducer
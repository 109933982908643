import { useParams } from 'react-router-dom'
import React, { Suspense } from 'react'
import { ComponentsGuide } from '../features/developer'
import { GlobalLayout, LoaderFour } from '../features/layout'

const DeveloperPage = () => {
    const { type } = useParams() as any
    const { NODE_ENV } = process.env
    return (
        <Suspense fallback={<LoaderFour />}>
            <GlobalLayout>
                {(type === 'components' && NODE_ENV === 'development') && <ComponentsGuide />}
            </GlobalLayout>
        </Suspense>
    )
}

export default DeveloperPage
import { confirmAlert } from 'react-confirm-alert'
import { AuthResponseType, DropdownItemType } from '../type'

export const removeWithConfirmation = (confirmAction: () => any, cancelAction: () => any) => {
    confirmAlert({
        title: 'Confirm Remove',
        message: 'Are you sure you want to remove?',
        closeOnClickOutside: false,
        buttons: [
            {
                label: 'Yes',
                onClick: confirmAction
            },
            {
                label: 'No',
                onClick: cancelAction
            }
        ]
    })
}

export const getDefaultDash = (profile: any, key: string) => {
    if(window.localStorage.getItem('dashboard')){
        return window.localStorage.getItem('dashboard')
    }
    if (profile?.setting?.length) {
        const settingData: any = profile.setting.find((setting: any) => setting.name === key)
        return settingData.value
    }
    if (profile?.sidebar?.length) {
        return profile?.sidebar[0].dashboard
    }
    return null
}

export const getSettings = (profile: AuthResponseType, key: string) => {
    if (profile?.setting?.length) {
        const settingData: any = profile.setting.find((setting: any) => setting.name === key)
        return settingData.value
    }
    return null
}

/* start dropdown label by id */
export const getDropdownLabelById = (items: DropdownItemType[], id: any, placeholder?: string) => {
    if (items !== undefined) {
        const item = items.filter((item: DropdownItemType) => item.id === id)
        if (item.length > 0) {
            return item[0].title
        }
    }
    return placeholder || 'Select here'
}
export const paymodeList = [
    {id:'bank-deposit', title:'Bank Deposit'},
    {id:'bank-transfer', title:'Bank Transfer'},
    {id:'cash', title:'Cash'},
    {id:'cheque', title:'Cheque'},
    {id:'credit-card', title:'Credit Card'},
    {id:'debit-card', title:'Debit Card'},
    {id:'demand-draft', title:'Demand Draft'},
    {id:'link', title:'Payment Link'}
]

export const monthList = [
    {id:4, title:'April'},
    {id:5, title:'May'},
    {id:6, title:'June'},
    {id:7, title:'July'},
    {id:8, title:'August'},
    {id:9, title:'September'},
    {id:10, title:'October'},
    {id:11, title:'November'},
    {id:12, title:'December'},
    {id:1, title:'January'},
    {id:2, title:'February'},
    {id:3, title:'March'},
]

export const balanceTypeList = [
    {id:'arrear', title:'Arrear'},
    {id:'concession', title:'Concession'}
]

export const paymentTypeList = [
    {id:'cash', title:'Cash'},
    {id:'debit-card', title:'Debit Card'},
    {id:'credit-card', title:'Credit Card'},
    {id:'demand-draft', title:'Demand Draft'},
    {id:'cheque', title:'Cheque'},
    {id:'bank-transfer', title:'Bank Transfer'},
    {id:'bank-deposit', title:'Bank Deposite'},
    {id:'link', title:'Payment Link'}
]

export const transactionStatusTypeList = [
    {id: 'processing', title: 'Processing'},
    {id: 'failed', title: 'Failed'},
    {id: 'completed', title: 'Completed'},
    {id: 'cancelled', title: 'Cancelled'}
]

export const transactionNatureTypeList = [
    {id: 'credit', title: 'Credit'},
    {id: 'debit', title: 'Debit'}
]
import { useParams } from 'react-router-dom'
import React, { Suspense } from 'react'
import { GlobalLayout, LoaderFour } from '../features/layout'
import { TeacherList } from '../features/staff'

const StaffPage = () => {
    const { type } = useParams() as any
    return (
        <Suspense fallback={<LoaderFour />}>
            <GlobalLayout>
                {type === 'teachers' && <TeacherList />}
            </GlobalLayout>
        </Suspense>
    )
}

export default StaffPage
import RestApiService from '../../service/RestApiService'

// Religion API start.

export const getReligions = async (page: number, records: number) => {
    const path = `misc/religions?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getReligionDropdownItems = async (sort?: string, order?: string) => {
    const path = `misc/religions/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const changeReligionStatus = async (id: number) => {
    const path = `misc/religions/${id}`
    return await RestApiService.patch(path)
}

export const createNewReligion = async (data: any) => {
    const path = 'misc/religions'
    return await RestApiService.post(path, data)
}

export const getSelectedReligion = async (id: number) => {
    const path = `misc/religions/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedReligion = async (id: number, data: any) => {
    const path = `misc/religions/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedReligion = async (id: number) => {
    const path = `misc/religions/${id}`
    return await RestApiService.destroy(path)
}

// Religion API end.

// Caste API start.

export const getCastes = async (page: number, records: number) => {
    const path = `misc/castes?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getCasteDropdownItems = async (sort?: string, order?: string, religion_id?: number) => {
    const path = `misc/castes/dropdown?sort=${sort}&order=${order}&religion_id=${religion_id}`
    return await RestApiService.get(path)
}

export const changeCasteStatus = async (id: number) => {
    const path = `misc/castes/${id}`
    return await RestApiService.patch(path)
}

export const createNewCaste = async (data: any) => {
    const path = 'misc/castes'
    return await RestApiService.post(path, data)
}

export const getSelectedCaste = async (id: number) => {
    const path = `misc/castes/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedCaste = async (id: number, data: any) => {
    const path = `misc/castes/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedCaste = async (id: number) => {
    const path = `misc/castes/${id}`
    return await RestApiService.destroy(path)
}

// Caste API end.

// Document type API start.

export const getDocumentTypes = async (page: number, records: number) => {
    const path = `misc/document-types?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getDocumentTypeDropdownItems = async (sort?: string, order?: string) => {
    const path = `misc/document-types/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const changeDocumentTypeStatus = async (id: number) => {
    const path = `misc/document-types/${id}`
    return await RestApiService.patch(path)
}

export const createNewDocumentType = async (data: any) => {
    const path = 'misc/document-types'
    return await RestApiService.post(path, data)
}

export const getSelectedDocumentType = async (id: number) => {
    const path = `misc/document-types/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedDocumentType = async (id: number, data: any) => {
    const path = `misc/document-types/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedDocumentType = async (id: number) => {
    const path = `misc/document-types/${id}`
    return await RestApiService.destroy(path)
}

// Document type API end.

// Income slab API start.

export const getIncomeSlabs = async (page: number, records: number) => {
    const path = `misc/income-slabs?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getIncomeSlabDropdownItems = async (sort?: string, order?: string) => {
    const path = `misc/income-slabs/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const changeIncomeSlabStatus = async (id: number) => {
    const path = `misc/income-slabs/${id}`
    return await RestApiService.patch(path)
}

export const createNewIncomeSlab = async (data: any) => {
    const path = 'misc/income-slabs'
    return await RestApiService.post(path, data)
}

export const getSelectedIncomeSlab = async (id: number) => {
    const path = `misc/income-slabs/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedIncomeSlab = async (id: number, data: any) => {
    const path = `misc/income-slabs/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedIncomeSlab = async (id: number) => {
    const path = `misc/income-slabs/${id}`
    return await RestApiService.destroy(path)
}

// Income slab API end.

// Occupation API start.

export const getOccupations = async (page: number, records: number) => {
    const path = `misc/occupations?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getOccupationDropdownItems = async (sort?: string, order?: string) => {
    const path = `misc/occupations/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const changeOccupationStatus = async (id: number) => {
    const path = `misc/occupations/${id}`
    return await RestApiService.patch(path)
}

export const createNewOccupation = async (data: any) => {
    const path = 'misc/occupations'
    return await RestApiService.post(path, data)
}

export const getSelectedOccupation = async (id: number) => {
    const path = `misc/occupations/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedOccupation = async (id: number, data: any) => {
    const path = `misc/occupations/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedOccupation = async (id: number) => {
    const path = `misc/occupations/${id}`
    return await RestApiService.destroy(path)
}

// Occupation API end.

// Qualification API start.

export const getQualifications = async (page: number, records: number) => {
    const path = `misc/qualifications?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getQualificationDropdownItems = async (sort?: string, order?: string) => {
    const path = `misc/qualifications/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const changeQualificationStatus = async (id: number) => {
    const path = `misc/qualifications/${id}`
    return await RestApiService.patch(path)
}

export const createNewQualification = async (data: any) => {
    const path = 'misc/qualifications'
    return await RestApiService.post(path, data)
}

export const getSelectedQualification = async (id: number) => {
    const path = `misc/qualifications/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedQualification = async (id: number, data: any) => {
    const path = `misc/qualifications/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedQualification = async (id: number) => {
    const path = `misc/qualifications/${id}`
    return await RestApiService.destroy(path)
}

// Qualification API end.

// EntranceExaminationCentre API start.

export const getEntranceExaminationCentres = async (page: number, records: number) => {
    const path = `misc/entrance-examination-centres?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const changeEntranceExaminationCentreStatus = async (id: number) => {
    const path = `misc/entrance-examination-centres/${id}`
    return await RestApiService.patch(path)
}

export const createNewEntranceExaminationCentre = async (data: any) => {
    const path = 'misc/entrance-examination-centres'
    return await RestApiService.post(path, data)
}

export const getSelectedEntranceExaminationCentre = async (id: number) => {
    const path = `misc/entrance-examination-centres/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedEntranceExaminationCentre = async (id: number, data: any) => {
    const path = `misc/entrance-examination-centres/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedEntranceExaminationCentre = async (id: number) => {
    const path = `misc/entrance-examination-centres/${id}`
    return await RestApiService.destroy(path)
}

// EntranceExaminationCentre API end.

//Department API start

export const getDepartments = async (page: number, records: number) => {
    const path = `misc/departments?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const changeDepartmentStatus = async (id: number) => {
    const path = `misc/departments/${id}`
    return await RestApiService.patch(path)
}

export const getDepartmentDropdownItems = async (sort?: string, order?: string) => {
    const path = `misc/departments/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const createNewDepartment = async (data: any) => {
    const path = 'misc/departments'
    return await RestApiService.post(path, data)
}

export const getSelectedDepartment = async (id: number) => {
    const path = `misc/departments/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedDepartment = async (id: number, data: any) => {
    const path = `misc/departments/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedDepartment = async (id: number) => {
    const path = `misc/departments/${id}`
    return await RestApiService.destroy(path)
}

//Department API end
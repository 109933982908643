import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import AuthReducer from '../features/auth/store'
import LayoutReducer from '../features/layout/store'
import UserReducer from '../features/user/store'
import MiscReducer from '../features/misc/store'
import LocationReducer from '../features/location/store'
import FeeReducer from '../features/fee/store'
import AcademicReducer from '../features/academic/store'
import AdmissionReducer from '../features/admission/store'
import AccountReducer from '../features/account/store'
import DashboardReducer from '../features/dashboard/store'
import StudentReducer from '../features/student/store'
import EmployeeReducer from '../features/employee/store'
import StaffReducer from '../features/staff/store'
import ExaminationReducer from '../features/examination/store'
import ReportReducer from '../features/reports/store'

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    layout: LayoutReducer,
    user: UserReducer,
    misc: MiscReducer,
    location: LocationReducer,
    fee: FeeReducer,
    academic: AcademicReducer,
    admission: AdmissionReducer,
    account: AccountReducer,
    dashboard: DashboardReducer,
    student: StudentReducer,
    employee: EmployeeReducer,
    staff: StaffReducer,
    examination: ExaminationReducer,
    report: ReportReducer
  },
})

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import RestApiService from '../../service/RestApiService'

// Seat API start.

export const getSeats = async (page: number, records: number, session_id?: number, standard_id?: number, filters?: object) => {
    const path = `academic/seats?page=${page}&records=${records}&session_id=${session_id}&standard_id=${standard_id}`
    return await RestApiService.get(path, filters)
}

export const changeSeatStatus = async (id: number) => {
    const path = `academic/seats/${id}`
    return await RestApiService.patch(path)
}

export const createNewSeat = async (data: any) => {
    const path = 'academic/seats'
    return await RestApiService.post(path, data)
}

export const getSelectedSeat = async (id: number) => {
    const path = `academic/seats/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedSeat = async (id: number, data: any) => {
    const path = `academic/seats/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedSeat = async (id: number) => {
    const path = `academic/seats/${id}`
    return await RestApiService.destroy(path)
}

// Seat API end.

// Section API start.

export const getSections = async (page: number, records: number) => {
    const path = `academic/sections?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getSectionDropdownItems = async (sort?: string, order?: string, sessionId?: number, standardId?: number) => {
    const path = `academic/sections/dropdown?sort=${sort}&order=${order}&session_id=${sessionId}&standard_id=${standardId}`
    return await RestApiService.get(path)
}

export const changeSectionStatus = async (id: number) => {
    const path = `academic/sections/${id}`
    return await RestApiService.patch(path)
}

export const createNewSection = async (data: any) => {
    const path = 'academic/sections'
    return await RestApiService.post(path, data)
}

export const getSelectedSection = async (id: number) => {
    const path = `academic/sections/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedSection = async (id: number, data: any) => {
    const path = `academic/sections/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedSection = async (id: number) => {
    const path = `academic/sections/${id}`
    return await RestApiService.destroy(path)
}

// Section API end.

// Session API start.

export const getSessions = async (page: number, records: number) => {
    const path = `academic/sessions?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getSessionDropdownItems = async (sort?: string, order?: string) => {
    const path = `academic/sessions/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const changeSessionStatus = async (id: number) => {
    const path = `academic/sessions/${id}`
    return await RestApiService.patch(path)
}

export const changeSessionCurrent = async (id: number) => {
    const path = `academic/sessions/${id}/current`
    return await RestApiService.patch(path)
}

export const changeSessionClose = async (id: number) => {
    const path = `academic/sessions/${id}/close`
    return await RestApiService.patch(path)
}

export const createNewSession = async (data: any) => {
    const path = 'academic/sessions'
    return await RestApiService.post(path, data)
}

export const getSelectedSession = async (id: number) => {
    const path = `academic/sessions/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedSession = async (id: number, data: any) => {
    const path = `academic/sessions/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedSession = async (id: number) => {
    const path = `academic/sessions/${id}`
    return await RestApiService.destroy(path)
}

// Session API end.

// Standard API start.

export const getStandards = async (page: number, records: number) => {
    const path = `academic/standards?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getStandardDropdownItems = async (sort?: string, order?: string) => {
    const path = `academic/standards/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const changeStandardStatus = async (id: number) => {
    const path = `academic/standards/${id}`
    return await RestApiService.patch(path)
}

export const createNewStandard = async (data: any) => {
    const path = 'academic/standards'
    return await RestApiService.post(path, data)
}

export const getSelectedStandard = async (id: number) => {
    const path = `academic/standards/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedStandard = async (id: number, data: any) => {
    const path = `academic/standards/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedStandard = async (id: number) => {
    const path = `academic/standards/${id}`
    return await RestApiService.destroy(path)
}

// Standard API end.

// Subject API start.

export const getSubjects = async (page: number, records: number) => {
    const path = `academic/subjects?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getSubjectDropdownItems = async (sort?: string, order?: string) => {
    const path = `academic/subjects/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const changeSubjectStatus = async (id: number) => {
    const path = `academic/subjects/${id}`
    return await RestApiService.patch(path)
}

export const createNewSubject = async (data: any) => {
    const path = 'academic/subjects'
    return await RestApiService.post(path, data)
}

export const getSelectedSubject = async (id: number) => {
    const path = `academic/subjects/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedSubject = async (id: number, data: any) => {
    const path = `academic/subjects/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedSubject = async (id: number) => {
    const path = `academic/subjects/${id}`
    return await RestApiService.destroy(path)
}

// Subject API end.

// Standard subject API start

export const createNewStandardSubject = async (data: any) => {
    const path = 'academic/standard-subjects'
    return await RestApiService.post(path, data)
}

export const patchStandardSubject = async (id: number, data: any) => {
    const path = `academic/standard-subjects/${id}`
    return await RestApiService.put(path, data)
}

export const getStandardSubjectDropdownItems = async (sessionId: number, standardId: number, sort?: string, order?: string) => {
    const path = `academic/standard-subjects/dropdown?sort=${sort}&order=${order}&session_id=${sessionId}&standard_id=${standardId}`
    return await RestApiService.get(path)
}
// Standard subject API end

// Standard subject API start

export const createStandardSections = async (data: any) => {
    const path = 'academic/standard-sections'
    return await RestApiService.post(path, data)
}
// Standard subject API end

//Timetable API Start

export const getTimetables = async (page?: number, records?: number, sort?: string, order?: number, day?: number, slot?: number, session_id?: number, standard_id?: number, section_id?: number, subject_id?: number, employee_id?: number, filter?: object) => {
    const path = `academic/timetables?page=${page}&records=${records}&sort=${sort}&order=${order}&day=${day}&slot=${slot}&session_id=${session_id}&standard_id=${standard_id}&section_id=${section_id}&subject_id=${subject_id}&employee_id=${employee_id}`
    return await RestApiService.get(path, filter)
}

export const getTimetable = async (id: number) => {
    const path = `academic/timetables/${id}`
    return await RestApiService.get(path)
}

export const createTimetable = async (data: any) => {
    const path = 'academic/timetables'
    return await RestApiService.post(path, data)
}

export const updateTimetable = async (id: number | undefined, data: any) => {
    const path = `academic/timetables/${id}`
    return await RestApiService.put(path, data)
}

export const getEmployeeDropdownItems = async (sort?: string, order?: string, departmentId?: number, standardId?: number, subjectId?: number) => {
    const path = `staff/teachers/dropdown?sort=${sort}&order=${order}&department_id=${departmentId}&standard_id=${standardId}&subject_id=${subjectId}`
    return await RestApiService.get(path)
}


//Timetable API End

//House API Start

export const getHouses = async (page: number, record: number, sort?: string, order?: string) => {
    const path = `academic/houses?page=${page}&records=${record}&sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getSelectedHouse = async (id: number) => {
    const path = `academic/houses/${id}`
    return await RestApiService.get(path)
}

export const createHouse = async (data: any) => {
    const path = 'academic/houses'
    return await RestApiService.post(path, data)
}

export const patchSelectedHouse = async (id: number, data: any) => {
    const path = `academic/houses/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedHouse = async (id: number) => {
    const path = `academic/houses/${id}`
    return await RestApiService.destroy(path)
}

export const patchSelectedHouseStatus = async (id: number) => {
    const path = `academic/houses/${id}`
    return await RestApiService.patch(path)
}

export const getHouseDropdownItems = async (sort?: string, order?: string) => {
    const path = `academic/house/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}
//House API End
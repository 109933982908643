import { DropdownItemType } from '../../../asset/inputs/dropdown/type'
import { CasteListCustomType, CasteResponseType, DepartmentListCustomType, DepartmentResponseType, DocumentTypeListCustomType, DocumentTypeResponseType, EntranceExaminationCentreListCustomType, EntranceExaminationCentreResponseType, IncomeSlabListCustomType, IncomeSlabResponseType, MetaType, OccupationListCustomType, OccupationResponseType, QualificationListCustomType, QualificationResponseType, ReligionListCustomType, ReligionResponseType } from '../type'

/* start get total number of pages */
export const getTotalPages = (meta: MetaType|undefined) => {
    return (meta !== undefined) ? Math.ceil(meta.total/meta.per_page)||1:1
}
/* end get total number of pages */

/* start get dropdown items */
export const getDropdownLabelById = (items: DropdownItemType[], id: any, placeholder?: string) => {
    const item = items.filter((item:DropdownItemType)=> item.id === id)
    if(item.length > 0){
        return item[0].title
    }
    return placeholder || 'Select here'
}
/* end get dropdown items */

/* start get religion lists */
export const getReligionsForList = (religions: ReligionResponseType[]) => {
    return religions?.map((religion: ReligionResponseType) => {
        return {
            Id:religion.id,
            Name:religion.name,
            Status:religion.status?'Active':'Not Active'
        } as ReligionListCustomType
    })
}

export const getReligionsForDropdown = (religions: ReligionResponseType[]) => {
    return religions?.map((religion: ReligionResponseType) => {
        return {
            id:religion.id,
            title:religion.name
        } as DropdownItemType
    })
}
/* end get religion lists */

/* start get caste lists */
export const getCastesForList = (castes: CasteResponseType[]) => {
    return castes?.map((caste: CasteResponseType) => {
        return {
            Id:caste.id,
            Name:caste.name,
            Religion:caste.religion.name,
            Status:caste.status?'Active':'Not Active'
        } as CasteListCustomType
    })
}

export const getCastesForDropdown = (castes: CasteResponseType[]) => {
    return castes?.map((caste: CasteResponseType) => {
        return {
            id:caste.id,
            title:caste.name
        } as DropdownItemType
    })
}
/* end get caste lists */

/* start get document type lists */
export const getDocumentTypesForList = (documentTypes: DocumentTypeResponseType[]) => {
    return documentTypes?.map((documentType: DocumentTypeResponseType) => {
        return {
            Id:documentType.id,
            Name:documentType.name,
            Attributes: getAttributes(documentType.attributes),
            Status:documentType.status?'Active':'Not Active'
        } as DocumentTypeListCustomType
    })
}

export const getDocumentTypesForDropdown = (documentTypes: DocumentTypeResponseType[]) => {
    return documentTypes?.map((documentType: DocumentTypeResponseType) => {
        return {
            id:documentType.id,
            title:documentType.name
        } as DropdownItemType
    })
}
/* end get document type lists */

/* start get income slab lists */
export const getIncomeSlabsForList = (incomeSlabs: IncomeSlabResponseType[]) => {
    return incomeSlabs?.map((incomeSlab: IncomeSlabResponseType) => {
        return {
            Id:incomeSlab.id,
            Slab: `${incomeSlab.minimum} - ${incomeSlab.maximum}`,
            Status:incomeSlab.status?'Active':'Not Active'
        } as IncomeSlabListCustomType
    })
}
/* end get income slab lists */

/* start get occupation lists */
export const getOccupationsForList = (occupations: OccupationResponseType[]) => {
    return occupations?.map((occupation: OccupationResponseType) => {
        return {
            Id:occupation.id,
            Name:occupation.name,
            Status:occupation.status?'Active':'Not Active'
        } as OccupationListCustomType
    })
}
/* end get occupation lists */

/* start get qualification lists */
export const getQualificationsForList = (occupations: QualificationResponseType[]) => {
    return occupations?.map((occupation: QualificationResponseType) => {
        return {
            Id:occupation.id,
            Name:occupation.name,
            Status:occupation.status?'Active':'Not Active'
        } as QualificationListCustomType
    })
}
/* end get qualification lists */

/* start get entranceExaminationCentre lists */
export const getEntranceExaminationCentresForList = (entranceExaminationCentres: EntranceExaminationCentreResponseType[]) => {
    return entranceExaminationCentres?.map((entranceExaminationCentre: EntranceExaminationCentreResponseType) => {
        return {
            Id:entranceExaminationCentre.id,
            Name:entranceExaminationCentre.name,
            City:entranceExaminationCentre.city,
            Status:entranceExaminationCentre.status?'Active':'Not Active'
        } as EntranceExaminationCentreListCustomType
    })
}
/* end get entranceExaminationCentre lists */

/* start set attribute split */
export const setAttributes = (name: string) => {
    if(name){
        const nameArray = name.split(',')
        return JSON.stringify(nameArray)
    }
    return null
}
/* end attribute split */

/* start get attribute split */
export const getAttributes = (name: string) => {
    if(name){
        const nameArr = JSON.parse(name)
        return nameArr.join(',')
    }
    return null
}
/* start attribute split */

/* start get department lists */
export const getDepartmentsForList = (departments: DepartmentResponseType[]) => {
    return departments?.map((department: DepartmentResponseType) => {
        return {
            Id: department.id,
            Name: department.name,
            Description: department.description || '--',
            Status: department.status ? 'Active' : 'Not Active'
        } as DepartmentListCustomType
    })
}

export const getDepartmentsForDropdown = (departments: DepartmentResponseType[]) => {
    return departments?.map((department: DepartmentResponseType) => {
        return {
            id:department.id,
            title:department.name,
        } as DropdownItemType
    })
}
/* start get department lists */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getDocumentTypeDropdown, getEmployee, getEmployees } from './api'
import { getDocumentTypeForDropdown } from './helper'
import { EmployeeListResponseType, EmployeeResponseType, MetaType, DropdownItemType } from './type'

export const EmployeeSlice = createSlice({
    name: 'employee',
    initialState: {
        /* Start common */
        loading: false as boolean,
        meta: {} as MetaType,
        documentType: [] as DropdownItemType[],
        /* End common */

        /* Start Employee */
        selectedEmployeePage: 1 as number,
        employees: [] as EmployeeResponseType[],
        employee: {} as EmployeeResponseType
        /* End Employee */
    },
    reducers: {
        /* Start common */
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setMeta: (state, action: PayloadAction<MetaType>) => {
            state.meta = action.payload
        },
        setDocumentType: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.documentType = action.payload
        },
        /* End common */

        /* Start Employee */
        setSelectedEmployeePage: (state, action: PayloadAction<number>) => {
            state.selectedEmployeePage = action.payload
        },
        setEmployees: (state, action: PayloadAction<EmployeeResponseType[]>) => {
            state.employees = action.payload
        },
        setEmployee: (state, action: PayloadAction<EmployeeResponseType>) => {
            state.employee = action.payload
        }
        /* End Employee */
    }
})

export const {
    /* Start common */
    setLoading,
    setMeta,
    setDocumentType,
    /* End common */

    /* Start Employee */
    setSelectedEmployeePage,
    setEmployees,
    setEmployee
    /* End Employee */
} = EmployeeSlice.actions

/* Start common */
export const loadDocumentTypes = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const documentTypeResponse = await getDocumentTypeDropdown()
    dispatch(setDocumentType(getDocumentTypeForDropdown(documentTypeResponse.items)))
    dispatch(setLoading(false))
}
/* End Common */

/* Start Employee */
export const loadEmployees = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: EmployeeListResponseType = await getEmployees(page, records)
    dispatch(setEmployees(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadEmployee = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: EmployeeResponseType = await getEmployee(id)
    dispatch(setEmployee(response))
    dispatch(setLoading(false))
}
/* End Employee */


/* Start common */
export const selectLoading = (state: any) => state.employee.loading
export const selectMeta = (state: any) => state.employee.meta
export const selectDocumentType = (state: any) => state.employee.documentType
/* End common */

/* Start Employee */
export const selectSelectedEmployeePage = (state: any) => state.employee.selectedEmployeePage
export const selectEmployees = (state: any) => state.employee.employees
export const selectEmployee = (state: any) => state.employee.employee
/* End Employee */

export default EmployeeSlice.reducer
import React from 'react'

const StudentList = React.lazy(() => import('./students/list'))
const StudentEdit = React.lazy(() => import('./students/edit'))
const StudentPersonalTab = React.lazy(() => import('./students/components/profile'))
const StudentDocumentTab = React.lazy(() => import('./students/components/document'))
const StudentFamilyTab = React.lazy(() => import('./students/components/family'))
const StudentMedicalProfileTab = React.lazy(() => import('./students/components/medical'))
//Student View Start
const StudentView = React.lazy(() => import('./students/show'))
const StudentFamilyView = React.lazy(() => import('./students/components/familyCard'))
const StudentMedicalProfileView = React.lazy(() => import('./students/components/healthCard'))
//Student View End
//Student Option Modal Start
const StudentStateChange = React.lazy(() => import('./students/modals/changeState'))
const StudentFilters = React.lazy(() => import('./students/modals/filter'))
//Student Option Modal End

//Settlements Start
const SettlementList = React.lazy(() => import('./settlements/list'))
//Settlements End

//Transfer Certificate Start
const StudentTransferCertificateList = React.lazy(() => import('./transferCertificates/list'))
const StudentTransferCertificateGenerate = React.lazy(() => import('./transferCertificates/modals/create'))
const StudentTransferCertificatePrint = React.lazy(() => import('./transferCertificates/modals/print'))
const StudentTransferCertificateEdit = React.lazy(() => import('./transferCertificates/modals/edit'))
//Transfer Certificate End

//Attendance Start
const StudentAttendanceList = React.lazy(() => import('./attendance/index'))
const StudentAttendanceFilter = React.lazy(() => import('./attendance/modal/filter'))
const StudentCreateAttendance = React.lazy(() => import('./attendance/modal/addAttendance'))
//Attendance Start

//Academic Start
const StudentAcademicsList = React.lazy(() => import('./academic/list'))
const StudentAcademicsFilter = React.lazy(()=>import('./academic/modals/filter'))
const StudentAcademicsListOption = React.lazy(()=>import('./academic/modals/listOptionModal'))
const StudentAcademicBulkSectionAssign = React.lazy(()=>import('./academic/modals/bulkSectionAssign'))
//Academic End

export {
    StudentList,
    StudentEdit,
    StudentPersonalTab,
    StudentDocumentTab,
    StudentFamilyTab,
    StudentMedicalProfileTab,
    StudentView,
    StudentFamilyView,
    StudentMedicalProfileView,
    StudentStateChange,
    SettlementList,
    StudentTransferCertificateList,
    StudentTransferCertificateGenerate,
    StudentTransferCertificatePrint,
    StudentTransferCertificateEdit,
    StudentFilters,
    StudentAttendanceList,
    StudentAttendanceFilter,
    StudentCreateAttendance,
    StudentAcademicsList,
    StudentAcademicsFilter,
    StudentAcademicsListOption,
    StudentAcademicBulkSectionAssign
}
import RestApiService from '../../service/RestApiService'

// Common API start
export const getSessionDropdown = async (sort?: string, order?: string) => {
    const path = `academic/sessions/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}
export const uploadImage = async (data: any) => {
    const path = 'common/upload/image'
    return await RestApiService.upload(path, data)
}

export const uploadFile = async (data: any) => {
    const path = 'common/upload/file'
    return await RestApiService.upload(path, data)
}

export const getReligionDropdown = async (sort?: string, order?: string) => {
    const path = `misc/religions/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getCasteDropdown = async (sort?: string, order?: string, religion_id?: number) => {
    const path = `misc/castes/dropdown?sort=${sort}&order=${order}&religion_id=${religion_id}`
    return await RestApiService.get(path)
}

export const getAreaDropdown = async (sort?: string, order?: string) => {
    const path = `location/areas/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getQualificationDropdown = async (sort?: string, order?: string) => {
    const path = `misc/qualifications/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getOccupationDropdown = async (sort?: string, order?: string) => {
    const path = `misc/occupations/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getIncomeSlabDropdown = async (sort?: string, order?: string) => {
    const path = `misc/income-slabs/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getDocumentTypeDropdown = async (sort?: string, order?: string) => {
    const path = `misc/document-types/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getStandardDropdown = async (sort?: string, order?: string) => {
    const path = `academic/standards/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getSectionDropdown = async (sort?: string, order?: string, sessionId?: number, standardId?: number) => {
    const path = `academic/sections/dropdown?sort=${sort}&order=${order}&session_id=${sessionId}&standard_id=${standardId}`
    return await RestApiService.get(path)
}

export const getHouseDropdown = async (sort?: string, order?: string) => {
    const path = `academic/houses/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getSubjectDropdown = async (sort?: string, order?: string) => {
    const path = `academic/subjects/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}
// Common API end

// Student API start.

export const getStudents = async (page: number, records: number, filters?: object) => {
    const path = `student/students?page=${page}&records=${records}`
    return await RestApiService.get(path, filters)
}

export const getStudent = async (id: number) => {
    const path = `student/students/${id}`
    return await RestApiService.get(path)
}

export const updateStudent = async (id: number | undefined, data: any) => {
    const path = `student/students/${id}`
    return await RestApiService.put(path, data)
}

export const updateParents = async (id: number, data: any) => {
    const path = `student/students/${id}/parent-profile`
    return await RestApiService.put(path, data)
}

export const updateSiblings = async (id: number, data: any) => {
    const path = `student/students/${id}/sibling-profile`
    return await RestApiService.put(path, data)
}

export const uploadStudentFiles = async (data: any) => {
    const path = 'common/upload/file'
    return await RestApiService.upload(path, data)
}

export const createDocument = async (id: number, data: any) => {
    const path = `student/students/${id}/documents`
    return await RestApiService.put(path, data)
}

export const deleteStudentDocument = async (id: number, studentId: number) => {
    const path = `student/students/${studentId}/documents/${id}`
    return await RestApiService.destroy(path)
}

export const updateMedicalProfile = async (id: number, data: any) => {
    const path = `student/students/${id}/medical-profile`
    return await RestApiService.put(path, data)
}

export const setStudentStatus = async (id: number, data: any) => {
    const path = `student/students/${id}/change-state`
    return await RestApiService.put(path, data)
}
//Student API End

//Settlement API Start
export const getSettlements = async (page: number, records: number) => {
    const path = `student/student-settlements?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getSettlement = async (id: number) => {
    const path = `student/student-settlements/${id}`
    return await RestApiService.get(path)
}
//Settlement API End

//Transfer Certificate API Start
export const getTransferCertificates = async (page: number, records: number) => {
    const path = `student/student-transfer-certificates?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getTransferCertificate = async (id: number) => {
    const path = `student/student-transfer-certificates/${id}`
    return await RestApiService.get(path)
}

export const generateTC = async (id: number, data: any) => {
    const path = `student/student-transfer-certificates/${id}`
    return await RestApiService.put(path, data)
}

export const tcCount = async (id: number) => {
    const path = `student/student-transfer-certificates/${id}`
    return await RestApiService.patch(path)
}
//Transfer Certificate API End

//Attendance API Start
export const getStudentAttendances = async (page?: number, records?: number, sort?: string, order?: string, sessionId?: number, filter?: object) => {
    const path = `student/student-attendances?page=${page}&records=${records}&sort=${sort}&order=${order}&session_id=${sessionId}`
    return await RestApiService.get(path, filter)
}

export const getStudentAttendance = async (id: string) => {
    const path = `student/student-attendances/${id}`
    return await RestApiService.get(path)
}

export const createAttendance = async (data: any) => {
    const path = 'student/student-attendances'
    return await RestApiService.post(path, data)
}
//Attendance API End

//Student Academic API Start
export const getStudentAcademicDropdownList = async (sessionId: number, sort?: string, order?: string, filter?: object) => {
    const path = `student/student-academics/dropdown?sort=${sort}&order=${order}&session_id=${sessionId}`
    return await RestApiService.get(path, filter)
}

export const getAcademics = async (page?: number, records?: number, sort?: string, order?: string, filter?: object) => {
    const path = `student/student-academics?page=${page}&records=${records}&sort=${sort}&order=${order}`
    return await RestApiService.get(path, filter)
}

export const getSelectedAcademic = async (id: number) => {
    const path = `student/student-academics/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedAcademic = async (id: number, data: any) => {
    const path = `student/student-academics/${id}`
    return await RestApiService.put(path, data)
}

export const generateRollNumber = async (id: number) => {
    const path = `student/student-academics/${id}/generate`
    return await RestApiService.patch(path)
}

export const getStandardSubject = async (id: number) => {
    const path = `academic/standards/${id}`
    return await RestApiService.get(path)
}

export const allotBulkSection = async (data: any) => {
    const path = 'student/student-academics/section-allotment'
    return await RestApiService.post(path, data)
}
//Student Academic API End
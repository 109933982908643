import React from 'react'

const ReligionList = React.lazy(()=>import('./religion/list'))
const ReligionCreate = React.lazy(()=>import('./religion/modals/createReligion'))
const ReligionEdit = React.lazy(()=>import('./religion/modals/editReligion'))
const ReligionOptionBox = React.lazy(()=>import('./religion/modals/options'))
const CasteList = React.lazy(()=>import('./caste/list'))
const CasteCreate = React.lazy(()=>import('./caste/modals/createCaste'))
const CasteEdit = React.lazy(()=>import('./caste/modals/editCaste'))
const CasteOptionBox = React.lazy(()=>import('./caste/modals/options'))
const DocumentTypeList = React.lazy(()=>import('./document_type/list'))
const DocumentTypeCreate = React.lazy(()=>import('./document_type/modals/createDocumentType'))
const DocumentTypeEdit = React.lazy(()=>import('./document_type/modals/editDocumentType'))
const DocumentTypeOptionBox = React.lazy(()=>import('./document_type/modals/options'))
const IncomeSlabList = React.lazy(()=>import('./income_slab/list'))
const IncomeSlabCreate = React.lazy(()=>import('./income_slab/modals/createIncomeSlab'))
const IncomeSlabEdit = React.lazy(()=>import('./income_slab/modals/editIncomeSlab'))
const IncomeSlabOptionBox = React.lazy(()=>import('./income_slab/modals/options'))
const OccupationList = React.lazy(()=>import('./occupation/list'))
const OccupationCreate = React.lazy(()=>import('./occupation/modals/createOccupation'))
const OccupationEdit = React.lazy(()=>import('./occupation/modals/editOccupation'))
const OccupationOptionBox = React.lazy(()=>import('./occupation/modals/options'))
const QualificationList = React.lazy(()=>import('./qualification/list'))
const QualificationCreate = React.lazy(()=>import('./qualification/modals/createQualification'))
const QualificationEdit = React.lazy(()=>import('./qualification/modals/editQualification'))
const QualificationOptionBox = React.lazy(()=>import('./qualification/modals/options'))
const EntranceExaminationCentreList = React.lazy(()=>import('./entrance_examination_centre/list'))
const EntranceExaminationCentreCreate = React.lazy(()=>import('./entrance_examination_centre/modals/createEntranceExaminationCentre'))
const EntranceExaminationCentreEdit = React.lazy(()=>import('./entrance_examination_centre/modals/editEntranceExaminationCentre'))
const EntranceExaminationCentreOptionBox = React.lazy(()=>import('./entrance_examination_centre/modals/options'))
const DepartmentList = React.lazy(()=>import('./department/list'))
const DepartmentCreate = React.lazy(()=>import('./department/modals/createDepartment'))
const DepartmentEdit = React.lazy(()=>import('./department/modals/editDepartment'))
const DepartmentOptionBox = React.lazy(()=>import('./department/modals/options'))

export {
    /* Religion start. */
    ReligionList,
    ReligionCreate,
    ReligionEdit,
    ReligionOptionBox,
    /* Religion end. */

    /* caste start. */
    CasteList,
    CasteCreate,
    CasteEdit,
    CasteOptionBox,
    /* caste end. */

    /* document type start. */
    DocumentTypeList,
    DocumentTypeCreate,
    DocumentTypeEdit,
    DocumentTypeOptionBox,
    /* document type end. */

    /* income slab start. */
    IncomeSlabList,
    IncomeSlabCreate,
    IncomeSlabEdit,
    IncomeSlabOptionBox,
    /* income slab end. */

    /* occupation start. */
    OccupationList,
    OccupationCreate,
    OccupationEdit,
    OccupationOptionBox,
    /* occupation end. */

    /* qualification start. */
    QualificationList,
    QualificationCreate,
    QualificationEdit,
    QualificationOptionBox,
    /* qualification end. */

    /* entranceExaminationCentre start. */
    EntranceExaminationCentreList,
    EntranceExaminationCentreCreate,
    EntranceExaminationCentreEdit,
    EntranceExaminationCentreOptionBox,
    /* entranceExaminationCentre end. */

    /* department start. */
    DepartmentList,
    DepartmentCreate,
    DepartmentEdit,
    DepartmentOptionBox
    /* department end. */
}
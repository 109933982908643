import React, { Suspense, useState } from 'react'
import { AdminBoard, DeveloperBoard } from '../features/dashboard'
import { GlobalLayout, LoaderFour } from '../features/layout'

const DashboardPage = () => {
    const [board, setBoard] = useState('default')
    return (
        <Suspense fallback={<LoaderFour/>}>
            <GlobalLayout switcher={setBoard}>
                {board === 'developer' && <DeveloperBoard/>}
                {board === 'admin' && <AdminBoard/>}
            </GlobalLayout>
        </Suspense>
    )
}

export default DashboardPage
import RestApiService from '../../service/RestApiService'

// Country API start.

export const getCountries = async (page: number, records: number) => {
    const path = `location/countries?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getCountryDropdownItems = async (sort?: string, order?: string) => {
    const path = `location/countries/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const changeCountryStatus = async (id:number) => {
    const path = `location/countries/${id}`
    return await RestApiService.patch(path)
}

export const createNewCountry = async (data: any) => {
    const path = 'location/countries'
    return await RestApiService.post(path, data)
}

export const getSelectedCountry = async (id: number) => {
    const path = `location/countries/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedCountry = async (id: number, data: any) => {
    const path = `location/countries/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedCountry = async (id: number) => {
    const path = `location/countries/${id}`
    return await RestApiService.destroy(path)
}

// Country API end.

// State API start.

export const getStates = async (page: number, records: number) => {
    const path = `location/states?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getStateDropdownItems = async (sort?: string, order?: string, country_id?: number) => {
    const path = `location/states/dropdown?sort=${sort}&order=${order}&country_id=${country_id}`
    return await RestApiService.get(path)
}

export const changeStateStatus = async (id:number) => {
    const path = `location/states/${id}`
    return await RestApiService.patch(path)
}

export const createNewState = async (data: any) => {
    const path = 'location/states'
    return await RestApiService.post(path, data)
}

export const getSelectedState = async (id: number) => {
    const path = `location/states/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedState = async (id: number, data: any) => {
    const path = `location/states/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedState = async (id: number) => {
    const path = `location/states/${id}`
    return await RestApiService.destroy(path)
}

// State API end.

// District API start.

export const getDistricts = async (page: number, records: number) => {
    const path = `location/districts?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getDistrictDropdownItems = async (sort?: string, order?: string, country_id?: number|string, state_id?: number) => {
    const path = `location/districts/dropdown?sort=${sort}&order=${order}&country_id=${country_id}&state_id=${state_id}`
    return await RestApiService.get(path)
}

export const changeDistrictStatus = async (id:number) => {
    const path = `location/districts/${id}`
    return await RestApiService.patch(path)
}

export const createNewDistrict = async (data: any) => {
    const path = 'location/districts'
    return await RestApiService.post(path, data)
}

export const getSelectedDistrict = async (id: number) => {
    const path = `location/districts/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedDistrict = async (id: number, data: any) => {
    const path = `location/districts/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedDistrict = async (id: number) => {
    const path = `location/districts/${id}`
    return await RestApiService.destroy(path)
}

// District API end.

// Area API start.

export const getAreas = async (page: number, records: number) => {
    const path = `location/areas?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getAreaDropdownItems = async (sort?: string, order?: string, country_id?: number, state_id?: number, district_id?: number) => {
    const path = `location/areas/dropdown?sort=${sort}&order=${order}&country_id=${country_id}&state_id=${state_id}&district_id=${district_id}`
    return await RestApiService.get(path)
}

export const changeAreaStatus = async (id:number) => {
    const path = `location/areas/${id}`
    return await RestApiService.patch(path)
}

export const createNewArea = async (data: any) => {
    const path = 'location/areas'
    return await RestApiService.post(path, data)
}

export const getSelectedArea = async (id: number) => {
    const path = `location/areas/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedArea = async (id: number, data: any) => {
    const path = `location/areas/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedArea = async (id: number) => {
    const path = `location/areas/${id}`
    return await RestApiService.destroy(path)
}

// Area API end.
import dateFormat from 'dateformat'
import { confirmAlert } from 'react-confirm-alert'
import { DropdownItemType, StudentParentResponseType, StudentResponseCustomType, StudentResponseType, ReligionResponseType, CasteResponseType, AreaResponseType, IncomeSlabResponseType, OccupationResponseType, QualificationResponseType, DocumentTypeResponseType, DocumentTypeDropdownItemType, MetaType, StudentSettlementsResponseType, StudentSettlementsCustomResponseType, StudentTransferCertificateResponseType, StudentTransferCertificateCustomResponseType, StudentAttendanceResponseType, StudentAttendanceCustomResponseType, SessionResponseType, StudentSectionResponseType, StudentStandardResponseType, StudentAcademicListItem, StudentAcademicCustomResponseType, HouseResponseType, SubjectResponseType } from '../type'
import { dateInWords } from './dateInWords'

/* start get total number of pages */
export const getTotalPages = (meta: MetaType | undefined) => {
    return (meta !== undefined) ? Math.ceil(meta.total / meta.per_page) || 1 : 1
}
/* end get total number of pages */

export const getFullName = (firstname?: string, middlename?: string | null, lastname?: string | null) => {
    return `${firstname} ${middlename || ''} ${lastname || ''}`
}

/* start get dropdown items */
export const getDropdownLabelById = (items: DropdownItemType[], id: any, placeholder?: string) => {
    const item = items.filter((item: DropdownItemType) => item.id === id)
    if (item.length > 0) {
        return item[0].title
    }
    return placeholder || 'Select here'
}
/* end get dropdown items */

/* start get student lists */
export const getStudentsForList = (students: StudentResponseType[]) => {
    return students?.map((student: StudentResponseType) => {
        return {
            Id: student.id,
            Admission: student.admission_number,
            Student: `${student.firstname} ${student.middlename || ''} ${student.lastname || ''}`,
            Father: student?.parents.find((parent: StudentParentResponseType) => parent.relation === 'father')?.name || '',
            Gender: student.gender.toUpperCase(),
            Status: student.student_status.toUpperCase()
        } as unknown as StudentResponseCustomType
    })
}
/* end get student lists */

/* Start Dropdown List */

export const getReligionsForDropdown = (religions: ReligionResponseType[]) => {
    return religions?.map((religion: ReligionResponseType) => {
        return {
            id: religion.id,
            title: religion.name
        } as DropdownItemType
    })
}

export const getCastesForDropdown = (castes: CasteResponseType[]) => {
    return castes?.map((caste: CasteResponseType) => {
        return {
            id: caste.id,
            title: caste.name
        } as DropdownItemType
    })
}

export const getAreaForDropdown = (areas: AreaResponseType[]) => {
    return areas?.map((area: AreaResponseType) => {
        return {
            id: area.id,
            title: `${area.name}, ${area.district.name}, ${area.state.name}, ${area.country.name}`
        } as DropdownItemType
    })
}

export const getQualifcationsForDropdown = (qualifications: QualificationResponseType[]) => {
    return qualifications?.map((qualification: QualificationResponseType) => {
        return {
            id: qualification.id,
            title: qualification.name
        } as DropdownItemType
    })
}

export const getOccupationForDropdown = (occupations: OccupationResponseType[]) => {
    return occupations?.map((occupation: OccupationResponseType) => {
        return {
            id: occupation.id,
            title: occupation.name
        } as DropdownItemType
    })
}

export const getIncomeSlabForDropdown = (incomes: IncomeSlabResponseType[]) => {
    return incomes?.map((income: IncomeSlabResponseType) => {
        return {
            id: income.id,
            title: `${income.minimum}-${income.maximum}`
        } as DropdownItemType
    })
}

export const getDocumentTypeForDropdown = (documentTypes: DocumentTypeResponseType[]) => {
    return documentTypes?.map((documentType: DocumentTypeResponseType) => {
        return {
            id: documentType.id,
            title: documentType.name,
            attributes: documentType.attributes
        } as DocumentTypeDropdownItemType
    })
}

export const getStandardsForDropdown = (standards: StudentStandardResponseType[]) => {
    return standards?.map((standard: StudentStandardResponseType) => {
        return {
            id: standard.id,
            title: standard.name
        } as DropdownItemType
    })
}

export const getSectionsForDropdown = (sections: StudentSectionResponseType[]) => {
    return sections?.map((section: StudentSectionResponseType) => {
        return {
            id: section.id,
            title: section.name
        } as DropdownItemType
    })
}

export const getSlotsForDropdown = (slots: DropdownItemType[], periods?: number) => {
    return slots?.slice(0, periods)?.map((slot) => {
        return {
            id: slot.id,
            title: slot.title
        } as DropdownItemType
    })
}

export const getStudentDropdownItems = (students: StudentAcademicListItem[]) => {
    return students?.map((student: StudentAcademicListItem) => {
        return {
            id: student.id,
            title: getFullName(student.student.firstname, student.student.middlename, student.student.lastname)
        } as DropdownItemType
    })
}

export const getSessionForDropdown = (sessions: SessionResponseType[]) => {
    return sessions?.map((session: SessionResponseType) => {
        return {
            id: session.id,
            title: session.name
        } as DropdownItemType
    })
}

export const getHouseForDropdown = (houses: HouseResponseType[]) => {
    return houses?.map((house: HouseResponseType) => {
        return {
            id: house.id,
            title: house.name
        } as DropdownItemType
    })
}

export const getSubjectForDropdown = (subjects: SubjectResponseType[]) => {
    return subjects?.map((subject: SubjectResponseType) => {
        return {
            id: subject.id,
            title: subject.name
        } as DropdownItemType
    })
}

/* End Dropdown List */

/* Student Status Confirmation Alert Start */
export const settlementConfirmationAlert = (acceptAction: () => any, denyAction: () => any) => {
    confirmAlert({
        title: 'Confirmation Final Settlement',
        message: 'Are you sure you want to move student to final settlement, this is irreversible process option',
        closeOnClickOutside: false,
        buttons: [
            {
                label: 'Accept',
                onClick: acceptAction
            },
            {
                label: 'Deny',
                onClick: denyAction
            }
        ]
    })
}
/* Student Status Confirmation Alert End */

/* Start get settlements list */
export const getSettlementsForList = (settlements: StudentSettlementsResponseType[]) => {
    return settlements?.map((settlement: StudentSettlementsResponseType) => {
        return {
            Id: settlement.id,
            Admission: settlement.student?.admission_number,
            Name: `${settlement.student?.firstname} ${settlement.student?.middlename || ''} ${settlement.student?.lastname || ''}`,
            DOB: dateFormat(settlement.student?.birth, 'dS mmm, yyyy'),
            Applied_On: dateFormat(settlement.created_at, 'dS mmm, yyyy'),
            Status: settlement.student?.student_status.toUpperCase()
        } as StudentSettlementsCustomResponseType
    })
}
/* End get settlements list */

/* Start get student trasfer certificate list */
export const getTransferCertificateForList = (transfers: StudentTransferCertificateResponseType[]) => {
    return transfers?.map((transfer: StudentTransferCertificateResponseType) => {
        return {
            id: transfer.id,
            Admission: transfer.student?.admission_number,
            Name: `${transfer.student?.firstname} ${transfer.student?.middlename || ''} ${transfer.student?.lastname || ''}`,
            DOB: dateFormat(transfer.student?.birth, 'dS mmm, yyyy'),
            Status: transfer.student?.student_status.toUpperCase()
        } as StudentTransferCertificateCustomResponseType
    })
}
/* End get student trasfer certificate list */

/* Start get student attendances */
export const getAttendancesForList = (attendances: StudentAttendanceResponseType[], daysList: DropdownItemType[], slotList: DropdownItemType[]) => {
    return [...attendances]?.sort((a, b) => a.slot - b.slot)?.map((attendance: StudentAttendanceResponseType, index: number) => {
        return {
            Id: attendance.id,
            Standard: `${attendance.standard?.name} ${attendance.section?.name}`,
            Day: getDropdownLabelById(daysList, attendance.day, ''),
            Period: getDropdownLabelById(slotList, attendance.slot, ''),
            Present: attendance.present?.length,
            Absent: attendance.absent?.length,
            Date: dateFormat(attendance.attendance_date, 'dd-mm-yyyy'),
            presentData: attendance.present,
            absentData: attendance.absent,
            Attendant: getFullName(attendance.attendant?.firstname, attendance.attendant?.middlename, attendance.attendant?.lastname)
        } as StudentAttendanceCustomResponseType
    })
}
/* End get student attendances */

/* Start get student academics */
export const getStudentAcademicsForList = (academics: StudentAcademicListItem[], schoolPosition: DropdownItemType[], housePosition: DropdownItemType[], standardPosition: DropdownItemType[]) => {
    return academics?.map((academic: StudentAcademicListItem) => {
        return {
            Id: academic.id,
            Roll_Number: academic.standard_roll_number || '--',
            Student_Id: academic.student_id,
            Name: getFullName(academic.student?.firstname, academic.student?.middlename, academic.student?.lastname),
            Standard: `${academic.standard?.name} ${academic.section?.name || ''}`,
            Standard_Position: getDropdownLabelById(standardPosition, academic.standard_position, '--') || '--',
            School_Position: getDropdownLabelById(schoolPosition, academic.school_position, '--') || '--',
            House_Position: getDropdownLabelById(housePosition, academic.house_position, '--') || '--',
            House: academic?.house?.name || '--',
            Standard_Id: academic.standard_id
        } as StudentAcademicCustomResponseType
    })
}
/* End get student academics */

/* Start get active session */
export const getActiveSession = (sessions: SessionResponseType[]) => {
    if (sessions.length > 0) {
        return sessions.find((session: SessionResponseType) => session.is_current === true)
    }
    return {} as SessionResponseType
}

export const getInt = (num: string | number) => {
    return (typeof num === 'string') ? parseInt(num) : num
}

export const getNonExistingSlots = (existing: StudentAttendanceResponseType[], slotList: DropdownItemType[], date: string, sectionId: number, standardId?: number) => {
    if (existing?.length !== 0) {
        const slotExist = existing?.filter((existingSlot) => date === existingSlot.attendance_date.toString() && standardId === existingSlot.standard_id && sectionId === existingSlot.section_id).map((item) => item.slot)
        return slotList.filter(slot => !slotExist.includes(getInt(slot.id)))
    }
    return slotList

}
/* End get active session */

export {
    dateInWords
}

/* Complete percentage start */
export const getCompletePercentage = (academic: StudentAcademicListItem) => {
    const count = Object.keys(academic).length
    const filled = Object.values(academic).filter(item => item !== null).length
    const value = (filled / count) * 100
    return {
        value,
        color: (value > 0 && value < 40) ? 'danger' : ((value > 40 && value < 80) ? 'warning' : 'success')
    }
}
/* Complete percentage end */

/* Start get student academic for section allotment */

export const getStudentForAllotment = (academics: StudentAcademicListItem[]) => {
    return [...academics]?.filter((data) => data.section_id === null)?.sort((a, b) => a.student?.firstname.localeCompare(b.student?.firstname))?.map((academic: StudentAcademicListItem, index: number) => {
        return {
            Id: academic.id,
            Name: getFullName(academic.student?.firstname, academic.student?.middlename, academic.student?.lastname),
            Standard: `${academic.standard?.name} ${academic.section?.name || ''}`,
            Student_Id: academic?.student_id,
            '#': index + 1
        } as StudentAcademicCustomResponseType
    })
}

/* End get student academic for section allotment */

export const getRangeByIndex = (value: string) => {

    const fullStringPatter = /(\d-\d(,\d)*)/g
    const rangeString = /\d-\d/g

    const arrayData: number[] = []
    const stringValue = value.split(/[-,]/)
    const lowerValue = stringValue[0]
    const highestValue = stringValue[1]
    const otherValues = (value.match(fullStringPatter) || value.match(rangeString)) ? stringValue?.slice(2)?.map(ids => getInt(ids)) : stringValue?.map((ids) => getInt(ids))
    if (value.match(fullStringPatter) || value.match(rangeString)) {
        for (let i = getInt(lowerValue); i <= getInt(highestValue); i++) {
            arrayData.push(i)
        }
    }
    const finalArray = arrayData?.concat(otherValues)
    return finalArray
}
import React from 'react'

const CountryList = React.lazy(()=>import('./countries/list'))
const CountryCreate = React.lazy(()=>import('./countries/modals/createCountry'))
const CountryEdit = React.lazy(()=>import('./countries/modals/editCountry'))
const CountryOptionBox = React.lazy(()=>import('./countries/modals/options'))
const StateList = React.lazy(()=>import('./states/list'))
const StateCreate = React.lazy(()=>import('./states/modals/createState'))
const StateEdit = React.lazy(()=>import('./states/modals/editState'))
const StateOptionBox = React.lazy(()=>import('./states/modals/options'))
const DistrictList = React.lazy(()=>import('./districts/list'))
const DistrictCreate = React.lazy(()=>import('./districts/modals/createDistrict'))
const DistrictEdit = React.lazy(()=>import('./districts/modals/editDistrict'))
const DistrictOptionBox = React.lazy(()=>import('./districts/modals/options'))
const AreaList = React.lazy(()=>import('./areas/list'))
const AreaCreate = React.lazy(()=>import('./areas/modals/createArea'))
const AreaEdit = React.lazy(()=>import('./areas/modals/editArea'))
const AreaOptionBox = React.lazy(()=>import('./areas/modals/options'))

export {
    /* start country */
    CountryList,
    CountryCreate,
    CountryEdit,
    CountryOptionBox,
    /* end country */

    /* start state */
    StateList,
    StateCreate,
    StateEdit,
    StateOptionBox,
    /* end state */

    /* start district */
    DistrictList,
    DistrictCreate,
    DistrictEdit,
    DistrictOptionBox,
    /* end district */

    /* start area */
    AreaList,
    AreaCreate,
    AreaEdit,
    AreaOptionBox
    /* end area */

}
import { areaTypeRadioItems } from '../data'
import { AreaListCustomType, AreaResponseType, CountryListCustomType, CountryResponseType, DistrictListCustomType, DistrictResponseType, DropdownItemType, MetaType, StateListCustomType, StateResponseType } from '../type'

/* start get total number of pages */
export const getTotalPages = (meta: MetaType|undefined) => {
    return (meta !== undefined) ? Math.ceil(meta.total/meta.per_page)||1:1
}
/* end get total number of pages */

/* start get dropdown items */
export const getDropdownLabelById = (items: DropdownItemType[], id: any, placeholder?: string) => {
    const item = items.filter((item:DropdownItemType)=> item.id === id)
    if(item.length > 0){
        return item[0].title
    }
    return placeholder || 'Select here'
}
/* end get dropdown items */

/* start country lists */
export const getCountriesForList = (countries: CountryResponseType[]) => {
    return countries?.map((country: CountryResponseType) => {
        return {
            Id:country.id,
            Name:country.name,
            Code:country.code,
            PhonePrefix:country.phone_prefix,
            Status:country.status ? 'Active':'Not Active'
        } as CountryListCustomType
    })
}

export const getCountriesForDropdown = (countries: CountryResponseType[]) => {
    return countries?.map((country: CountryResponseType) => {
        return {
            id:country.id,
            title:country.name
        } as DropdownItemType
    })
}
/* end country lists */

/* start States List */
export const getStatesForList = (states: StateResponseType[]) => {
    return states?.map((state: StateResponseType) => {
        return {
            Id:state.id,
            Name:state.name,
            Country:state.country.name,
            Status:state.status?'Active':'Not Active'
        } as StateListCustomType
    })
}

export const getStatesForDropdown = (states: StateResponseType[]) => {
    return states?.map((state: StateResponseType) => {
        return {
            id:state.id,
            title:state.name
        } as DropdownItemType
    })
}
/* end States List */

/* start Districts List */
export const getDistrictsForList = (districts: DistrictResponseType[]) => {
    return districts?.map((district: DistrictResponseType) => {
        return {
            Id:district.id,
            Name:district.name,
            Country:district.country.name,
            State:district.state.name,
            Status:district.status?'Active':'Not Active'
        } as DistrictListCustomType
    })
}

export const getDistrictsForDropdown = (districts: DistrictResponseType[]) => {
    return districts?.map((district: DistrictResponseType) => {
        return {
            id:district.id,
            title:district.name
        } as DropdownItemType
    })
}
/* end Districts List */

/* start Area List */
export const getAreasForList = (areas: AreaResponseType[]) => {
    return areas?.map((area: AreaResponseType) => {
        return {
            Id:area.id,
            Name:area.name,
            Country:area.country.name,
            State: area.state.name,
            District: area.district.name,
            Type:getDropdownLabelById(areaTypeRadioItems,area.type),
            Status:area.status?'Active':'Not Active'
        } as AreaListCustomType
    })
}

export const getAreasForDropdown = (areas: AreaResponseType[]) => {
    return areas?.map((area: AreaResponseType) => {
        return {
            id:area.id,
            title:area.name
        } as DropdownItemType
    })
}
/* end Area List */
import { useParams } from 'react-router-dom'
import React, { Suspense } from 'react'
import { GlobalLayout, LoaderFour } from '../features/layout'
import { EntranceExamList, ExaminationList } from '../features/examination'

const ExaminationPage = () => {
    const { type } = useParams() as any
    return (
        <Suspense fallback={<LoaderFour />}>
            <GlobalLayout>
                {type === 'examinations' && <ExaminationList />}
                {type === 'entrance' && <EntranceExamList />}
            </GlobalLayout>
        </Suspense>
    )
}

export default ExaminationPage
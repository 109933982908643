import { DropdownItemType } from './type'

export const getInt = (num: string | number) => {
    return (typeof num === 'string') ? parseInt(num) : num
}

export const getDropdownLabelById = (items: DropdownItemType[], id: any, placeholder?: string) => {
    if (items !== undefined) {
        const item = items.filter((item: DropdownItemType) => item.id === id)
        if (item.length > 0) {
            return item[0].title
        }
    }
    return placeholder || 'Select here'
}
import { useParams } from 'react-router-dom'
import React, { Suspense } from 'react'
import { GlobalLayout, LoaderFour } from '../features/layout'
import { CasteList, DepartmentList, DocumentTypeList, EntranceExaminationCentreList, IncomeSlabList, OccupationList, QualificationList, ReligionList } from '../features/misc'

const MiscPage = () => {
    const { type } = useParams() as any
    return (
        <Suspense fallback={<LoaderFour />}>
            <GlobalLayout>
                {type === 'religions' && <ReligionList />}
                {type === 'castes' && <CasteList />}
                {type === 'document-types' && <DocumentTypeList />}
                {type === 'income-slabs' && <IncomeSlabList />}
                {type === 'occupations' && <OccupationList />}
                {type === 'qualifications' && <QualificationList />}
                {type === 'entrance-examination-centres' && <EntranceExaminationCentreList />}
                {type === 'departments' && <DepartmentList />}
            </GlobalLayout>
        </Suspense>

    )
}

export default MiscPage
import dateFormat from 'dateformat'
import { confirmAlert } from 'react-confirm-alert'
import { symbol } from '../../../app/config'
import { monthList, paymodeList } from '../data'
import { DropdownItemType, FeeRateResponseType, SessionResponseType, StandardResponseType, RegularFeeReceiptResponseType, RegularFeeStudent, ReceiptDataResponseType, RegisterFeeStudent, MetaType, FeeDefaulterResponseType, FeeDefaulterListType, TransactionResponseType, TransactionListType } from '../type'

/* start get total number of pages */
export const getTotalPages = (meta: MetaType|undefined) => {
    return (meta !== undefined) ? Math.ceil(meta.total/meta.per_page)||1:1
}
/* end get total number of pages */

export const getFullName = (firstname?: string, middlename?: string, lastname?: string) => {
    return `${firstname}${middlename?` ${middlename}`: ''}${lastname? ` ${lastname}` :''}`
}

export const getUpcomingSession = (sessions: SessionResponseType[]) => {
    if (sessions.length > 0) {
        return sessions[0]
    }
    return {} as SessionResponseType
}

export const getCurrentSession = (sessions: SessionResponseType[]) => {
    if (sessions.length > 0) {
        return sessions.find(session => session.is_current === true) || {} as SessionResponseType
    }
    return {} as SessionResponseType
}

export const getLastSession = (sessions: SessionResponseType[]) => {
    const closedSessions = sessions.filter(session=>session.end !== null)
    if (closedSessions.length > 0) {
        return closedSessions[0]
    }
    return {} as SessionResponseType
}

/* start dropdown label by id */
export const getDropdownLabelById = (items: DropdownItemType[], id: any, placeholder?: string) => {
    if (items !== undefined) {
        const item = items.filter((item: DropdownItemType) => item.id === id)
        if (item.length > 0) {
            return item[0].title
        }
    }
    return placeholder || 'Select here'
}
/* end dropdown label by id */

/* start standard dropdown list */
export const getStandardsForDropdown = (standards: StandardResponseType[]) => {
    return standards?.map((standard: StandardResponseType) => {
        return {
            id: standard.id,
            title: standard.name
        } as DropdownItemType
    })
}
/* end standard dropdown list */

/* start standard dropdown list */
export const preAdmissionFeeSum = (feeRates: FeeRateResponseType[], standard: number | string) => {
    let sum: number = 0
    const rates = feeRates.filter((feeRate: FeeRateResponseType) => (feeRate.standard_id === standard && feeRate.fee_type.before_admission === true))
    if (rates.length > 0) {
        rates.forEach(rate => {
            sum += (typeof rate.rate === 'string') ? parseFloat(rate.rate) : rate.rate
        })
    }
    return sum.toFixed(2)
}
/* end standard dropdown list */

/* confirm fee collect alert */
export const collectWithConfirmation = (acceptAction: () => any, denyAction: () => any, total: number | string | undefined, mode: string | undefined | number) => {
    confirmAlert({
        title: 'Confirm Collection!',
        message: `Are you sure you want to accept ${symbol}${total} in ${getDropdownLabelById(paymodeList, mode)} payment?`,
        closeOnClickOutside: false,
        buttons: [
            {
                label: 'Accept',
                onClick: acceptAction
            },
            {
                label: 'Deny',
                onClick: denyAction
            }
        ]
    })
}
/* end confirm fee collect alert */

/* confirm regular fee collect alert */
export const collectRegularFeeWithConfirmation = (acceptAction: () => any, denyAction: () => any, total: number | string | undefined, mode: string | undefined | number, balance?: number | string | undefined, balanceType?: string | number | undefined, reference?: string | number) => {
    let message = `Are you sure you want to accept ${symbol}${total} in ${getDropdownLabelById(paymodeList, mode)} payment ${mode !== 'cash' && mode !== 'link' && mode !== 'upi' ? `[Reference Number: ${reference}]` : ''}?`
    if (balance !== 0) {
        message = `Are you sure you want to accept ${symbol}${total} in ${getDropdownLabelById(paymodeList, mode)} payment ${mode !== 'cash' && mode !== 'link' && mode !== 'upi' ? `[Reference Number: ${reference}]` : ''} with ${symbol}${balance} ${balanceType}?`
    }
    confirmAlert({
        title: 'Confirm Collection!',
        message: message,
        closeOnClickOutside: false,
        buttons: [
            {
                label: 'Accept',
                onClick: acceptAction
            },
            {
                label: 'Deny',
                onClick: denyAction
            }
        ]
    })
}
/* end confirm regular fee collect alert */


/* array key append start */
export const arrayInApi = (items: any[], key: string) => {
    if (items.length > 1) {
        return `${key}=${items.join(`${key}=`)}`
    }
    return `${key}=${items[0]}`
}
/* array key append end */

/* get sum */
export const sumTotal = (num1: number, num2: number) => {
    let a = 0
    let b = 0
    if (typeof num1 === 'string') {
        a = parseFloat(num1)
    }
    if (typeof num2 === 'string') {
        b = parseFloat(num2)
    }
    return (a + b).toFixed(2)
}
/* get sum */

/* Registration Fee Receipt Student */
export const getRegisterStudent = (feeReceipt: ReceiptDataResponseType) => {
    const student = {} as RegisterFeeStudent
    if (feeReceipt?.student_registration_fee) {
        const item = feeReceipt.student_registration_fee
        student.student_registration_id = item.student_registration_id
        student.name = item.name
        student.father = item.father
        student.contact = item.contact
        student.session = item.session.name
        student.standard = item.standard.name
    }
    return student
}
/* Registration Fee Receipt End */

/* Regular Fee Receipt Student */
export const getRegularStudent = (regularFeeReceipt: RegularFeeReceiptResponseType) => {
    const student = {} as RegularFeeStudent
    if (regularFeeReceipt?.student_regular_fees?.length > 0) {
        const item = regularFeeReceipt.student_regular_fees[0]
        student.admissionNumber = item.student_id
        student.name = item.name
        student.father = item.father
        student.contact = item.contact
        student.session = item.session.name
        student.standard = item.standard.name
    }
    return student
}
/* Regular Fee Receipt End */

/* Get month by number */
export const getMonthByNumber = (month: number) => {
    const monthItem: { id: number, title: string }[] = monthList.filter((monthItem: { id: number, title: string }) => monthItem.id === month)
    if (monthItem.length > 0) {
        return monthItem[0].title
    }
    return ''
}
/* Get month by number end */

/* Get number */
export const getInt = (num: string | number) => {
    return (typeof num === 'string') ? parseInt(num) : num
}

export const getFloat = (num: string | number) => {
    return (typeof num === 'string') ? parseFloat(num) : num
}
/* Get number */

/* Start Cancel with confirmation */
export const CancelWithConfirmation = (acceptAction: () => any, denyAction: () => any) => {
    confirmAlert({
        title: 'Cancel Print Job!',
        message: 'Are you sure you want to close without printing receipt?',
        closeOnClickOutside: false,
        buttons: [
            {
                label: 'Yes',
                onClick: acceptAction
            },
            {
                label: 'No',
                onClick: denyAction
            }
        ]
    })
}
/* End Cancel with confirmation */

/** Fee defaulter List */
export const getFeeDefaultersForList = (defaulters: FeeDefaulterResponseType[]) => {
    return defaulters?.map((defaulter: FeeDefaulterResponseType) => {
        return {
            Session: defaulter.session.name,
            Admission: defaulter.student_id,
            Name: `${defaulter.student?.firstname} ${defaulter.student?.middlename || ''} ${defaulter.student?.lastname || ''}`,
            Amount: defaulter.amount,
            Fine: defaulter.fine,
            Total: (getFloat(defaulter.fine) + getFloat(defaulter.amount)).toFixed(2),
            Status: defaulter.status.toUpperCase()
        } as FeeDefaulterListType
    })
}
/** Fee defaulter List End */

/** Fee transaction List */
export const getTransactionForList = (transactions: TransactionResponseType[]) => {
    return transactions?.map((transaction: TransactionResponseType) => {
        return {
            Head: transaction.type_head.toUpperCase(),
            Receipt_Number: transaction.receipt_number,
            Amount: transaction.total_payable,
            Payment_Mode: transaction.payment_mode.toUpperCase(),
            Transaction_Type: transaction.type.toUpperCase(),
            Status: transaction.transaction_status.toUpperCase(),
            Date: dateFormat(transaction.created_at, 'dd-mm-yyyy h:MM:ss TT'),
            By: getFullName(transaction?.user?.firstname, transaction?.user?.middlename || undefined, transaction?.user?.lastname || undefined)
        } as TransactionListType
    })
}
/** Fee transaction List End */
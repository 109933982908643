import RestApiService from '../../service/RestApiService'

/* Common API start */

export const getStandardDropdown = async (sort?: string, order?: string) => {
    const path = `academic/standards/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getSubjectDropdownItems = async (sort?: string, order?: string) => {
    const path = `academic/subjects/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getSessionDropdown = async (sort?: string, order?: string) => {
    const path = `academic/sessions/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getSectionDropdownItems = async (sort?: string, order?: string, sessionId?: number, standardId?: number) => {
    const path = `academic/sections/dropdown?sort=${sort}&order=${order}&session_id=${sessionId}&standard_id=${standardId}`
    return await RestApiService.get(path)
}

export const getStandardSubjectDropdownItems = async (sessionId: number, standardId: number, sort?: string, order?: string) => {
    const path = `academic/standard-subjects/dropdown?sort=${sort}&order=${order}&session_id=${sessionId}&standard_id=${standardId}`
    return await RestApiService.get(path)
}

export const getStudentDropdownItems = async (sessionId: number, standardId: number, sectionId: number, subjectId: number) => {
    const path = `student/student-academics/dropdown?session_id=${sessionId}&standard_id=${standardId}&section_id=${sectionId}&subject_id=${subjectId}`
    return await RestApiService.get(path)
}

export const getEntranceExaminationCentresDropdownItems = async (sort?: string, order?: string) => {
    const path = `misc/entrance-examination-centres/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

/* Common API end */

/* Examination API Start */

export const getExaminations = async (page?: number, records?: number, sort?: string, order?: string, sessionId?: number, filter?: object) => {
    const path = `examination/examinations?page=${page}&records=${records}&sort=${sort}&order=${order}&session_id=${sessionId}`
    return await RestApiService.get(path, filter)
}

export const getSelectedExamination = async (id: number) => {
    const path = `examination/examinations/${id}`
    return await RestApiService.get(path)
}

export const createExamination = async (data: any) => {
    const path = 'examination/examinations'
    return await RestApiService.post(path, data)
}

export const patchSelectedExamination = async (id: number, data: any) => {
    const path = `examination/examinations/${id}`
    return await RestApiService.put(path, data)
}

export const getAreaDropdown = async (sort?: string, order?: string) => {
    const path = `location/areas/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

/* Examination API End */

/* Examination Marks API Start */

export const createExamMarks = async (data: any) => {
    const path = 'examination/examination-marks'
    return await RestApiService.post(path, data)
}

export const patchSelectedExaminationMarks = async (id?: string, data?: any) => {
    const path = `examination/examination-marks/${id}`
    return await RestApiService.put(path, data)
}

/* Examination Marks API End */

/* Entrance Examination Start */

export const getEntranceExaminations = async (sessionId: number, page: number, records: number, sort?: string, order?: string, filter?: object) => {
    const path = `examination/entrance-examinations?page=${page}&records=${records}&sort=${sort}&order=${order}&session_id=${sessionId}`
    return await RestApiService.get(path, filter)
}

export const getSelectedEntranceExamination = async (id: number) => {
    const path = `examination/entrance-examinations/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedEntranceExamination = async (id: number, data: any) => {
    const path = `examination/entrance-examinations/${id}`
    return await RestApiService.put(path, data)
}

export const getStudentEntranceList = async (page: number, records: number, sessionId: number, admissionType: string, admissionStatus: string, standardId?: number | string, localAreaId?: number | string, gender?: string | number) => {
    const path = `registration/student-registrations?page=${page}&records=${records}&session_id=${sessionId}&standard_id=${standardId}&gender=${gender}&local_area_id=${localAreaId}&admission_type=${admissionType}&admission_status=${admissionStatus}`
    return await RestApiService.get(path)
}

export const rescheduleExaminations = async (data: any) => {
    const path = 'examination/entrance-examinations/schedule'
    return await RestApiService.post(path, data)
}

export const getEntranceExaminationsDropdownList = async (sessionId: number, status?: string, standardId?: number|string, examinationCenterId?: number|string, examDate?: string) => {
    const path = `examination/entrance-examinations/dropdown?session_id=${sessionId}&exam_status=${status}&standard_id=${standardId}&entrance_examination_centre_id=${examinationCenterId}&exam_date=${examDate}`
    return await RestApiService.get(path)
}

/* Entrance Examination End */
import { useParams } from 'react-router-dom'
import React, { Suspense } from 'react'
import { FeeLateList, FeeRateList, FeeTypeList } from '../features/fee'
import { GlobalLayout, LoaderFour } from '../features/layout'

const FeePage = () => {
    const { type } = useParams() as any
    return (
        <Suspense fallback={<LoaderFour/>}>
            <GlobalLayout>
                {type === 'fee-types' && <FeeTypeList/>}
                {type === 'fee-rates' && <FeeRateList/>}
                {type === 'fee-lates' && <FeeLateList/>}
            </GlobalLayout>
        </Suspense>
    )
}

export default FeePage
import CIcon from '@coreui/icons-react'
import { CInputGroupPrepend, CInputGroupText, CInput, CInputGroupAppend, CInvalidFeedback, CLabel, CInputGroup } from '@coreui/react'
import dateFormat from 'dateformat'
import React, { useEffect, useState } from 'react'
import { constructDate, constructTime, formatDate, handleDateInput, handleTimeInput, validateDate, validateTime } from './helper'
import { DateTimeInputPropType } from './type'

export const DateTime = ({
    value,
    onValueChange,
    label,
    showTimeInput,
    required,
    placeholder,
    leftIcon,
    leftText,
    rightIcon,
    rightText,
    autocomplete,
    error,
    size,
    className,
    defaultErrorMessage
}: DateTimeInputPropType) => {

    const [dateInputValue, setDateInputValue] = useState(handleDateInput(value))
    const [timeInputValue, setTimeInputValue] = useState(handleTimeInput(value))
    const [errorMessage, setErrorMessage] = useState<string>(error || '')

    const onDateValueChange = (formattedDate: string|null) => {
        if (!!formattedDate || onValueChange) {
            return onValueChange(formattedDate)
        }
        throw new Error('Provide either onValueChange to Input component.')
    }

    // onChange event handlers start
    const handleDateOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setDateInputValue(value.replace(/\D/, ''))
        if (value === null || value === undefined || value === '') {
            setDateInputValue('')
        }
    }

    const handleTimeOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setTimeInputValue(value.replace(/\D/, ''))
        if (value === null || value === undefined || value === '') {
            setTimeInputValue('')
        }
    }
    // onChange event handlers end

    // onFocus event handlers start
    const handleDateOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        const inputValue = event.target.value
        if (inputValue.length === 10) {
            setDateInputValue(inputValue.replace(/-/g,''))
        }
    }

    const handleTimeOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        const inputValue = event.target.value
        if (inputValue.length === 5) {
            setTimeInputValue(inputValue.replace(/:/g,''))
        }
    }
    // onFocus event handlers end

    // onBlur event handlers start
    const handleDateOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setErrorMessage('')
        const value = (event.target.value).replace(/\D/g, '')
        const dateString = constructDate(value)
        if (validateDate(dateString)){
            setDateInputValue(dateString)
            handleChange(dateString, undefined)
        } else {
            setErrorMessage('Invalid date entered')
            setDateInputValue('')
            handleChange(undefined, undefined)
        }
        if (defaultErrorMessage === false){
            setErrorMessage('')
        }
    }

    const handleTimeOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setErrorMessage('')
        const value = (event.target.value).replace(/\D/g, '')
        const timeString = constructTime(value)
        if (validateTime(timeString)){
            setTimeInputValue(timeString)
            handleChange(undefined, timeString)
        } else {
            setErrorMessage('Invalid time entered')
            setTimeInputValue('')
            handleChange(undefined, undefined)
        }
    }
    // onBlur event handlers end

    const handleChange = (date?: string|undefined, time?: string|undefined) => {
        onDateValueChange(null)
        setErrorMessage('')
        const datevalue = date ? date : dateInputValue
        const timeValue = time ? time : timeInputValue
        if (showTimeInput) {
            if(!datevalue || !timeValue){
                setErrorMessage('Invalid datetime value.')
                onDateValueChange(null)
            } else {
                const combine = `${formatDate(datevalue)} ${timeValue}:00`
                onDateValueChange(dateFormat(new Date(combine), 'yyyy-mm-dd HH:MM:ss'))
            }
        } else {
            if(!datevalue){
                setErrorMessage('Invalid date value.')
                onDateValueChange(null)
            } else {
                onDateValueChange(dateFormat(new Date(formatDate(datevalue)), 'yyyy-mm-dd'))
            }
        }
    }

    // Update input logic begin
    useEffect(()=>{
        setDateInputValue(handleDateInput(value))
        setTimeInputValue(handleTimeInput(value))
    }, [value])
    // Update input logic end

    return (
        <>
        {label && <CLabel className={required?'required-class font-weight-bolder':'font-weight-bolder'}>{label}</CLabel>}
        <CInputGroup>
            {(leftIcon || leftText) &&<CInputGroupPrepend>
                <CInputGroupText className={(required && !label)?'required-class':''}>
                    {leftIcon && <CIcon name={leftIcon || 'cil-calendar'} />}
                    {leftText && <>{leftText}</>}
                </CInputGroupText>
            </CInputGroupPrepend>}
            <CInput
                value={dateInputValue || undefined || ''}
                type={'text'}
                placeholder={ placeholder || 'Enter Here..'}
                autoComplete={autocomplete || 'none'}
                onChange={handleDateOnChange}
                onBlur={handleDateOnBlur}
                onFocus={handleDateOnFocus}
                invalid={errorMessage ? true : false}
                size={size}
                className={className}
            />
            {showTimeInput && <CInput
                value={timeInputValue || undefined || ''}
                type={'text'}
                placeholder={'hh:mm'}
                onChange={handleTimeOnChange}
                onBlur={handleTimeOnBlur}
                onFocus={handleTimeOnFocus}
                invalid={(error || errorMessage)? true : false}
                size={size}
                className={className}
            />}
            {(errorMessage && error === undefined) && <CInvalidFeedback>{errorMessage}</CInvalidFeedback>}
            {(rightIcon ||rightText)&&<CInputGroupAppend>
                <CInputGroupText>
                    {rightIcon && <CIcon name={rightIcon || 'cil-clock'} />}
                    {rightText && <CInputGroupText>{rightText}</CInputGroupText>}
                </CInputGroupText>
            </CInputGroupAppend>}
        </CInputGroup>
        </>
    )
}
import RestApiService from '../../service/RestApiService'
import { arrayInApi } from './helper'

/* Fee Collect api start */
export const getSessionDropdown = async (sort?: string, order?: string) => {
    const path = `academic/sessions/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getStandardDropdown = async (sort?: string, order?: string) => {
    const path = `academic/standards/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getFeeRateList = async (sort?: string, order?: string, session?: number) => {
    const path = `fee/fee-rates/list?sort=${sort}&order=${order}&session_id=${session}`
    return await RestApiService.get(path)
}

/* Fee Collect api end */

/* Registration Fee Collect api start */
export const getRegistration = async (id: number) => {
    const path = `registration/student-registrations/${id}`
    return await RestApiService.get(path)
}

export const postRegistrationFee = async(data: any) => {
    const path = 'fee/fee-collects/registration'
    return await RestApiService.post(path, data)
}

export const getRegistrationFeeReceiptData = async (id: string) => {
    const path = `fee/fee-collects/registration/${id}`
    return await RestApiService.get(path)
}
/* Registration Fee Collect api end */

/* Regular Fee Collect api start */
export const getRegularFee = async(session: number,standard:string|number, months: number[],student?: number) => {
    const path = `fee/fee-collects/regular?session_id=${session}&standard_id=${standard}${arrayInApi(months,'&months')}&student_id=${student}`
    return await RestApiService.get(path)
}

export const postRegularFee = async(data: any) => {
    const path = 'fee/fee-collects/regular'
    return await RestApiService.post(path, data)
}

export const getStudentFeeDetail = async(session: number, student?: string, registration?: string) => {
    const path = `fee/fee-collects/regular-student?session_id=${session}&student_id=${student}&registration_id=${registration}`
    return await RestApiService.get(path)
}

export const getRegularFeeReceiptData = async(receipt: string) => {
    const path = `fee/fee-collects/regular/${receipt}`
    return await RestApiService.get(path)
}
/* Regular Fee Collect api end */

/* Defaulter Fee Collect api start */
export const getFeeDefaulters = async(page: number, records: number, session?: number,standard?:string|number, months?: number[], student?: number) => {
    const path = `fee/fee-defaulter?page=${page}&records=${records}&session_id=${session}&standard_id=${standard}&student_id=${student}${arrayInApi(months || [],'&months')}`
    return await RestApiService.get(path)
}
/* Defaulter Fee Collect api end */

/* Transactions api start */
export const getFeeTransactions = async(page: number, records: number, filters?: object) => {
    const path = `transaction/transactions?page=${page}&records=${records}`
    return await RestApiService.get(path, filters)
}
/* Transactions api end */
import RestApiService from '../../service/RestApiService'

/* Teacher API start */

export const getTeachers = async (page: number, records: number, filters?: object) => {
    const path = `staff/teachers?page=${page}&records=${records}`
    return await RestApiService.get(path, filters)
}

export const getTeacher = async (id: number) => {
    const path = `staff/teachers/${id}`
    return await RestApiService.get(path)
}

export const createTeacherSubject = async (data: any) => {
    const path = 'staff/teachers'
    return await RestApiService.post(path, data)
}

export const updateTeacherSubject = async (id: number, data: any) => {
    const path = `staff/teachers/${id}`
    return await RestApiService.put(path, data)
}

export const getStandardDropdown = async (sort?: string, order?: string) => {
    const path = `academic/standards/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getSubjectDropdownItems = async (sort?: string, order?: string) => {
    const path = `academic/subjects/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getStandardSubject = async (id?: number) => {
    const path = `academic/standards/${id}`
    return await RestApiService.get(path)
}

export const getSessionDropdown = async (sort?: string, order?: string) => {
    const path = `academic/sessions/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

/* Teacher API end */
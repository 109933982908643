import { useParams } from 'react-router-dom'
import React, { Suspense } from 'react'
import { GlobalLayout, LoaderFour } from '../features/layout'
import { FeeDailyReport, FeeSummaryReport } from '../features/reports'

const ReportPage = () => {
    const { type } = useParams() as any
    return (
        <Suspense fallback={<LoaderFour />}>
            <GlobalLayout>
                {type === 'fee-summary' && <FeeSummaryReport />}
                {type === 'daily-fee' && <FeeDailyReport />}
            </GlobalLayout>
        </Suspense>
    )
}

export default ReportPage
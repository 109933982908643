import { DropdownItemType, EmployeeResponseType, HouseListCustomType, HouseResponseType, MetaType, SeatListCustomType, SeatResponseType, SectionListCustomType, SectionResponseType, SessionListCustomType, SessionResponseType, StandardListCustomType, StandardResponseType, SubjectListCustomType, SubjectResponseType } from '../type'

/* start total pages */
export const getTotalPages = (meta: MetaType | undefined) => {
    return (meta !== undefined) ? Math.ceil(meta.total / meta.per_page) || 1 : 1
}
/* end total pages */

/* start seats list */
export const getSeatsForList = (seats: SeatResponseType[]) => {
    return seats?.map((seat) => {
        return {
            Id: seat.id,
            Seats: seat.seats,
            Session: seat.session.name,
            Standard: seat.standard.name,
            Status: seat.status ? 'Active' : 'Not Active'
        } as SeatListCustomType
    })
}
/* end seats list */

/* start section list */
export const getSectionsForList = (sections: SectionResponseType[]) => {
    return sections?.map((section: SectionResponseType) => {
        return {
            Id: section.id,
            Name: section.name,
            Order: section.sort,
            Status: section.status ? 'Active' : 'Not Active'
        } as SectionListCustomType
    })
}
/* end section list */

/* start section list dropdown */
export const getSectionsForDropdown = (sections: SectionResponseType[]) => {
    return sections?.map((section: SectionResponseType) => {
        return {
            id: section.id,
            title: section.name
        } as DropdownItemType
    })
}
/* end section list dropdown */

/* start session list */
export const getSessionsForList = (sessions: SessionResponseType[]) => {
    return sessions?.map((session: SessionResponseType) => {
        return {
            Id: session.id,
            Name: session.name,
            Start: session.start,
            End: session.end || 'NA',
            Current: session.is_current ? 'Current' : '--',
            Status: session.status ? 'Active' : 'Not Active'
        } as SessionListCustomType
    })
}
/* end session list */

/* start sessions dropdown list */
export const getSessionsForDropdown = (sessions: SessionResponseType[]) => {
    return sessions?.map((session: SessionResponseType) => {
        return {
            id: session.id,
            title: session.name
        } as DropdownItemType
    })
}
/* end sessions dropdown list */

/* start standards list */
export const getStandardsForList = (standards: StandardResponseType[], activeSession: SessionResponseType) => {
    return standards?.map((standard: StandardResponseType) => {
        return {
            Id: standard.id,
            Name: standard.name,
            Order: standard.sort,
            Status: standard.status ? 'Active' : 'Not Active',
            Subjects: `${standard.standard_subject.find(data => activeSession.id === data.session_id)?.compulsory_subject_count||0}+${standard.standard_subject.find(data => activeSession.id === data.session_id)?.optional_subject_count||0}`,
            Sections: standard.sections.filter(data => activeSession.id === data.session_id).length,
            Seats: standard.seats.find(data => activeSession.id === data.session_id)?.seats,
            Standard_Subject: standard.standard_subject.find((data) => data.session_id === activeSession.id)
        } as StandardListCustomType
    })
}
/* end standards list */

/* start standards dropdown list */
export const getStandardsForDropdown = (standards: StandardResponseType[]) => {
    return standards?.map((standard: StandardResponseType) => {
        return {
            id: standard.id,
            title: standard.name
        } as DropdownItemType
    })
}
/* end standards dropdown list */

/* start dropdown label by id */
export const getDropdownLabelById = (items: DropdownItemType[], id: any, placeholder?: string) => {
    if (items !== undefined) {
        const item = items.filter((item: DropdownItemType) => item.id === id)
        if (item.length > 0) {
            return item[0].title
        }
    }
    return placeholder || 'Select here'
}
/* end dropdown label by id */

/* start subject list */
export const getSubjectsForList = (subjects: SubjectResponseType[]) => {
    return subjects?.map((subject: SubjectResponseType) => {
        return {
            Id: subject.id,
            Name: subject.name,
            Status: subject.status ? 'Active' : 'Not Active'
        } as SubjectListCustomType
    })
}
/* end subject list */

/* start subject list dropdown */
export const getSubjectsForDropdown = (subjects: SubjectResponseType[]) => {
    return subjects?.map((subject: SubjectResponseType) => {
        return {
            id: subject.id,
            title: subject.name
        } as DropdownItemType
    })
}
/* end subject list dropdown */

/* start house list dropdown */
export const getHousesForDropdown = (houses: HouseResponseType[]) => {
    return houses?.map((house: HouseResponseType) => {
        return {
            id: house.id,
            title: house.name
        } as DropdownItemType
    })
}
/* end house list dropdown */

export const getActiveSession = (sessions: SessionResponseType[]) => {
    if (sessions.length > 0) {
        return sessions.find((session: SessionResponseType) => session.is_current === true)
    }
    return {} as SessionResponseType
}

export const getInt = (num: string | number) => {
    return (typeof num === 'string') ? parseInt(num) : num
}

export const getTeacherName = (employee: EmployeeResponseType | undefined) => {
    return employee ? `${employee.firstname}${employee.middlename ? ` ${employee.middlename}` : ''}${employee.lastname ? ` ${employee.lastname}` : ''}` : 'NA'
}

/* start teacher dropdown list */

export const getTeachersForDropdown = (teachers: EmployeeResponseType[]) => {
    return teachers?.map((teacher: EmployeeResponseType) => {
        return {
            id: teacher.id,
            title: getTeacherName(teacher)
        } as DropdownItemType
    })
}

/* end teacher dropdown list */

/*start standard subject dropdown list */

export const getStandardSubjectForDropdown = (subjects: SubjectResponseType[]) => {
    return subjects?.map((subject: SubjectResponseType) => {
        return {
            id: subject.id,
            title: subject.name
        } as DropdownItemType
    })
}

/*end standard subject dropdown list */

/* start house list */
export const getHousesForList = (houses: HouseResponseType[]) => {
    return houses?.map((house: HouseResponseType) => {
        return {
            Id: house.id,
            Name: house.name,
            Color: house.color,
            Status: house.status ? 'Active' : 'Not Active'
        } as HouseListCustomType
    })
}
/* end house list */
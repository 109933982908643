import CIcon from '@coreui/icons-react'
import { CInputGroupPrepend, CInputGroupText, CInput, CLabel, CDropdown, CDropdownToggle, CDropdownItem, CDropdownMenu, CInputGroup, CInputGroupAppend } from '@coreui/react'
import React, { useState } from 'react'
import { MultiselectPropType, DropdownItemType } from './type'
import { Div, Span } from './styled'
import { getDropdownLabelById, getInt } from './helper'

export const MultiSelect = ({
    onSelect,
    value,
    items,
    label,
    searchTitle,
    title,
    disabled,
    required,
    leftIcon,
    leftText,
    rightIcon,
    rightText,
    selectionLimit,
    selectAll
}: MultiselectPropType) => {

    const Item = ({ title, onClick }: DropdownItemType & { onClick: () => any }) => {
        return (
            <CDropdownItem disabled={selectionLimit !== undefined && selectionLimit === value?.length} onClick={onClick}>{title}</CDropdownItem>
        )
    }

    const [searchValue, setSearchValue] = useState('')

    const selectedValues = value?.map((data) => data)

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value)
    }

    const handleSelection = (data: DropdownItemType) => {
        const selectData = {
            id: data.id,
            title: data.title
        }
        const selectedOptions = value?.map(data => data)
        const newArray = [...selectedOptions]
        newArray?.push(selectData.id.toString())
        if (onSelect) {
            return onSelect(newArray)
        }
    }

    const handleSelectAll = (data: DropdownItemType[]) => {
        const selectedOptions = value?.map(data => data)
        const newArray = [...selectedOptions]
        data?.forEach((data) => {
            newArray.push(data.id.toString())
        })
        if (onSelect) {
            return onSelect(newArray)
        }
    }

    const handleRemove = (id: number) => {
        const selectedOptions = value?.map(data => data)
        const newArray = [...selectedOptions]
        newArray.splice(id, 1)
        if (onSelect && !disabled) {
            return onSelect(newArray)
        }
    }

    const itemsData = items.filter(item => !selectedValues?.includes(item.id.toString()))


    const itemSearchFilter = (item: DropdownItemType) => item.title.toLowerCase().includes(searchValue.toLowerCase())

    return (
        <>
            {label && <CLabel className={required ? 'required-class font-weight-bolder' : 'font-weight-bolder'}>{label}</CLabel>}
            <CDropdown className={'w-100'}>
                <CDropdownToggle color={'secondary'} className={`d-block w-100 p-0 custom-mdd-input text-left bg-white h6 mb-0 ${disabled && 'is-disable'}`}>
                    <CInputGroup>
                        {(leftIcon || leftText) && <CInputGroupPrepend>
                            <CInputGroupText className={(required && !label) ? 'required-class font-weight-bolder' : ' font-weight-bolder'}>
                                {leftIcon && <CIcon name={leftIcon} />}
                                {leftText && <>{leftText}</>}
                            </CInputGroupText>
                        </CInputGroupPrepend>}
                        <Div className={'multi-dropdown-container'}>
                            {value !== undefined && value?.length > 0 ? value.map((data, index) => {
                                return (
                                    <Span key={index} className={'multi-dropdown-tag'}>{getDropdownLabelById(items, getInt(data))} <CIcon name={'cilX'} onClick={() => handleRemove(index)} /></Span>
                                )
                            }) : title}
                        </Div>
                        {(rightIcon || rightText) && <CInputGroupAppend>
                            {(rightIcon || rightText) && <CInputGroupText className={'font-weight-bolder'}>
                                {rightIcon && <CIcon name={rightIcon} />}
                                {rightText && <CInputGroupText>{rightText}</CInputGroupText>}
                            </CInputGroupText>}
                        </CInputGroupAppend>}
                    </CInputGroup>
                </CDropdownToggle>
                {!disabled &&<CDropdownMenu className={'p-0 dropdown-menu-height'}>
                    {!!searchTitle && <CInputGroup className={'px-1 py-1'}>
                        <CInputGroupPrepend>
                            <CInputGroupText>
                                <CIcon name={'cil-search'} />
                            </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={searchTitle}
                        />
                    </CInputGroup>}
                    {selectAll && !disabled && <Item id={'select-all'} onClick={() => handleSelectAll(itemsData)} title={'Select All'} />}
                    {(items !== undefined && !disabled) && itemsData.filter(itemSearchFilter).map(item =>
                        <Item key={item.id} onClick={() => handleSelection(item)} {...item} />
                    )}
                </CDropdownMenu>}
            </CDropdown>
        </>
    )
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getFeeRateList, getRegistrationFeeReceiptData, getRegularFee, getSessionDropdown, getStandardDropdown, getRegularFeeReceiptData, getFeeDefaulters, getFeeTransactions } from './api'
import { getCurrentSession, getUpcomingSession, getStandardsForDropdown, getLastSession } from './helper'
import { DropdownItemType, ReceiptDataResponseType, FeeRateResponseType, RegularFeesResponseType, SessionResponseType, RegularFeeReceiptResponseType, FeeDefaulterResponseType, MetaType, TransactionResponseType, FilterType } from './type'

export const AccountSlice = createSlice({
    name: 'account',
    initialState: {
        /*start common*/
        loading:false as boolean,
        meta: {} as MetaType,
        session: {} as SessionResponseType,
        currentSession: {} as SessionResponseType,
        upcomingSession: {} as SessionResponseType,
        lastSession: {} as SessionResponseType|null,
        standards: [] as DropdownItemType[],
        feeRates: [] as FeeRateResponseType[],
        regularFees: {} as RegularFeesResponseType,
        filter: {} as FilterType,
        /*end common*/

        /*start receipt data*/
        registrationFeeReceiptData: {} as any,
        regularFeeReceiptData: {} as any,
        /*end receipt data*/

        /*start fee defaulter data*/
        feeDefaulters: [] as FeeDefaulterResponseType[],
        selectedFeeDefaulterPage: 1 as number,
        /*end fee defaulter data*/

        /*start transaction data*/
        transactions: [] as TransactionResponseType[],
        selectedTransactionPage: 1 as number
        /*end transaction data*/
    },
    reducers:{
        /* start common */
        setLoading: (state, action:PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setMeta: (state, action:PayloadAction<MetaType>) => {
            state.meta = action.payload
        },
        setSession: (state, action:PayloadAction<SessionResponseType>) => {
            state.session = action.payload
        },
        setUpcomingSession: (state, action:PayloadAction<SessionResponseType>) => {
            state.upcomingSession = action.payload
        },
        setCurrentSession: (state, action:PayloadAction<SessionResponseType>) => {
            state.currentSession = action.payload
        },
        setLastSession: (state, action:PayloadAction<SessionResponseType>) => {
            state.lastSession = action.payload
        },
        setStandards: (state, action:PayloadAction<DropdownItemType[]>) =>{
            state.standards = action.payload
        },
        setFeeRates: (state, action:PayloadAction<FeeRateResponseType[]>) =>{
            state.feeRates = action.payload
        },
        setRegularFees: (state, action:PayloadAction<RegularFeesResponseType>) => {
            state.regularFees = action.payload
        },
        setFilter: (state, action:PayloadAction<FilterType>) => {
            state.filter = action.payload
        },
        /* end common */

        /*start receipt data*/
        setRegistrationFeeReceiptData: (state, action:PayloadAction<ReceiptDataResponseType>) =>{
            state.registrationFeeReceiptData = action.payload
        },
        setRegularFeeReceiptData: (state, action:PayloadAction<RegularFeeReceiptResponseType>) => {
            state.regularFeeReceiptData = action.payload
        },
        /*end receipt data*/

        /*start fee defaulter data*/
        setFeeDefaulters: (state, action:PayloadAction<FeeDefaulterResponseType[]>) => {
            state.feeDefaulters = action.payload
        },
        setSelectedFeeDefaulterPage: (state, action:PayloadAction<number>) => {
            state.selectedFeeDefaulterPage = action.payload
        },
        /*end fee defaulter data*/

        /*start fee transaction data*/
        setTransactions: (state, action:PayloadAction<TransactionResponseType[]>) => {
            state.transactions = action.payload
        },
        setSelectedTransactionPage: (state, action:PayloadAction<number>) => {
            state.selectedTransactionPage = action.payload
        }
        /*end fee defaulter data*/
    }
})

export const {
    /* start common */
    setLoading,
    setMeta,
    setSession,
    setCurrentSession,
    setUpcomingSession,
    setLastSession,
    setStandards,
    setFeeRates,
    setRegularFees,
    setFilter,
    /* end common */

    /*start receipt data*/
    setRegistrationFeeReceiptData,
    setRegularFeeReceiptData,
    /*end receipt data*/

    /*start fee defaulter data*/
    setFeeDefaulters,
    setSelectedFeeDefaulterPage,
    /*end fee defaulter data*/

    /*start fee defaulter data*/
    setTransactions,
    setSelectedTransactionPage
    /*end fee defaulter data*/
} = AccountSlice.actions

export const loadSession = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const sessionResponse = await getSessionDropdown('start', 'DESC')
    dispatch(setSession(getCurrentSession(sessionResponse.items)))
    dispatch(setCurrentSession(getCurrentSession(sessionResponse.items)))
    dispatch(setUpcomingSession(getUpcomingSession(sessionResponse.items)))
    dispatch(setLastSession(getLastSession(sessionResponse.items)))
    dispatch(setLoading(false))
}

export const loadStandards = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const standardsResponse = await getStandardDropdown()
    dispatch(setStandards(getStandardsForDropdown(standardsResponse.items)))
    dispatch(setLoading(false))
}

export const loadFeeRates = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const feeRateListResponse = await getFeeRateList(undefined, undefined, id)
    dispatch(setFeeRates(feeRateListResponse.list))
    dispatch(setLoading(false))
}

export const loadRegistrationFeeReceiptData = (id: string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const registrationFeeReceiptDataResponse = await getRegistrationFeeReceiptData(id)
    dispatch(setRegistrationFeeReceiptData(registrationFeeReceiptDataResponse))
    dispatch(setLoading(false))
}

export const loadRegularFee = (sessionId: number, standardId: number|string, months: number[], student?:number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const feeRegularResponse = await getRegularFee(sessionId,standardId,months,student)
    dispatch(setRegularFees(feeRegularResponse))
    dispatch(setLoading(false))
}

export const loadRegularFeeReceiptData = (receipt: string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const regularFeeReceiptData = await getRegularFeeReceiptData(receipt)
    dispatch(setRegularFeeReceiptData(regularFeeReceiptData))
    dispatch(setLoading(false))
}

export const loadFeeDefaulter = (page: number, records:number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const defaultersList = await getFeeDefaulters(page, records)
    dispatch(setFeeDefaulters(defaultersList.list))
    dispatch(setMeta(defaultersList.meta))
    dispatch(setLoading(false))
}

export const loadTransactions = (page: number, records:number, filters?: object) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response = await getFeeTransactions(page, records, filters)
    dispatch(setTransactions(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

/* start common */
export const selectLoading = (state:any) => state.account.loading
export const selectSession = (state: any) => state.account.session
export const selectUpcomingSession = (state: any) => state.account.upcomingSession
export const selectCurrentSession = (state: any) => state.account.currentSession
export const selectLastSession = (state: any) => state.account.lastSession
export const selectStandards = (state: any) => state.account.standards
export const selectFeeRates = (state: any) => state.account.feeRates
export const selectRegularFees = (state: any) => state.account.regularFees
export const selectMeta = (state: any) => state.account.meta
export const selectFilter = (state: any) => state.account.filter
/* end common */

/*start receipt data*/
export const selectRegistrationFeeReceiptData = (state: any) => state.account.registrationFeeReceiptData
export const selectRegularFeeReceiptData = (state: any) => state.account.regularFeeReceiptData
/*end receipt data*/

/*start fee defaulters data*/
export const selectFeeDefaultersData = (state: any) => state.account.feeDefaulters
export const selectSelectedFeeDefaultersPage = (state: any) => state.account.selectedFeeDefaulterPage
/*end fee defaulters data*/

/*start fee transactions data*/
export const selectTransactionsData = (state: any) => state.account.transactions
export const selectSelectedTransactionPage = (state: any) => state.account.selectedTransactionPage
/*end fee transactions data*/

export default AccountSlice.reducer
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getFeeLates, getFeeRates, getFeeStructureYearly, getFeeTypeDropdownItems, getFeeTypes, getSelectedFeeLate, getSelectedFeeRate, getSelectedFeeType, getSessionDropdownItems, getSessions, getStandardDropdownItems, getStandards } from './api'
import { getActiveSession, getFeeTypesForDropdown, getSessionsForDropdown, getStandardsForDropdown } from './helper'
import { DropdownItemType, FeeLateListResponseType, FeeLateResponseType, FeeRateListResponseType, FeeRateResponseType, FeeStructureYearlyResponseType, FeeTypeListResponseType, FeeTypeResponseType, MetaType, SessionListResponseType, SessionResponseType, StandardListResponseType, StandardResponseType } from './type'

export const FeeSlice = createSlice({
    name: 'fee',
    initialState: {
        /*start common*/
        loading:false as boolean,
        meta: {} as MetaType,
        /*end common*/

        /* start fee type */
        feeTypes: [] as FeeTypeResponseType[],
        selectedFeeTypePage: 1 as number,
        selectedFeeType: {} as FeeTypeResponseType,
        feeTypeDropdownItems: [] as DropdownItemType[],
        /* end fee type */

        /* start fee rate */
        standards: [] as StandardResponseType[],
        sessions: [] as SessionResponseType[],
        activeSession: {} as SessionResponseType,
        feeRates: [] as FeeRateResponseType[],
        selectedFeeRate: {} as FeeRateResponseType,
        yearlyFeeStructure:  [] as FeeStructureYearlyResponseType[],
        /* end fee rate */

        /* start late fee */
        sessionDropdownItems: [] as DropdownItemType[],
        standardDropdownItems: [] as DropdownItemType[],
        feeLates: [] as FeeLateResponseType[],
        selectedFeeLatePage: 1 as number,
        selectedFeeLate: {} as FeeLateResponseType
        /* end fee type */
    },
    reducers:{
        /* start common */
        setLoading: (state, action:PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setMeta: (state, action:PayloadAction<MetaType>) => {
            state.meta = action.payload
        },
        /* end common */

        /* start fee type */
        setSelectedFeeTypePage: (state, action:PayloadAction<number>) => {
            state.selectedFeeTypePage = action.payload
        },
        setFeeTypes: (state, action:PayloadAction<FeeTypeResponseType[]>) => {
            state.feeTypes = action.payload
        },
        setSelectedFeeType: (state, action:PayloadAction<FeeTypeResponseType>) => {
            state.selectedFeeType = action.payload
        },
        setFeeTypeDropdownItems: (state, action:PayloadAction<DropdownItemType[]>) => {
            state.feeTypeDropdownItems = action.payload
        },

        /* end fee type */

        /* start fee rate */
        setFeeRates: (state, action:PayloadAction<FeeRateResponseType[]>) => {
            state.feeRates = action.payload
        },
        setSelectedFeeRate: (state, action:PayloadAction<FeeRateResponseType>) => {
            state.selectedFeeRate = action.payload
        },
        setStandards: (state, action:PayloadAction<StandardResponseType[]>) => {
            state.standards = action.payload
        },
        setSessions: (state, action:PayloadAction<SessionResponseType[]>) => {
            state.sessions = action.payload
        },
        setActiveSession: (state, action:PayloadAction<SessionResponseType[]>) => {
            state.activeSession = getActiveSession(action.payload) as SessionResponseType
        },
        setYearlyFeeStructure: (state, action:PayloadAction<FeeStructureYearlyResponseType[]>) => {
            state.yearlyFeeStructure = action.payload
        },
        /* end fee rate */

        /* start late fee */
        setSelectedFeeLatePage: (state, action:PayloadAction<number>) => {
            state.selectedFeeLatePage = action.payload
        },
        setFeeLates: (state, action:PayloadAction<FeeLateResponseType[]>) => {
            state.feeLates = action.payload
        },
        setSelectedFeeLate: (state, action:PayloadAction<FeeLateResponseType>) => {
            state.selectedFeeLate = action.payload
        },
        setSessionDropdownItems: (state, action:PayloadAction<DropdownItemType[]>) => {
            state.sessionDropdownItems = action.payload
        },
        setStandardDropdownItems: (state, action:PayloadAction<DropdownItemType[]>) => {
            state.standardDropdownItems = action.payload
        }
        /* end late fee */
    }
})

export const {
    /* start common */
    setLoading,
    setMeta,
    /* end common */

    /* start fee type */
    setSelectedFeeTypePage,
    setFeeTypes,
    setSelectedFeeType,
    setFeeTypeDropdownItems,
    /* end fee type */


    /* start fee rate */
    setFeeRates,
    setSelectedFeeRate,
    setStandards,
    setSessions,
    setActiveSession,
    setYearlyFeeStructure,
    /* end fee rate */

    /* start late fee */
    setSelectedFeeLatePage,
    setFeeLates,
    setSelectedFeeLate,
    setSessionDropdownItems,
    setStandardDropdownItems
    /* end late fee */
} = FeeSlice.actions

/* start load fee type */
export const loadFeeTypes = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: FeeTypeListResponseType = await getFeeTypes(page, records)
    dispatch(setFeeTypes(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSelectedFeeType = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: FeeTypeResponseType = await getSelectedFeeType(id)
    dispatch(setSelectedFeeType(response))
    dispatch(setLoading(false))
}

export const loadFeeTypeDropdownItems = (sort?: string, order?:string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: {items: FeeTypeResponseType[]} = await getFeeTypeDropdownItems(sort, order)
    const dropdownItems= getFeeTypesForDropdown(response.items)
    dispatch(setFeeTypeDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}
/* end load fee type */

/* start load fee rates */
export const loadFeeRates = (page: number, records: number,session_id?: number, standard_id?: number, fee_type_id?: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: FeeRateListResponseType = await getFeeRates(page, records, session_id, standard_id, fee_type_id)
    dispatch(setFeeRates(response.list))
    dispatch(setLoading(false))
}

export const loadSelectedFeeRate = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: FeeRateResponseType = await getSelectedFeeRate(id)
    dispatch(setSelectedFeeRate(response))
    dispatch(setLoading(false))
}


export const loadStandards = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StandardListResponseType = await getStandards(page, records)
    const sessions: SessionListResponseType = await getSessions(page, records)
    dispatch(setSessions(sessions.list as SessionResponseType[]))
    dispatch(setStandards(response.list))
    dispatch(setActiveSession(sessions.list))
    dispatch(setLoading(false))
}

export const loadFeeStructureYearly = (session_id: number, standard_id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: {list: FeeStructureYearlyResponseType[]} = await getFeeStructureYearly(session_id, standard_id)
    dispatch(setYearlyFeeStructure(response.list))
    dispatch(setLoading(false))
}
/* end fee rate */

/* start fee late */
export const loadFeeLates = (page: number, records: number, sort?: string, order?: string, session_id?: number, standard_id?: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: FeeLateListResponseType = await getFeeLates(page, records, sort, order, session_id, standard_id)
    dispatch(setFeeLates(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSelectedFeeLate = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: FeeLateResponseType = await getSelectedFeeLate(id)
    dispatch(setSelectedFeeLate(response))
    dispatch(setLoading(false))
}

export const loadSessionDropdownItems = (sort?: string, order?:string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: {items: SessionResponseType[]} = await getSessionDropdownItems(sort, order)
    const dropdownItems= getSessionsForDropdown(response.items)
    dispatch(setSessionDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}

export const loadStandardDropdownItems = (sort?: string, order?:string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: {items: StandardResponseType[]} = await getStandardDropdownItems(sort, order)
    const dropdownItems= getStandardsForDropdown(response.items)
    dispatch(setStandardDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}
/* end load fee rates */


/* start common */
export const selectLoading = (state:any) => state.fee.loading
export const selectMeta = (state:any) => state.fee.meta
/* end common */

/* start fee type */
export const selectSelectedFeeTypePage = (state:any) => state.fee.selectedFeeTypePage
export const selectFeeTypes = (state:any) => state.fee.feeTypes
export const selectSelectedFeeType = (state:any) => state.fee.selectedFeeType
export const selectFeeTypeDropdownItems = (state:any) => state.fee.feeTypeDropdownItems
/* end fee type */

/* start fee rates */
export const selectFeeRates = (state: any) => state.fee.feeRates
export const selectSelectedFeeRate = (state:any) => state.fee.selectedFeeRate
export const selectStandards = (state: any) => state.fee.standards
export const selectSessions = (state: any) => state.fee.sessions
export const selectActiveSession = (state: any) => state.fee.activeSession
export const selectYearlyFeeStructure = (state: any) => state.fee.yearlyFeeStructure
/* end fee rates */

/* start late fee */
export const selectSelectedFeeLatePage = (state:any) => state.fee.selectedFeeLatePage
export const selectFeeLates = (state:any) => state.fee.feeLates
export const selectSelectedFeeLate = (state:any) => state.fee.selectedFeeLate
export const selectSessionDropdownItems = (state:any) => state.fee.sessionDropdownItems
export const selectStandardDropdownItems = (state:any) => state.fee.standardDropdownItems
/* end late fee */

export default FeeSlice.reducer
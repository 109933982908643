export const ExaminationTypeList = [
    { id: 'class-test', title: 'Class Test' },
    { id: 'surprise-test', title: 'Surprise Test' },
    { id: 'unit-test', title: 'Unit Test' },
    { id: 'quarterly', title: 'Quarterly' },
    { id: 'half-yearly', title: 'Half-Yearly' },
    { id: 'annual', title: 'Annual' },
    { id: 'prepration-exam', title: 'Prepration Exam' }
]

export const ExaminationMarkTypeList = [
    { id: 'grade', title: 'Grade' },
    { id: 'number', title: 'Number' }
]

export const genderTypeList = [
    { id: 'male', title: 'Male' },
    { id: 'female', title: 'Female' }
]
import { frequencyItemList } from '../data'
import { DropdownItemType, FeeLateListCustomType, FeeLateResponseType, FeeTypeListCustomType, FeeTypeResponseType, MetaType, SessionResponseType, StandardResponseType, UserSettings } from '../type'

/* start get total number of pages */
export const getTotalPages = (meta: MetaType|undefined) => {
    return (meta !== undefined) ? Math.ceil(meta.total/meta.per_page)||1:1
}
/* end get total number of pages */

/* start get dropdown items */
export const getDropdownLabelById = (items: DropdownItemType[], id: any, placeholder?: string) => {
    if(items !== undefined){
        const item = items.filter((item:DropdownItemType)=> item.id === id)
        if(item.length > 0){
            return item[0].title
        }
    }
    return placeholder || 'Select here'
}
/* start get dropdown items */

/* start comman */
export const booleanToString = (key: boolean|string) => {
    if(typeof key === 'string'){
        return key === 'false'? false: true
    }
    return key
}

export const getSettingValueByKey = (key: string) => {
    const settings: UserSettings[] = JSON.parse('[]')
    if(settings.length>0){
        const setting = settings.filter(setting=>(setting.key === key))
        if(setting.length === 1){
            return setting[0].value
        }
        return null
    }
}

export const getActiveSession = (sessions: SessionResponseType[]) => {
    if(sessions.length > 0){
        return sessions.find((session: SessionResponseType)=> session.is_current === true)
    }
    return {} as SessionResponseType
}
/* end comman */

/* start get fee type list */
export const getFeeTypesForList = (feeTypes: FeeTypeResponseType[]) => {
    return feeTypes?.map((feeType: FeeTypeResponseType) => {
        return {
            Id:feeType.id,
            Name:feeType.name,
            Frequency: getDropdownLabelById(frequencyItemList, feeType.frequency),
            Type: feeType.before_admission ? 'Pre Admission' : 'Post Admission',
            Status:feeType.status?'Active':'Not Active'
        } as FeeTypeListCustomType
    })
}

export const getFeeTypesForDropdown = (feeTypes: FeeTypeResponseType[]) => {
    return feeTypes?.map((feeType: FeeTypeResponseType) => {
        return {
            id:feeType.id,
            title: `${feeType.name} (${feeType.frequency})`
        } as DropdownItemType
    })
}
/* end get fee type list */

/* start get late fee list */
export const getFeeLatesForList = (feeLates: FeeLateResponseType[]) => {
    return feeLates?.map((feeLate: FeeLateResponseType) => {
        return {
            Id:feeLate.id,
            Session: feeLate.session.name,
            Standard: feeLate.standard.name,
            Rate: feeLate.rate,
            Days: feeLate.days,
            Status:feeLate.status?'Active':'Not Active'
        } as FeeLateListCustomType
    })
}
/* end get late fee list */

/* start get session list */
export const getSessionsForDropdown = (sessions: SessionResponseType[]) => {
    return sessions?.map((session: SessionResponseType) => {
        return {
            id:session.id,
            title:session.name
        } as DropdownItemType
    })
}
/* end get session list */

/* start get standard list */
export const getStandardsForDropdown = (standards: StandardResponseType[]) => {
    return standards?.map((standard: StandardResponseType) => {
        return {
            id:standard.id,
            title:standard.name
        } as DropdownItemType
    })
}
/* start get standard list */

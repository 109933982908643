import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAreaDropdown, getCasteDropdown, getIncomeSlabDropdown, getOccupationDropdown, getQualificationDropdown, getRegistration, getReligionDropdown, getSessionDropdown, getStandardDropdown, listRegistration, listAdmission, getStandardSubject, getSubjectDropdownItems } from './api'
import { getAreaForDropdown, getCastesForDropdown, getIncomeSlabForDropdown, getLastSession, getOccupationForDropdown, getQualifcationsForDropdown, getReligionsForDropdown, getStandardsForDropdown, getActiveSession, getSubjectsForDropdown } from './helper'
import { DropdownItemType, MetaType, RegistrationResponseType, SessionResponseType, RegistrationListResponseType, FilterType, StandardSubjectsResponseType, StandardResponseType, AdmissionResponseType, AdmissionListResponseType } from './type'

export const AdmissionSlice = createSlice({
    name: 'admission',
    initialState: {
        /*start common*/
        loading: false as boolean,
        meta: {} as MetaType,
        filters: {} as FilterType,
        registrations: [] as RegistrationResponseType[],
        registration: {} as RegistrationResponseType,
        selectedPage: 1 as number,
        session: {} as SessionResponseType,
        sessions: [] as SessionResponseType[],
        activeSession: {} as SessionResponseType,
        standards: [] as DropdownItemType[],
        religions: [] as DropdownItemType[],
        castes: [] as DropdownItemType[],
        qualifications: [] as DropdownItemType[],
        occupations: [] as DropdownItemType[],
        incomes: [] as DropdownItemType[],
        areas: [] as DropdownItemType[],
        standardSubject: {} as StandardSubjectsResponseType,
        subjects: [] as DropdownItemType[],
        admissions: [] as AdmissionResponseType[],
        admission: {} as AdmissionResponseType
        /*end common*/
    },
    reducers: {
        /* start common */
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setMeta: (state, action: PayloadAction<MetaType>) => {
            state.meta = action.payload
        },
        setFilter: (state, action: PayloadAction<FilterType>) => {
            state.filters = action.payload
        },
        setSession: (state, action: PayloadAction<SessionResponseType>) => {
            state.session = action.payload
        },
        setSessions: (state, action: PayloadAction<SessionResponseType[]>) => {
            state.sessions = action.payload
        },
        setActiveSession: (state, action: PayloadAction<SessionResponseType[]>) => {
            state.activeSession = getActiveSession(action.payload) as SessionResponseType
        },
        setStandards: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.standards = action.payload
        },
        setReligions: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.religions = action.payload
        },
        setCastes: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.castes = action.payload
        },
        setQualifications: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.qualifications = action.payload
        },
        setOccupations: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.occupations = action.payload
        },
        setIncomes: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.incomes = action.payload
        },
        setAreas: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.areas = action.payload
        },
        /* end common */

        /* start registration */
        setRegistrations: (state, action: PayloadAction<RegistrationResponseType[]>) => {
            state.registrations = action.payload
        },
        setSelectedRegistrationPage: (state, action: PayloadAction<number>) => {
            state.selectedPage = action.payload
        },
        setRegistration: (state, action: PayloadAction<RegistrationResponseType>) => {
            state.registration = action.payload
        },
        /* end registration */

        /* Start Admission */
        setAdmissions: (state, action: PayloadAction<AdmissionResponseType[]>) => {
            state.admissions = action.payload
        },
        setSelectedAdmissionPage: (state, action: PayloadAction<number>) => {
            state.selectedPage = action.payload
        },
        setStandardSubject: (state, action: PayloadAction<StandardSubjectsResponseType>) => {
            state.standardSubject = action.payload
        },
        setSubjects: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.subjects = action.payload
        },
        setAdmission: (state, action: PayloadAction<AdmissionResponseType>) => {
            state.admission = action.payload
        }
        /* End Admission */
    }
})

export const {
    /* start common */
    setLoading,
    setMeta,
    setFilter,
    setSession,
    setSessions,
    setActiveSession,
    setStandards,
    setReligions,
    setCastes,
    setQualifications,
    setOccupations,
    setIncomes,
    setAreas,
    setRegistrations,
    setSelectedRegistrationPage,
    setRegistration,
    setSelectedAdmissionPage,
    setStandardSubject,
    setSubjects,
    setAdmissions,
    setAdmission
    /* end common */

} = AdmissionSlice.actions

export const loadSession = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const sessionResponse = await getSessionDropdown('start', 'DESC')
    dispatch(setSession(getLastSession(sessionResponse.items)))
    dispatch(setActiveSession(sessionResponse.items))
    dispatch(setLoading(false))
}

export const loadStandards = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const standardsResponse = await getStandardDropdown()
    dispatch(setStandards(getStandardsForDropdown(standardsResponse.items)))
    dispatch(setLoading(false))
}

export const loadReligions = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const religionsResponse = await getReligionDropdown()
    dispatch(setReligions(getReligionsForDropdown(religionsResponse.items)))
    dispatch(setLoading(false))
}

export const loadCastes = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const castesResponse = await getCasteDropdown(undefined, undefined, id)
    dispatch(setCastes(getCastesForDropdown(castesResponse.items)))
    dispatch(setLoading(false))
}

export const loadQualification = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const qualificationsResponse = await getQualificationDropdown()
    dispatch(setQualifications(getQualifcationsForDropdown(qualificationsResponse.items)))
    dispatch(setLoading(false))
}

export const loadOccupations = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const occupationsResponse = await getOccupationDropdown()
    dispatch(setOccupations(getOccupationForDropdown(occupationsResponse.items)))
    dispatch(setLoading(false))
}

export const loadIncomes = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const incomesResponse = await getIncomeSlabDropdown()
    dispatch(setIncomes(getIncomeSlabForDropdown(incomesResponse.items)))
    dispatch(setLoading(false))
}

export const loadAreas = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const areaResponse = await getAreaDropdown()
    dispatch(setAreas(getAreaForDropdown(areaResponse.items)))
    dispatch(setLoading(false))
}

export const loadRegistrations = (page: number, records: number, session?: number, filters?: object) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: RegistrationListResponseType = await listRegistration(page, records, session, filters)
    dispatch(setRegistrations(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadRegistration = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: RegistrationResponseType = await getRegistration(id)
    dispatch(setRegistration(response))
    dispatch(setLoading(false))
}

export const loadAdmissions = (page: number, records: number, sort: string, order: string, session: number, filters?: object) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: AdmissionListResponseType = await listAdmission(page, records, sort, order, session, filters)
    dispatch(setAdmissions(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadAdmission = (admissionData: AdmissionResponseType) => async (dispatch: any) => {
    dispatch(setLoading(true))
    dispatch(setAdmission(admissionData))
    dispatch(setLoading(false))
}

export const loadStandardSubject = (id: number, activeSessionId: number) => async (disaptch: any) => {
    disaptch(setLoading(true))
    const response: StandardResponseType = await getStandardSubject(id)
    const subjectData: StandardSubjectsResponseType = response?.standard_subject.filter((data) => data.session_id === activeSessionId)[0]
    disaptch(setStandardSubject(subjectData))
    disaptch(setLoading(false))
}

export const loadSubjects = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response = await getSubjectDropdownItems()
    dispatch(setSubjects(getSubjectsForDropdown(response.items)))
    dispatch(setLoading(false))
}

/* start common */
export const selectLoading = (state: any) => state.admission.loading
export const selectMeta = (state: any) => state.admission.meta
export const selectFilter = (state: any) => state.admission.filters
export const selectSession = (state: any) => state.admission.session
export const selectActiveSession = (state: any) => state.admission.activeSession
export const selectStandards = (state: any) => state.admission.standards
export const selectReligions = (state: any) => state.admission.religions
export const selectCastes = (state: any) => state.admission.castes
export const selectQualifications = (state: any) => state.admission.qualifications
export const selectOccupations = (state: any) => state.admission.occupations
export const selectIncomes = (state: any) => state.admission.incomes
export const selectAreas = (state: any) => state.admission.areas
export const selectRegistrations = (state: any) => state.admission.registrations
export const selectSelectedRegistrationPage = (state: any) => state.admission.selectedPage
export const selectSelectedAdmissionPage = (state: any) => state.admission.selectedPage
export const selectRegistration = (state: any) => state.admission.registration
export const selectAdmissions = (state: any) => state.admission.admissions
export const selectAdmission = (state: any) => state.admission.admission
export const selectStandardSubject = (state: any) => state.admission.standardSubject
export const selectSubjects = (state: any) => state.admission.subjects
/* end common */

export default AdmissionSlice.reducer
import RestApiService from '../../service/RestApiService'

/* Registration api start */
export const getSessionDropdown = async (sort?: string, order?: string) => {
    const path = `academic/sessions/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getStandardDropdown = async (sort?: string, order?: string) => {
    const path = `academic/standards/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getReligionDropdown = async (sort?: string, order?: string) => {
    const path = `misc/religions/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getCasteDropdown = async (sort?: string, order?: string, religion_id?: number) => {
    const path = `misc/castes/dropdown?sort=${sort}&order=${order}&religion_id=${religion_id}`
    return await RestApiService.get(path)
}

export const getQualificationDropdown = async (sort?: string, order?: string) => {
    const path = `misc/qualifications/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getOccupationDropdown = async (sort?: string, order?: string) => {
    const path = `misc/occupations/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getIncomeSlabDropdown = async (sort?: string, order?: string) => {
    const path = `misc/income-slabs/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getAreaDropdown = async (sort?: string, order?: string) => {
    const path = `location/areas/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const uploadImage = async (data: any) => {
    const path = 'common/upload/image'
    return await RestApiService.upload(path, data)
}

export const createRegistration = async (data: any) => {
    const path = 'registration/student-registrations'
    return await RestApiService.post(path, data)
}

export const getReceiptDetails = async (id: number | string) => {
    const path = `transaction/transactions/verify-student-registrations-receipt?receipt_id=${id}`
    return await RestApiService.get(path)
}

export const patchReceiptDetails = async (id: number | string, data: any) => {
    const path = `transaction/transactions/${id}/student-registrations-confirm-receipt`
    return await RestApiService.put(path, data)
}

export const listRegistration = async (page: number, records: number, session?: number, filters?: object) => {
    const path = `registration/student-registrations?page=${page}&records=${records}&session_id=${session}` //&gender=${gender}&standard_id=${standard}&admission_type=${admissionType}&admission_status=${registrationStatus}`
    return await RestApiService.get(path, filters)
}

export const getRegistration = async (id: number) => {
    const path = `registration/student-registrations/${id}`
    return await RestApiService.get(path)
}

export const patchRegistration = async (id: string | number, data: any) => {
    const path = `registration/student-registrations/${id}`
    return await RestApiService.put(path, data)
}
/* Registration api end */

/* Admission api start */
export const getSessions = async (page: number, records: number) => {
    const path = `academic/sessions?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getRegularFeeReceiptDetails = async (id: string) => {
    const path = `transaction/transactions/verify-regular-fee-receipt?receipt_id=${id}`
    return await RestApiService.get(path)
}

export const createNewAdmission = async (data: any) => {
    const path = 'student/students'
    return await RestApiService.post(path, data)
}

export const listAdmission = async (page: number, records: number, sort: string, order: string, session_id: number, filters?: object) => {
    const path = `admission/admissions?page=${page}&records=${records}&sort=${sort}&order=${order}&session_id=${session_id}`
    return await RestApiService.get(path, filters)
}

export const getStandardSubject = async (id: number) => {
    const path = `academic/standards/${id}`
    return await RestApiService.get(path)
}

export const getSubjectDropdownItems = async (sort?: string, order?: string) => {
    const path = `academic/subjects/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const setStudentStatus = async (id: number, data: any) => {
    const path = `student/students/${id}/change-state`
    return await RestApiService.put(path, data)
}

export const confirmAdmission = async (data: any) => {
    const path = 'admission/admissions'
    return await RestApiService.post(path, data)
}

export const changeAdmissionStandard = async (id: number, data: any) => {
    const path = `/admission/admissions/${id}`
    return await RestApiService.put(path, data)
}

/* Admission api end */
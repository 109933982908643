import React from 'react'


const EmployeeList = React.lazy(() => import('./employee/list'))
const EmployeeCreate = React.lazy(() => import('./employee/create'))
const EmployeeEdit = React.lazy(() => import('./employee/edit'))

//Employee Edit Start
const EmployeePersonalTab = React.lazy(() => import('./employee/components/profile'))
const EmployeeEmploymentTab = React.lazy(() => import('./employee/components/employment'))
const EmployeeFinancialTab = React.lazy(() => import('./employee/components/financial'))
const EmployeeQualificationTab = React.lazy(() => import('./employee/components/qualification'))
const EmployeeExperienceTab = React.lazy(()=>import('./employee/components/experience'))
const EmployeeDocumentTab = React.lazy(()=>import('./employee/components/document'))
//Employee Edit End

//Employee Access Start
const EmployeeAccess = React.lazy(()=>import('./employee/modal/access'))
//Employee Access End

export {
    EmployeeList,
    EmployeeCreate,
    EmployeeEdit,
    //Employee Edit Start
    EmployeePersonalTab,
    EmployeeEmploymentTab,
    EmployeeFinancialTab,
    EmployeeQualificationTab,
    EmployeeExperienceTab,
    EmployeeDocumentTab,
    //Employee Edit End

    //Employee Access Start
    EmployeeAccess
    //Employee Access End
}
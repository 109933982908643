import React from 'react'

const FeeTypeList = React.lazy(()=>import('./fee_type/list'))
const FeeTypeCreate = React.lazy(()=>import('./fee_type/modals/createFeeType'))
const FeeTypeEdit = React.lazy(()=>import('./fee_type/modals/editFeeType'))
const FeeTypeOptionBox = React.lazy(()=>import('./fee_type/modals/options'))

const FeeRateList = React.lazy(()=>import('./fee_rate/list'))
const FeeRateCreate = React.lazy(()=>import('./fee_rate/modals/feeRateCreate'))
const FeeRateUpdate = React.lazy(()=>import('./fee_rate/modals/feeRateUpdate'))

const FeeLateList = React.lazy(()=>import('./fee_late/list'))
const FeeLateCreate = React.lazy(()=>import('./fee_late/modals/createFeeLate'))
const FeeLateEdit = React.lazy(()=>import('./fee_late/modals/editFeeLate'))
const FeeLateOptionBox = React.lazy(()=>import('./fee_late/modals/options'))
const FeeStructureYearly = React.lazy(()=>import('./fee_rate/modals/feeStructureYearly'))


export {
    //start fee type
    FeeTypeList,
    FeeTypeCreate,
    FeeTypeEdit,
    FeeTypeOptionBox,
    // end fee type

    //start fee rate
    FeeRateList,
    FeeRateCreate,
    FeeRateUpdate,
    //end fee rate

    //start late fee
    FeeLateList,
    FeeLateCreate,
    FeeLateEdit,
    FeeLateOptionBox,
    FeeStructureYearly
    //end late fee
}
import React from 'react'

const SelectFeeType  = React.lazy(()=>import('./fee_collect/index'))
const SelectFeeDefaulter = React.lazy(()=>import('./fee_defaulter/index'))

const TransactionList = React.lazy(()=>import('./transaction/index'))
const TransactionListFilter = React.lazy(()=>import('./transaction/modals/filter'))

export {
    SelectFeeType,
    SelectFeeDefaulter,

    TransactionList,
    TransactionListFilter
}
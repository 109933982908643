import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getStudents, getStudent, getCasteDropdown, getReligionDropdown, getAreaDropdown, getIncomeSlabDropdown, getOccupationDropdown, getQualificationDropdown, getDocumentTypeDropdown, getSettlements, getSettlement, getTransferCertificates, getTransferCertificate, getStudentAttendances, getSessionDropdown, getStandardDropdown, getSectionDropdown, getStudentAcademicDropdownList, getStudentAttendance, getAcademics, getHouseDropdown, getSubjectDropdown, getSelectedAcademic, getStandardSubject } from './api'
import { MetaType, StudentResponseType, StudentTypeListResponseType, DropdownItemType, StudentSettlementsResponseType, StudentSettlementsListResponseType, StudentTransferCertificateResponseType, StudentTransferCertificateListResponseType, FilterType, StudentAttendanceResponseType, StudentAttendanceListResponseType, SessionResponseType, StudentAcademicListItem, StudentAcademicListDropDown, StudentAcademicListItems, StandardSubjectsResponseType, StudentStandardResponseType } from './type'
import { getReligionsForDropdown, getCastesForDropdown, getAreaForDropdown, getQualifcationsForDropdown, getIncomeSlabForDropdown, getOccupationForDropdown, getDocumentTypeForDropdown, getActiveSession, getStandardsForDropdown, getSectionsForDropdown, getSessionForDropdown, getHouseForDropdown, getSubjectForDropdown } from './helper'

export const StudentSlice = createSlice({
    name: 'student',
    initialState: {
        /* start common */
        loading: false as boolean,
        meta: {} as MetaType,
        religions: [] as DropdownItemType[],
        castes: [] as DropdownItemType[],
        areas: [] as DropdownItemType[],
        qualifications: [] as DropdownItemType[],
        occupations: [] as DropdownItemType[],
        incomes: [] as DropdownItemType[],
        documentType: [] as DropdownItemType[],
        filter: {} as FilterType,
        activeSession: {} as SessionResponseType,
        standardItems: [] as DropdownItemType[],
        sectionItems: [] as DropdownItemType[],
        sessionDropdownItems: [] as DropdownItemType[],
        houseDropdownItems: [] as DropdownItemType[],
        subjectDropdownItems: [] as DropdownItemType[],
        /* start common */

        /* start student */
        selectedStudentPage: 1 as number,
        students: [] as StudentResponseType[],
        student: {} as StudentResponseType,
        /* end student */

        /* start student academic */
        studentAcademicList: [] as StudentAcademicListItem[],
        /* end student academimc */

        /* Start Settlements */
        selectedSettlementPage: 1 as number,
        settlements: [] as StudentSettlementsResponseType[],
        settlement: {} as StudentSettlementsResponseType,
        /* End Settlements */

        /* Start Student Transfer Certificate */
        selecetedTransferCertificatePage: 1 as number,
        transferCertificates: [] as StudentTransferCertificateResponseType[],
        transferCertificate: {} as StudentTransferCertificateResponseType,
        /* End Student Transfer Certificate */

        /* Start Student Attendance */
        selectedAttendancePage: 1 as number,
        attendances: [] as StudentAttendanceResponseType[],
        attendance: {} as StudentAttendanceResponseType,
        /* End Student Attendance */

        /* Start Student Academics */
        selectedAcademicPage: 1 as number,
        academics: [] as StudentAcademicListItem[],
        academic: {} as StudentAcademicListItem,
        standardSubject: {} as StandardSubjectsResponseType
        /* End Student Academics */
    },
    reducers: {
        /* start common */
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setMeta: (state, action: PayloadAction<MetaType>) => {
            state.meta = action.payload
        },
        setReligions: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.religions = action.payload
        },
        setCastes: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.castes = action.payload
        },
        setAreas: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.areas = action.payload
        },
        setQualifications: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.qualifications = action.payload
        },
        setOccupations: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.occupations = action.payload
        },
        setIncomes: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.incomes = action.payload
        },
        setDocumentType: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.documentType = action.payload
        },
        setFilter: (state, action: PayloadAction<FilterType>) => {
            state.filter = action.payload
        },
        setActiveSession: (state, action: PayloadAction<SessionResponseType[]>) => {
            state.activeSession = getActiveSession(action.payload) as SessionResponseType
        },
        setStandardDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.standardItems = action.payload
        },
        setSectionDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.sectionItems = action.payload
        },
        setSessionsDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.sessionDropdownItems = action.payload
        },
        setHousesDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.houseDropdownItems = action.payload
        },
        setSubjectsDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.subjectDropdownItems = action.payload
        },
        /* end common */

        /* start student */
        setSelectedStudentPage: (state, action: PayloadAction<number>) => {
            state.selectedStudentPage = action.payload
        },
        setStudents: (state, action: PayloadAction<StudentResponseType[]>) => {
            state.students = action.payload
        },
        setStudent: (state, action: PayloadAction<StudentResponseType>) => {
            state.student = action.payload
        },
        /* end student */

        /* Start Settlements */
        setSelectedSettlementPage: (state, action: PayloadAction<number>) => {
            state.selectedSettlementPage = action.payload
        },
        setSettlements: (state, action: PayloadAction<StudentSettlementsResponseType[]>) => {
            state.settlements = action.payload
        },
        setSettlement: (state, action: PayloadAction<StudentSettlementsResponseType>) => {
            state.settlement = action.payload
        },
        /* End Settlements */

        /* Start Transfer Certificates */
        setSelectedTransferCertificatePage: (state, action: PayloadAction<number>) => {
            state.selecetedTransferCertificatePage = action.payload
        },
        setTransferCertificates: (state, action: PayloadAction<StudentTransferCertificateResponseType[]>) => {
            state.transferCertificates = action.payload
        },
        setTransferCertificate: (state, action: PayloadAction<StudentTransferCertificateResponseType>) => {
            state.transferCertificate = action.payload
        },
        /* End Transfer Certificates */

        /* Start Attendance */
        setSelectedAttendancePage: (state, action: PayloadAction<number>) => {
            state.selectedAttendancePage = action.payload
        },
        setAttendances: (state, action: PayloadAction<StudentAttendanceResponseType[]>) => {
            state.attendances = action.payload
        },
        setAttendance: (state, action: PayloadAction<StudentAttendanceResponseType>) => {
            state.attendance = action.payload
        },
        /* End Attendance */

        /* start student academic */
        setStudentAcademicList: (state, action: PayloadAction<StudentAcademicListItem[]>) => {
            state.studentAcademicList = action.payload
        },
        setSelectedStudentAcademicPage: (state, action: PayloadAction<number>) => {
            state.selectedAcademicPage = action.payload
        },
        setAcademics: (state, action: PayloadAction<StudentAcademicListItem[]>) => {
            state.academics = action.payload
        },
        setAcademic: (state, action: PayloadAction<StudentAcademicListItem>) => {
            state.academic = action.payload
        },
        setStandardSubject: (state, action: PayloadAction<StandardSubjectsResponseType>) => {
            state.standardSubject = action.payload
        }
        /* end student academimc */
    }
})

export const {
    /* start comman */
    setLoading,
    setMeta,
    setReligions,
    setCastes,
    setAreas,
    setQualifications,
    setIncomes,
    setOccupations,
    setDocumentType,
    setFilter,
    setActiveSession,
    setStandardDropdownItems,
    setSectionDropdownItems,
    setSessionsDropdownItems,
    setHousesDropdownItems,
    setSubjectsDropdownItems,
    /* end comman */

    /* start student */
    setSelectedStudentPage,
    setStudents,
    setStudent,
    /* end student */

    /* Start Settlements */
    setSelectedSettlementPage,
    setSettlements,
    setSettlement,
    /* End Settlements */

    /* Start Transfer Certificate */
    setSelectedTransferCertificatePage,
    setTransferCertificates,
    setTransferCertificate,
    /* End Transfer Certificate */

    /* Start Attendance */
    setSelectedAttendancePage,
    setAttendances,
    setAttendance,
    /* End Attendance */

    /* start student academic */
    setStudentAcademicList,
    setSelectedStudentAcademicPage,
    setAcademics,
    setAcademic,
    setStandardSubject
    /* end student academimc */

} = StudentSlice.actions

/* Start Common */
export const loadReligions = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const religionsResponse = await getReligionDropdown()
    dispatch(setReligions(getReligionsForDropdown(religionsResponse.items)))
    dispatch(setLoading(false))
}

export const loadCastes = (id: number) => async (dispatch: any) => {/* Start Transfer Certificates */
    dispatch(setLoading(true))
    const castesResponse = await getCasteDropdown(undefined, undefined, id)
    dispatch(setCastes(getCastesForDropdown(castesResponse.items)))
    dispatch(setLoading(false))
}

export const loadAreas = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const areaResponse = await getAreaDropdown()
    dispatch(setAreas(getAreaForDropdown(areaResponse.items)))
    dispatch(setLoading(false))
}

export const loadQualifications = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const qualificationResponse = await getQualificationDropdown()
    dispatch(setQualifications(getQualifcationsForDropdown(qualificationResponse.items)))
    dispatch(setLoading(false))
}

export const loadOccupations = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const occupationResponse = await getOccupationDropdown()
    dispatch(setOccupations(getOccupationForDropdown(occupationResponse.items)))
    dispatch(setLoading(false))
}

export const loadIncomes = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const incomesResponse = await getIncomeSlabDropdown()
    dispatch(setIncomes(getIncomeSlabForDropdown(incomesResponse.items)))
    dispatch(setLoading(false))
}

export const loadDocumentTypes = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const documentTypeResponse = await getDocumentTypeDropdown()
    dispatch(setDocumentType(getDocumentTypeForDropdown(documentTypeResponse.items)))
    dispatch(setLoading(false))
}

export const loadStandardDropdownItems = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const standardResponseType = await getStandardDropdown()
    dispatch(setStandardDropdownItems(getStandardsForDropdown(standardResponseType.items)))
    dispatch(setLoading(false))
}

export const loadSectionDropdownItems = (sort?: string, order?: string, sessionId?: number, standardId?: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const sectionResponseType = await getSectionDropdown(sort, order, sessionId, standardId)
    dispatch(setSectionDropdownItems(getSectionsForDropdown(sectionResponseType.items)))
    dispatch(setLoading(false))
}

export const loadActiveSession = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const sessionResponse = await getSessionDropdown('start', 'DESC')
    dispatch(setActiveSession(sessionResponse.items))
    dispatch(setSessionsDropdownItems(getSessionForDropdown(sessionResponse.items)))
    dispatch(setLoading(false))
}

export const loadHousesDropdownItems = (sort?: string, order?: string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const houseResponse = await getHouseDropdown(sort, order)
    dispatch(setHousesDropdownItems(getHouseForDropdown(houseResponse.items)))
    dispatch(setLoading(false))
}

export const loadSubjectsDropdownItems = (sort?: string, order?: string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const subjectResponse = await getSubjectDropdown(sort, order)
    dispatch(setSubjectsDropdownItems(getSubjectForDropdown(subjectResponse.items)))
    dispatch(setLoading(false))
}
/* end comman */

/* start student */
export const loadStudents = (page: number, records: number, filters?: object) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StudentTypeListResponseType = await getStudents(page, records, filters)
    dispatch(setStudents(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadStudent = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StudentResponseType = await getStudent(id)
    dispatch(setStudent(response))
    dispatch(setLoading(false))
}
/* end student */

/* Start Settlements */
export const loadSettlements = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StudentSettlementsListResponseType = await getSettlements(page, records)
    dispatch(setSettlements(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadSettlement = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StudentSettlementsResponseType = await getSettlement(id)
    dispatch(setSettlement(response))
    dispatch(setLoading(false))
}
/* End Settlements */

/* Start Transfer Certificates */
export const loadTransferCertificates = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StudentTransferCertificateListResponseType = await getTransferCertificates(page, records)
    dispatch(setTransferCertificates(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadTransferCertificate = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StudentTransferCertificateResponseType = await getTransferCertificate(id)
    dispatch(setTransferCertificate(response))
    dispatch(setLoading(false))
}
/* End Transfer Certificates */

/* Start Attendance */
export const loadAttendances = (page?: number, records?: number, sort?: string, order?: string, sessionId?: number, filter?: object) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StudentAttendanceListResponseType = await getStudentAttendances(page, records, sort, order, sessionId, filter)
    dispatch(setAttendances(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}
export const loadAttendance = (id: string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StudentAttendanceResponseType = await getStudentAttendance(id)
    dispatch(setAttendance(response))
    dispatch(setLoading(false))
}
/* End Attendance */

/* Start Student Academics */
export const loadStudentAcademicsDropdown = (sessionId: number, sort?: string, order?: string, filter?: object) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StudentAcademicListDropDown = await getStudentAcademicDropdownList(sessionId, sort, order, filter)
    dispatch(setStudentAcademicList(response.items))
    dispatch(setLoading(false))
}

export const loadStudentAcademics = (page?: number, records?: number, sort?: string, order?: string, filter?: object) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StudentAcademicListItems = await getAcademics(page, records, sort, order, filter)
    dispatch(setAcademics(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadStudentAcademic = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StudentAcademicListItem = await getSelectedAcademic(id)
    dispatch(setAcademic(response))
    dispatch(setLoading(false))
}

export const loadStandardSubject = (id: number, activeSessionId: number) => async (disaptch: any) => {
    disaptch(setLoading(true))
    const response: StudentStandardResponseType = await getStandardSubject(id)
    const subjectData: StandardSubjectsResponseType = response?.standard_subject.filter((data) => data.session_id === activeSessionId)[0]
    disaptch(setStandardSubject(subjectData))
    disaptch(setLoading(false))
}
/* End Student Academics */

/* start comman */
export const selectLoading = (state: any) => state.student.loading
export const selectMeta = (state: any) => state.student.meta
export const selectReligions = (state: any) => state.student.religions
export const selectCastes = (state: any) => state.student.castes
export const selectAreas = (state: any) => state.student.areas
export const selectQualifications = (state: any) => state.student.qualifications
export const selectOccupations = (state: any) => state.student.occupations
export const selectIncomes = (state: any) => state.student.incomes
export const selectDocumentType = (state: any) => state.student.documentType
export const selectFilter = (state: any) => state.student.filter
export const selectStandards = (state: any) => state.student.standardItems
export const selectSections = (state: any) => state.student.sectionItems
export const selectActiveSession = (state: any) => state.student.activeSession
export const selectSessions = (state: any) => state.student.sessionDropdownItems
export const selectHouse = (state: any) => state.student.houseDropdownItems
export const selectSubjects = (state: any) => state.student.subjectDropdownItems
/* end comman */

/* start student */
export const selectSelectedStudentPage = (state: any) => state.student.selectedStudentPage
export const selectStudents = (state: any) => state.student.students
export const selectStudent = (state: any) => state.student.student
/* end student */

/* Start Settlements */
export const selectSelectedSettlementPage = (state: any) => state.student.selectedSettlementPage
export const selectSettlements = (state: any) => state.student.settlements
export const selectSettlement = (state: any) => state.student.settlement
/* End Settlements */

/* Start Transfer Certificate */
export const selectSelectedTransferCertificatePage = (state: any) => state.student.selecetedTransferCertificatePage
export const selectTransferCertificates = (state: any) => state.student.transferCertificates
export const selectTransferCertificate = (state: any) => state.student.transferCertificate
/* End Transfer Certificate */

/* Start Attendance */
export const selectSelectedAttendancePage = (state: any) => state.student.selectedAttendancePage
export const selectAttendances = (state: any) => state.student.attendances
export const selectAttendance = (state: any) => state.student.attendance
/* End Attendance */

/* Start Student Academics */
export const selectStudentAcademicList = (state: any) => state.student.studentAcademicList
export const selectSelectedAcademicPage = (state: any) => state.student.selectedAcademicPage
export const selectAcademics = (state: any) => state.student.academics
export const selectSelectedAcademic = (state: any) => state.student.academic
/* End Student Academics */

export default StudentSlice.reducer
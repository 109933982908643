import React from 'react'

const SessionList = React.lazy(() => import('./session/list'))
const SessionCreate = React.lazy(() => import('./session/modals/createSession'))
const SessionEdit = React.lazy(() => import('./session/modals/editSession'))
const SessionClose = React.lazy(() => import('./session/modals/closeSession'))
const SessionOptionBox = React.lazy(() => import('./session/modals/options'))

const StandardList = React.lazy(() => import('./standard/list'))
const StandardCreate = React.lazy(() => import('./standard/modals/createStandard'))
const StandardEdit = React.lazy(() => import('./standard/modals/editStandard'))
const StandardOptionBox = React.lazy(() => import('./standard/modals/options'))
const StandardSubject = React.lazy(() => import('./standard/modals/allocateSubject'))
const StandardSubjectList = React.lazy(() => import('./standard/modals/subjectList'))
const StandardSection = React.lazy(() => import('./standard/modals/allocateSection'))

const SectionList = React.lazy(() => import('./section/list'))
const SectionCreate = React.lazy(() => import('./section/modals/createSection'))
const SectionEdit = React.lazy(() => import('./section/modals/editSection'))
const SectionOptionBox = React.lazy(() => import('./section/modals/options'))

const SeatList = React.lazy(() => import('./seat/list'))
const SeatCreate = React.lazy(() => import('./seat/modals/createSeat'))
const SeatEdit = React.lazy(() => import('./seat/modals/editSeat'))
const SeatOptionBox = React.lazy(() => import('./seat/modals/options'))
const SeatFilter = React.lazy(() => import('./seat/modals/filter'))

const SubjectList = React.lazy(() => import('./subject/list'))
const SubjectCreate = React.lazy(() => import('./subject/modals/createSubject'))
const SubjectEdit = React.lazy(() => import('./subject/modals/editSubject'))
const SubjectOptionBox = React.lazy(() => import('./subject/modals/options'))

const StandardTimeTable = React.lazy(() => import('./timetable/index'))
const TimetableCreate = React.lazy(() => import('./timetable/modals/create'))
const TeacherTimetable = React.lazy(() => import('./timetable/techerView'))
const TeacherTimetableFilter = React.lazy(() => import('./timetable/modals/scheduleFilter'))

const HouseList = React.lazy(() => import('./house/list'))
const HouseCreate = React.lazy(() => import('./house/modals/createHouse'))
const HouseUpdate = React.lazy(() => import('./house/modals/editHouse'))
const HouseOption = React.lazy(() => import('./house/modals/options'))

export {
    /* start standard */
    StandardList,
    StandardCreate,
    StandardEdit,
    StandardOptionBox,
    StandardSubject,
    StandardSubjectList,
    StandardSection,
    /* end standard */

    /* start session */
    SessionList,
    SessionCreate,
    SessionEdit,
    SessionClose,
    SessionOptionBox,
    /* end session */

    /* start section */
    SectionList,
    SectionCreate,
    SectionEdit,
    SectionOptionBox,
    /* ens section */

    /* start seat */
    SeatList,
    SeatCreate,
    SeatEdit,
    SeatOptionBox,
    SeatFilter,
    /* end seat */

    /* start subject */
    SubjectList,
    SubjectCreate,
    SubjectEdit,
    SubjectOptionBox,
    /* ens subject */

    /* start timetable */
    StandardTimeTable,
    TimetableCreate,
    TeacherTimetable,
    TeacherTimetableFilter,
    /* end timetable */

    /* start house */
    HouseList,
    HouseCreate,
    HouseUpdate,
    HouseOption
    /* end house */
}
export const frequencyItemList = [
    {id: 'one-time', title:'One Time'},
    {id: 'every-month', title:'Every Month'},
    {id: 'every-two-months', title:'Every Two Months'},
    {id: 'every-three-months', title:'Every Three Months'},
    {id: 'every-four-months', title:'Every Four Months'},
    {id: 'every-six-months', title:'Every Six Months'},
    {id: 'every-year', title:'Every Year'}
]

export const feeTypeApplicableList = [
    {id: false, title: 'No'},
    {id: true, title: 'Yes'}
]
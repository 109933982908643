import { ToWords } from 'to-words'

const months = [
    {id: 1, key: '01', month: 'January'},
    {id: 2, key: '02', month: 'Feburary'},
    {id: 3, key: '03', month: 'March'},
    {id: 4, key: '04', month: 'April'},
    {id: 5, key: '05', month: 'May'},
    {id: 6, key: '06', month: 'June'},
    {id: 7, key: '07', month: 'July'},
    {id: 8, key: '08', month: 'August'},
    {id: 9, key: '09', month: 'September'},
    {id: 10, key: '10', month: 'October'},
    {id: 11, key: '11', month: 'November'},
    {id: 12, key: '12', month: 'December'}
]

const days = [
    {id: 1, key: '01', day: 'First'},
    {id: 2, key: '02', day: 'Second'},
    {id: 3, key: '03', day: 'Third'},
    {id: 4, key: '04', day: 'Forth'},
    {id: 5, key: '05', day: 'Fifth'},
    {id: 6, key: '06', day: 'Sixth'},
    {id: 7, key: '07', day: 'Seventh'},
    {id: 8, key: '08', day: 'Eigth'},
    {id: 9, key: '09', day: 'Ninth'},
    {id: 10, key: '10', day: 'Tenth'},
    {id: 11, key: '11', day: 'Eleventh'},
    {id: 12, key: '12', day: 'Twelfth'},
    {id: 13, key: '13', day: 'Thirteenth'},
    {id: 14, key: '14', day: 'Forteenth'},
    {id: 15, key: '15', day: 'Fifteenth'},
    {id: 16, key: '16', day: 'Sixteenth'},
    {id: 17, key: '17', day: 'Seveteenth'},
    {id: 18, key: '18', day: 'Eigthteenth'},
    {id: 19, key: '19', day: 'Nineteenth'},
    {id: 20, key: '20', day: 'Twentieth'},
    {id: 21, key: '21', day: 'Twenty-first'},
    {id: 22, key: '22', day: 'Twenty-second'},
    {id: 23, key: '23', day: 'Twenty-third'},
    {id: 24, key: '24', day: 'Twenty-forth'},
    {id: 25, key: '25', day: 'Twenty-fifth'},
    {id: 26, key: '26', day: 'Twenty-sixth'},
    {id: 27, key: '27', day: 'Twenty-seventh'},
    {id: 28, key: '28', day: 'Twenty-eigth'},
    {id: 29, key: '29', day: 'Twenty-ninth'},
    {id: 30, key: '30', day: 'Thirtieth'},
    {id: 31, key: '31', day: 'Thirty-first'},
]

const getMonth = (month: string) => {
    return months.find(m=>m.key===month)?.month||''
}

const getDay = (day: string) => {
    return days.find(d=>d.key===day)?.day||''
}

const getYear = (year: string) => {
    const toWords = new ToWords({
        localeCode: 'en-IN',
        converterOptions: {
            currency: false,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: true,
        }
    })
    const yearlength = year.length
    if(yearlength === 4){
        const p1 = year.substr(0,2)
        const p2 = year.substr(2,2)
        const y1 = toWords.convert(parseInt(p1))
        const y2 = toWords.convert(parseInt(p2))
        return `${y1} ${y2}`
    }
    return ''
}
/** get date in words */
export const dateInWords = (date: string) => {
    if(date){
        const dateparts = date.split('-')
        const year = getYear(dateparts[0])
        const month = getMonth(dateparts[1])
        const day = getDay(dateparts[2])
        return `${month} ${day}, ${year.toLowerCase()}`
    }
    return '--'
}
/** end */
import { useParams } from 'react-router-dom'
import React, { Suspense } from 'react'
import { StandardList, SessionList, SectionList, SeatList, SubjectList, StandardTimeTable, TeacherTimetable, HouseList } from '../features/academic'
import { GlobalLayout, LoaderFour } from '../features/layout'

const AcademicPage = () => {
    const { type } = useParams() as any
    return (
        <Suspense fallback={<LoaderFour />}>
            <GlobalLayout>
                {type === 'standards' && <StandardList />}
                {type === 'sessions' && <SessionList />}
                {type === 'sections' && <SectionList />}
                {type === 'seats' && <SeatList />}
                {type === 'subjects' && <SubjectList />}
                {type === 'timetable' && <StandardTimeTable />}
                {type === 'schedule' && <TeacherTimetable />}
                {type === 'houses' && <HouseList />}
            </GlobalLayout>
        </Suspense>
    )
}

export default AcademicPage
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getSeats, getSelectedSeat, getSections, getSelectedSection, getSectionDropdownItems, getSessions, getSelectedSession, getSessionDropdownItems, getStandards, getSelectedStandard, getStandardDropdownItems, getSubjects, getSelectedSubject, getSubjectDropdownItems, getTimetables, getEmployeeDropdownItems, getStandardSubjectDropdownItems, getTimetable, getHouses, getSelectedHouse, getHouseDropdownItems } from './api'
import { getActiveSession, getHousesForDropdown, getSectionsForDropdown, getSessionsForDropdown, getStandardsForDropdown, getStandardSubjectForDropdown, getSubjectsForDropdown, getTeachersForDropdown } from './helper'
import { DropdownItemType, EmployeeResponseType, FilterType, HouseListResponseType, HouseResponseType, MetaType, SeatListResponseType, SeatResponseType, SectionListResponseType, SectionResponseType, SessionListResponseType, SessionResponseType, StandardListResponseType, StandardResponseType, StandardSubjectsResponseType, SubjectListResponseType, SubjectResponseType, TimetabelListResponseType, TimetableResponseType } from './type'

export const AcademicSlice = createSlice({
    name: 'academic',
    initialState: {
        /*start common*/
        loading: false as boolean,
        meta: {} as MetaType,
        activeSession: {} as SessionResponseType,
        filter: {} as FilterType,
        /*end common*/

        /*start standard*/
        selectedStandardPage: 1 as number,
        standardDropdownItems: [] as DropdownItemType[],
        standards: [] as StandardResponseType[],
        selectedStandard: {} as StandardResponseType,
        standardSubjects: {} as StandardSubjectsResponseType,
        standardSubjectDropdownItems: [] as DropdownItemType[],
        /*start standard*/

        /*start session*/
        selectedSessionPage: 1 as number,
        sessions: [] as SessionResponseType[],
        selectedSession: {} as SessionResponseType,
        sessionDropdownItems: [] as DropdownItemType[],
        /*end session*/

        /*start section*/
        sections: [] as SectionResponseType[],
        selectedSection: {} as SectionResponseType,
        sectionDropdownItems: [] as DropdownItemType[],
        selectedSectionPage: 1 as number,
        /*end section*/

        /*start seats*/
        seats: [] as SeatResponseType[],
        selectedSeat: {} as SeatResponseType,
        selectedSeatPage: 1 as number,
        /*end seats*/

        /*start subject*/
        subjects: [] as SubjectResponseType[],
        selectedSubject: {} as SubjectResponseType,
        subjectDropdownItems: [] as DropdownItemType[],
        selectedSubjectPage: 1 as number,
        /*end subject*/

        /* start timetable */
        timetables: [] as TimetableResponseType[],
        timetable: {} as TimetableResponseType,
        teachersDropdownItems: [] as DropdownItemType[],
        /* end timetable */

        /*start house*/
        houses: [] as HouseResponseType[],
        selectedHouse: {} as HouseResponseType,
        houseDropdownItems: [] as DropdownItemType[],
        selectedHousePage: 1 as number,
        /*end house*/
    },
    reducers: {
        /*start common*/
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setMeta: (state, action: PayloadAction<MetaType>) => {
            state.meta = action.payload
        },
        setActiveSession: (state, action: PayloadAction<SessionResponseType[]>) => {
            state.activeSession = getActiveSession(action.payload) as SessionResponseType
        },
        setFilter: (state, action: PayloadAction<FilterType>) => {
            state.filter = action.payload
        },
        /*end common*/

        /*start session*/
        setSelectedSessionPage: (state, action: PayloadAction<number>) => {
            state.selectedSessionPage = action.payload
        },
        setSessions: (state, action: PayloadAction<SessionResponseType[]>) => {
            state.sessions = action.payload
        },
        setSelectedSession: (state, action: PayloadAction<SessionResponseType>) => {
            state.selectedSession = action.payload
        },
        setSessionDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.sessionDropdownItems = action.payload
        },
        /*end session*/

        /*start section*/
        setSelectedSectionPage: (state, action: PayloadAction<number>) => {
            state.selectedSectionPage = action.payload
        },
        setSections: (state, action: PayloadAction<SectionResponseType[]>) => {
            state.sections = action.payload
        },
        setSelectedSection: (state, action: PayloadAction<SectionResponseType>) => {
            state.selectedSection = action.payload
        },
        setSectionDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.sectionDropdownItems = action.payload
        },
        /*end section*/

        /*start standard*/
        setSelectedStandardPage: (state, action: PayloadAction<number>) => {
            state.selectedStandardPage = action.payload
        },
        setStandards: (state, action: PayloadAction<StandardResponseType[]>) => {
            state.standards = action.payload
        },
        setSelectedStandard: (state, action: PayloadAction<StandardResponseType>) => {
            state.selectedStandard = action.payload
        },
        setStandardDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.standardDropdownItems = action.payload
        },
        setStandardSubjects: (state, action: PayloadAction<StandardSubjectsResponseType>) => {
            state.standardSubjects = action.payload
        },
        setStandardSubjectDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.standardSubjectDropdownItems = action.payload
        },
        /*end standard*/

        /*start seat*/
        setSelectedSeatPage: (state, action: PayloadAction<number>) => {
            state.selectedSeatPage = action.payload
        },
        setSeats: (state, action: PayloadAction<SeatResponseType[]>) => {
            state.seats = action.payload
        },
        setSelectedSeat: (state, action: PayloadAction<SeatResponseType>) => {
            state.selectedSeat = action.payload
        },
        /*end seat*/

        /*start subject*/
        setSelectedSubjectPage: (state, action: PayloadAction<number>) => {
            state.selectedSubjectPage = action.payload
        },
        setSubjects: (state, action: PayloadAction<SubjectResponseType[]>) => {
            state.subjects = action.payload
        },
        setSelectedSubject: (state, action: PayloadAction<SubjectResponseType>) => {
            state.selectedSubject = action.payload
        },
        setSubjectDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.subjectDropdownItems = action.payload
        },
        /*end subject*/

        /* start timetable */
        setTimetables: (state, action: PayloadAction<TimetableResponseType[]>) => {
            state.timetables = action.payload
        },
        setTimetable: (state, action: PayloadAction<TimetableResponseType>) => {
            state.timetable = action.payload
        },
        setTeacherDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.teachersDropdownItems = action.payload
        },
        /* end timetable */

        /*start house*/
        setSelectedHousePage: (state, action: PayloadAction<number>) => {
            state.selectedHousePage = action.payload
        },
        setHouses: (state, action: PayloadAction<HouseResponseType[]>) => {
            state.houses = action.payload
        },
        setSelectedHouse: (state, action: PayloadAction<HouseResponseType>) => {
            state.selectedHouse = action.payload
        },
        setHouseDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.houseDropdownItems = action.payload
        }
        /*end subject*/
    }
})

export const {
    /*start common*/
    setLoading,
    setMeta,
    setActiveSession,
    setFilter,
    /* end common*/

    /*start session*/
    setSelectedSessionPage,
    setSessions,
    setSelectedSession,
    setSessionDropdownItems,
    /*end session*/

    /*start standard*/
    setSelectedStandardPage,
    setStandards,
    setSelectedStandard,
    setStandardDropdownItems,
    setStandardSubjects,
    setStandardSubjectDropdownItems,
    /*end standard*/

    /*start section*/
    setSelectedSectionPage,
    setSections,
    setSelectedSection,
    setSectionDropdownItems,
    /*end section*/

    /*start seat*/
    setSelectedSeatPage,
    setSeats,
    setSelectedSeat,
    /*end seat*/

    /*start subject*/
    setSelectedSubjectPage,
    setSubjects,
    setSelectedSubject,
    setSubjectDropdownItems,
    /*end subject*/

    /* start timetable */
    setTimetables,
    setTimetable,
    setTeacherDropdownItems,
    /* end timetable */

    /* start house */
    setSelectedHousePage,
    setHouses,
    setSelectedHouse,
    setHouseDropdownItems
    /* end house */
} = AcademicSlice.actions

/*start session*/
export const loadSessions = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: SessionListResponseType = await getSessions(page, records)
    dispatch(setSessions(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}
export const loadSelectedSession = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: SessionResponseType = await getSelectedSession(id)
    dispatch(setSelectedSession(response))
    dispatch(setLoading(false))
}
export const loadSessionDropdownItems = (sort?: string, order?: string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: { items: SessionResponseType[] } = await getSessionDropdownItems(sort, order)
    const dropdownItems = getSessionsForDropdown(response.items)
    dispatch(setSessionDropdownItems(dropdownItems))
    dispatch(setActiveSession(response.items))
    dispatch(setLoading(false))
}
/*end session*/

/*start standard*/
export const loadStandards = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StandardListResponseType = await getStandards(page, records)
    dispatch(setStandards(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}
export const loadSelectedStandard = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: StandardResponseType = await getSelectedStandard(id)
    dispatch(setSelectedStandard(response))
    dispatch(setLoading(false))
}
export const loadStandardDropdownItems = (sort?: string, order?: string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: { items: StandardResponseType[] } = await getStandardDropdownItems(sort, order)
    const dropdownItems = getStandardsForDropdown(response.items)
    dispatch(setStandardDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}
export const loadStandardSubject = (data: StandardSubjectsResponseType) => async (dispatch: any) => {
    dispatch(setLoading(true))
    dispatch(setStandardSubjects(data))
    dispatch(setLoading(false))
}
export const loadStandardSubjectDropdownItems = (sessionId: number, standardId: number, sort?: string, order?: string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: { items: SubjectResponseType[] } = await getStandardSubjectDropdownItems(sessionId, standardId, sort, order)
    const dropdownItems = getStandardSubjectForDropdown(response.items)
    dispatch(setStandardSubjectDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}
/*end standard*/

/*start section*/
export const loadSections = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: SectionListResponseType = await getSections(page, records)
    dispatch(setSections(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}
export const loadSelectedSection = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: SectionResponseType = await getSelectedSection(id)
    dispatch(setSelectedSection(response))
    dispatch(setLoading(false))
}
export const loadSectionDropdownItems = (sort?: string, order?: string, sessionId?: number, standardId?: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: { items: SectionResponseType[] } = await getSectionDropdownItems(sort, order, sessionId, standardId)
    const dropdownItems = getSectionsForDropdown(response.items)
    dispatch(setSectionDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}
/*end section*/

/*start seats*/
export const loadSeats = (page: number, records: number, session_id?: number, standard_id?: number, filters?: object) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: SeatListResponseType = await getSeats(page, records, session_id, standard_id, filters)
    dispatch(setSeats(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}
export const loadSelectedSeat = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: SeatResponseType = await getSelectedSeat(id)
    dispatch(setSelectedSeat(response))
    dispatch(setLoading(false))
}
/*end seats*/

/*start subject*/
export const loadSubjects = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: SubjectListResponseType = await getSubjects(page, records)
    dispatch(setSubjects(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}
export const loadSelectedSubject = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: SubjectResponseType = await getSelectedSubject(id)
    dispatch(setSelectedSubject(response))
    dispatch(setLoading(false))
}
export const loadSubjectDropdownItems = (sort?: string, order?: string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: { items: SubjectResponseType[] } = await getSubjectDropdownItems(sort, order)
    const dropdownItems = getSubjectsForDropdown(response.items)
    dispatch(setSubjectDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}
/*end subject*/

/*start timetable */
export const loadTimetables = (page?: number, records?: number, sort?: string, order?: number, day?: number, slot?: number, session_id?: number, standard_id?: number, section_id?: number, subject_id?: number, employee_id?: number, filter?: object) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: TimetabelListResponseType = await getTimetables(page, records, sort, order, day, slot, session_id, standard_id, section_id, subject_id, employee_id, filter)
    dispatch(setTimetables(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadTimetable = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: TimetableResponseType = await getTimetable(id)
    dispatch(setTimetable(response))
    dispatch(setLoading(false))
}

export const loadTeacherDropdownItems = (sort?: string, order?: string, departmentId?: number, standardId?: number, subjectId?: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: { items: EmployeeResponseType[] } = await getEmployeeDropdownItems(sort, order, departmentId, standardId, subjectId)
    const dropdownItems = getTeachersForDropdown(response.items)
    dispatch(setTeacherDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}
/*end timetable */

/*start subject*/
export const loadHouses = (page: number, record: number, sort?: string, order?: string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: HouseListResponseType = await getHouses(page, record, sort, order)
    dispatch(setHouses(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}
export const loadSelectedHouse = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: HouseResponseType = await getSelectedHouse(id)
    dispatch(setSelectedHouse(response))
    dispatch(setLoading(false))
}
export const loadHouseDropdownItems = (sort?: string, order?: string) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: { items: HouseResponseType[] } = await getHouseDropdownItems(sort, order)
    const dropdownItems = getHousesForDropdown(response.items)
    dispatch(setHouseDropdownItems(dropdownItems))
    dispatch(setLoading(false))
}
/*end subject*/


/*start common*/
export const selectLoading = (state: any) => state.academic.loading
export const selectMeta = (state: any) => state.academic.meta
export const selectActiveSession = (state: any) => state.academic.activeSession
export const selectFilter = (state: any) => state.academic.filter
/*end common*/

/*start session*/
export const selectSelectedSessionPage = (state: any) => state.academic.selectedSessionPage
export const selectSessions = (state: any) => state.academic.sessions
export const selectSelectedSession = (state: any) => state.academic.selectedSession
export const selectSessionDropdownItems = (state: any) => state.academic.sessionDropdownItems
/*end session*/

/*start standard*/
export const selectSelectedStandardPage = (state: any) => state.academic.selectedStandardPage
export const selectStandards = (state: any) => state.academic.standards
export const selectSelectedStandard = (state: any) => state.academic.selectedStandard
export const selectStandardDropdownItems = (state: any) => state.academic.standardDropdownItems
export const selectStandardSubject = (state: any) => state.academic.standardSubjects
export const selectStandardSubjectDropdownItems = (state: any) => state.academic.standardSubjectDropdownItems
/*end standard*/

/*start section*/
export const selectSelectedSectionPage = (state: any) => state.academic.selectedSectionPage
export const selectSections = (state: any) => state.academic.sections
export const selectSelectedSection = (state: any) => state.academic.selectedSection
export const selectSectionDropdownItems = (state: any) => state.academic.sectionDropdownItems
/*end section*/

/*start seat*/
export const selectSeats = (state: any) => state.academic.seats
export const selectSelectedSeat = (state: any) => state.academic.selectedSeat
export const selectSelectedSeatPage = (state: any) => state.academic.selectedSeatPage
/*end seat*/

/*start subject*/
export const selectSelectedSubjectPage = (state: any) => state.academic.selectedSubjectPage
export const selectSubjects = (state: any) => state.academic.subjects
export const selectSelectedSubject = (state: any) => state.academic.selectedSubject
export const selectSubjectDropdownItems = (state: any) => state.academic.subjectDropdownItems
/*end subject*/

/*start timetable */
export const selectTimetables = (state: any) => state.academic.timetables
export const selectTimetable = (state: any) => state.academic.timetable
export const selectTeacherDropdownItems = (state: any) => state.academic.teachersDropdownItems
/*end timetable */

/*start house*/
export const selectSelectedHousePage = (state: any) => state.academic.selectedHousePage
export const selectHouses = (state: any) => state.academic.houses
export const selectSelectedHouse = (state: any) => state.academic.selectedHouse
export const selectHouseDropdownItems = (state: any) => state.academic.houseDropdownItems
/*end house*/

export default AcademicSlice.reducer
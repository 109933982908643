import React from 'react'

//Examination Start
const ExaminationList = React.lazy(() => import('./examinations/list'))
const ExaminationFilter = React.lazy(() => import('./examinations/modals/filter'))
const ExaminationEdit = React.lazy(() => import('./examinations/modals/edit'))
//Examination End

//Marks Start
const ExaminationMarksAdd = React.lazy(() => import('./examinations/modals/addMarks'))
const ExaminationMarksView = React.lazy(() => import('./examinations/modals/viewMarks'))
//Marks End

//Entrance Exam Start
const EntranceExamList = React.lazy(() => import('./entrance/index'))
const EntranceExamEdit = React.lazy(() => import('./entrance/modals/edit'))
const EntranceExamBulkSchedule = React.lazy(() => import('./entrance/modals/bulkSchedule'))
//Entrance Exam End

export {
    ExaminationList,
    ExaminationFilter,
    ExaminationEdit,
    ExaminationMarksAdd,
    ExaminationMarksView,
    EntranceExamList,
    EntranceExamEdit,
    EntranceExamBulkSchedule
}
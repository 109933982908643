import RestApiService from '../../service/RestApiService'

/* Start Common */
export const getStandardDropdown = async () => {
    const path = '/academic/standards/dropdown'
    return await RestApiService.get(path)
}

export const getSessionDropdown = async (sort?: string, order?: string) => {
    const path = `/academic/sessions/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}
/* End Common */

/* Fee Report Start */
export const getFeeSummary = async (id: number) => {
    const path = `/report/fee-report/fee-summary?session_id=${id}`
    return await RestApiService.get(path)
}

export const getDailyFee = async (page: number, records: number, filters: object) => {
    const path = `/report/fee-report/fee-daily?head=${'post-admission'}&page=${page}&records=${records}`
    return await RestApiService.get(path, filters)
}
/* Fee Report End */
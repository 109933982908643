import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getStandardDropdown, getSubjectDropdownItems, getTeacher, getTeachers, getSessionDropdown, getStandardSubject } from './api'
import { getStandardsForDropdown, getSubjectsForDropdown, getActiveSession } from './helper'
import { DropdownItemType, MetaType, TeacherListResponseType, TeacherResponseType, SessionResponseType, StandardSubjectsResponseType, StandardResponseType, FilterType } from './type'

export const StaffSlice = createSlice({
    name: 'staff',
    initialState: {
        /* Start Common */
        loading: false as boolean,
        meta: {} as MetaType,
        standards: [] as DropdownItemType[],
        subjects: [] as DropdownItemType[],
        activeSession: {} as SessionResponseType,
        filter: {} as FilterType,
        /* End Common */

        /* Start Teacher */
        selectedTeacherPage: 1 as number,
        teachers: [] as TeacherResponseType[],
        teacher: {} as TeacherResponseType,
        standardSubject: {} as StandardSubjectsResponseType
        /* End Teacher */
    },
    reducers: {
        /* Start Common */
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setMeta: (state, action: PayloadAction<MetaType>) => {
            state.meta = action.payload
        },
        setStandards: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.standards = action.payload
        },
        setSubjects: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.subjects = action.payload
        },
        setActiveSession: (state, action: PayloadAction<SessionResponseType[]>) => {
            state.activeSession = getActiveSession(action.payload) as SessionResponseType
        },
        setFilter: (state, action: PayloadAction<FilterType>) => {
            state.filter = action.payload
        },
        /* End Common */

        /* Start Teacher */
        setSelectedTeacherPage: (state, action: PayloadAction<number>) => {
            state.selectedTeacherPage = action.payload
        },
        setTeachers: (state, action: PayloadAction<TeacherResponseType[]>) => {
            state.teachers = action.payload
        },
        setTeacher: (state, action: PayloadAction<TeacherResponseType>) => {
            state.teacher = action.payload
        },
        setStandardSubject: (state, action: PayloadAction<StandardSubjectsResponseType>) => {
            state.standardSubject = action.payload
        }
        /* End Teacher */
    }
})

export const {
    /* Start Common */
    setLoading,
    setMeta,
    setStandards,
    setSubjects,
    setActiveSession,
    setFilter,
    /* End Common */

    /* Start Teacher */
    setSelectedTeacherPage,
    setTeachers,
    setTeacher,
    setStandardSubject
    /* End Teacher */
} = StaffSlice.actions

/*Start Common */
export const loadStandards = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const standardsResponse = await getStandardDropdown()
    dispatch(setStandards(getStandardsForDropdown(standardsResponse.items)))
    dispatch(setLoading(false))
}

export const loadSubjects = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response = await getSubjectDropdownItems()
    dispatch(setSubjects(getSubjectsForDropdown(response.items)))
    dispatch(setLoading(false))
}

export const loadActiveSession = () => async (dispatch: any) => {
    dispatch(setLoading(true))
    const sessionResponse = await getSessionDropdown('start', 'DESC')
    dispatch(setActiveSession(sessionResponse.items))
    dispatch(setLoading(false))
}
/*Start Common */

/* Start Teacher */
export const loadTeachers = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: TeacherListResponseType = await getTeachers(page, records)
    dispatch(setTeachers(response.list))
    dispatch(setMeta(response.meta))
    dispatch(setLoading(false))
}

export const loadTeacher = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const response: TeacherResponseType = await getTeacher(id)
    dispatch(setTeacher(response))
    dispatch(setLoading(false))
}

export const loadStandardSubject = (id?: number, activeSessionId?: number) => async (disaptch: any) => {
    disaptch(setLoading(true))
    const response: StandardResponseType = await getStandardSubject(id)
    const subjectData: StandardSubjectsResponseType = response?.standard_subject.filter((data) => data.session_id === activeSessionId)[0]
    disaptch(setStandardSubject(subjectData))
    disaptch(setLoading(false))
}
/* End Teacher */

/* Start Common */
export const selectLoading = (state: any) => state.staff.loading
export const selectMeta = (state: any) => state.staff.meta
export const selectStandards = (state: any) => state.staff.standards
export const selectSubjects = (state: any) => state.staff.subjects
export const selectActiveSession = (state: any) => state.staff.activeSession
export const selectFilter = (state: any) => state.staff.filter
/* End Common */

/* Start Teacher */
export const selectSelectedTeacherPage = (state: any) => state.staff.selectedTeacherPage
export const selectTeachers = (state: any) => state.staff.teachers
export const selectTeacher = (state: any) => state.staff.teacher
export const selectStandardSubject = (state: any) => state.staff.standardSubject
/* End Teacher */

export default StaffSlice.reducer
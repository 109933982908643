import { CFooter, CLink } from '@coreui/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectVersion } from '../../store'
import { Div, Span } from '../../styled'

const GlobalFooter = () => {
    const version = useSelector(selectVersion)
    return (
        <CFooter fixed={false}>
            <Div>
                <Span className={'ml-1'}><CLink href={'https://nanobits.in/'} target={'_blank'}>&copy; {new Date().getFullYear()} Nanobits Technologies Pvt. Ltd.</CLink></Span>
            </Div>
            <Div className={'mfs-auto'}>
                <Span className={'mr-1'}>
                    <CLink href={'https://vidhyasaga.com/release-note'} target={'_blank'}>v{version} | Vidhysaga </CLink>
                </Span>
            </Div>
        </CFooter>
    )
}

export default GlobalFooter
import React from 'react'

/* Fee Reports Start */

const FeeSummaryReport = React.lazy(() => import('./fee/fee-summary/index'))
const FeeDailyReport = React.lazy(()=>import('./fee/fee-daily'))

/* Fee Reports End */

export {
    /* Fee Reports Start */
    FeeSummaryReport,
    FeeDailyReport
    /* Fee Reports End */
}
import RestApiService from '../../service/RestApiService'

/* FeeType API start. */
export const getFeeTypes = async (page: number, records: number) => {
    const path = `fee/fee-types?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getFeeTypeDropdownItems = async (sort?: string, order?: string) => {
    const path = `fee/fee-types/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const changeFeeTypeStatus = async (id:number) => {
    const path = `fee/fee-types/${id}`
    return await RestApiService.patch(path)
}

export const createNewFeeType = async (data: any) => {
    const path = 'fee/fee-types'
    return await RestApiService.post(path, data)
}

export const getSelectedFeeType = async (id: number) => {
    const path = `fee/fee-types/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedFeeType = async (id: number, data: any) => {
    const path = `fee/fee-types/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedFeeType = async (id: number) => {
    const path = `fee/fee-types/${id}`
    return await RestApiService.destroy(path)
}

/* FeeType API end. */

/* Fee Rate API start. */

export const getStandards = async (page: number, records: number) => {
    const path = `academic/standards?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getSessions = async (page: number, records: number) => {
    const path = `academic/sessions?page=${page}&records=${records}`
    return await RestApiService.get(path)
}

export const getFeeRates = async (page: number, records: number, session_id?: number, standard_id?: number, fee_type_id?: number) => {
    const path = `fee/fee-rates?page=${page}&records=${records}&fee_type_id=${fee_type_id}&session_id=${session_id}&standard_id=${standard_id}`
    return await RestApiService.get(path)
}

export const createNewFeeRate = async (data: any) => {
    const path = 'fee/fee-rates'
    return await RestApiService.post(path, data)
}

export const getSelectedFeeRate = async (id: number) => {
    const path = `fee/fee-rates/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedFeeRate = async (id: number, data: any) => {
    const path = `fee/fee-rates/${id}`
    return await RestApiService.put(path, data)
}

export const changeFeeRateStatus = async (id:number) => {
    const path = `fee/fee-rates/${id}`
    return await RestApiService.patch(path)
}

export const getFeeStructureYearly = async (session_id: number, standard_id: number) => {
    const path = `fee/fee-rates/structure?session_id=${session_id}&standard_id=${standard_id}`
    return await RestApiService.get(path)
}

/* Fee Rate API end. */

/* FeeLate API start. */

export const getFeeLates = async (page: number, records: number, sort?: string, order?: string, session_id?: number, standard_id?: number) => {
    const path = `fee/fee-lates?page=${page}&records=${records}&sort=${sort}&order=${order}&session_id=${session_id}&standard_id=${standard_id}`
    return await RestApiService.get(path)
}

export const getSessionDropdownItems = async (sort?: string, order?: string) => {
    const path = `academic/sessions/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const getStandardDropdownItems = async (sort?: string, order?: string) => {
    const path = `academic/standards/dropdown?sort=${sort}&order=${order}`
    return await RestApiService.get(path)
}

export const changeFeeLateStatus = async (id:number) => {
    const path = `fee/fee-lates/${id}`
    return await RestApiService.patch(path)
}

export const createNewFeeLate = async (data: any) => {
    const path = 'fee/fee-lates'
    return await RestApiService.post(path, data)
}

export const getSelectedFeeLate = async (id: number) => {
    const path = `fee/fee-lates/${id}`
    return await RestApiService.get(path)
}

export const patchSelectedFeeLate = async (id: number, data: any) => {
    const path = `fee/fee-lates/${id}`
    return await RestApiService.put(path, data)
}

export const deleteSelectedFeeLate = async (id: number) => {
    const path = `fee/fee-lates/${id}`
    return await RestApiService.destroy(path)
}

/* FeeLate API end. */